export const getColorStyle = (number1, number2, criterio) => {
    let style = '';

    // Verificar si number2 es numérico
    if (isNumeric(number2)) {
        let parsedNumber1, parsedNumber2;

        if (criterio.toLowerCase() !== 'rango') {
            // Convertir number1 a número si contiene un porcentaje
            parsedNumber1 = number1.includes('%') ? parseFloat(number1.replace('%', '')) / 100 : parseFloat(number1);

            // Resto del código...
        }

        if (criterio.toLowerCase() === 'rango') {
            // Descomponer la cadena en un array usando el guion como separador
            const arreglometa = number1.split("-");

            // Convertir las partes a números si contienen un porcentaje
            parsedNumber1 = arreglometa[0].includes('%') ? parseFloat(arreglometa[0].replace('%', '')) / 100 : parseFloat(arreglometa[0]);
            parsedNumber2 = arreglometa[1].includes('%') ? parseFloat(arreglometa[1].replace('%', '')) / 100 : parseFloat(arreglometa[1]);

            // Resto del código...
        }

        // Resto del código...

        switch (criterio.toLowerCase()) {
            case 'maximo':
                style = number2 > parsedNumber1 ? '#FF9B9B' : (number2 === parsedNumber1 ? '#B5F1CC' : '#B5F1CC');
                
                break;
            case 'minimo':
                style = number2 < parsedNumber1 ? '#FF9B9B' : (number2 === parsedNumber1 ? '#B5F1CC' : '#B5F1CC');
                break;
            case 'rango':
                if (number2 < parsedNumber1 || number2 > parsedNumber2) {
                    style = '#FF9B9B'; /* Rojo */

                } else if (number2 === parsedNumber1) {
                    style = '#B5F1CC'; /* Verde */
                } else {
                    style = '#B5F1CC'; /* Verde Oscuro */
                }
                break;
            default:
                style = '';
                break;
        }
    }

    return style;
};
const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
};

