import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { CardActionArea, Divider, Grid } from '@mui/material';
// import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import UploadIcon from '@mui/icons-material/Upload';

const CalidadPrincipal = () => {
    return (

        <Box >
            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Typography variant="h5" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                        marginBottom: "25px", marginTop: "1.5rem"
                    }}>
                        <DoneAllRoundedIcon style={{ marginRight: "5px", color: "#FE0000" }} />
                        Calidad
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Paper variant="elevation" elevation={5} style={{ borderRadius: "13px", }}>
                        <Card sx={{ display: 'flex', height: "130px", borderRadius: "13px", }}>
                            <CardActionArea component="a" href="/Farmanet/ParametrosCalidad" sx={{ display: "flex" }}>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        // bgcolor="#EEF2FF"
                                        // height="230px"
                                        width="100%"
                                    >
                                        <ManageAccountsIcon style={{ fontSize: "40px", }} />
                                    </Box>
                                </Grid>
                                <Divider orientation='vertical' style={{ height: "80px" }} />
                                <Grid item xs={9} sm={9} md={9}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography sx={{ fontSize: 14, color: "#FF1E1E" }} color="text.secondary" gutterBottom>
                                                Calidad
                                            </Typography>
                                            <Typography variant="h5" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                Parametros
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Grid>
                            </CardActionArea>
                        </Card>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Paper variant="elevation" elevation={5} style={{ borderRadius: "13px", }}>
                        <Card sx={{ display: 'flex', height: "130px", borderRadius: "13px", }}>
                            <CardActionArea component="a" href="/Farmanet/InsetarIndicadorCalidad" sx={{ display: "flex" }}>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        // bgcolor="#EEF2FF"
                                        // height="230px"
                                        width="100%"
                                    >
                                        <UploadIcon style={{ fontSize: "40px", }} />
                                    </Box>
                                </Grid>
                                <Divider orientation='vertical' style={{ height: "80px" }} />
                                <Grid item xs={9} sm={9} md={9}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography sx={{ fontSize: 14, color: "#FF1E1E" }} color="text.secondary" gutterBottom>
                                                Calidad
                                            </Typography>
                                            <Typography variant="h5" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                Insertar indicador
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                </Grid>
                            </CardActionArea>
                        </Card>
                    </Paper>
                </Grid>
            </Grid>
        </Box>

    )
}

export default CalidadPrincipal