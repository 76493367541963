import React, { useContext, useEffect, useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import {
    Box, Divider, IconButton, List, ListItemButton, ListItemText, Toolbar, Typography,
    Modal, TextField, Button, Menu, MenuItem,
    Tooltip
} from '@mui/material';

import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../loginContext';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import DrawIcon from '@mui/icons-material/Draw';
import ListItemIcon from '@mui/material/ListItemIcon';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import DirectionsBoatFilledRoundedIcon from '@mui/icons-material/DirectionsBoatFilledRounded';
import PersonPinRoundedIcon from '@mui/icons-material/PersonPinRounded';

import BiotechIcon from '@mui/icons-material/Biotech';
import QrCodeIcon from '@mui/icons-material/QrCode';
import LogoutIcon from '@mui/icons-material/Logout';
import QRCode from 'qrcode.react';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import ListItem from '@mui/material/ListItem';
const drawerWidth = 225;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

// Creamos una funcion donde se habra un modal, para poder generar un qr
const Modalqr = ({ open, handleClose }) => {

    const [url, setUrl] = useState('');

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '0.5px solid #000',
        borderRadius: "13px",
        boxShadow: 24,
        p: 4,
    };

    const generateQRCode = () => {
        // Aquí puedes personalizar la lógica para generar el código QR según el valor de 'url'
        // Por ahora, simplemente usaremos el valor actual de 'url'
        return (
            <QRCode
                value={url}
                size={300}
                fgColor="#000000"
                bgColor="#ffffff"
                level="L"
                id={"sameId_as_QRCode_compoent_id"}
            />
        );
    };

    const downloadCode = () => {
        const canvas = document.getElementById("sameId_as_QRCode_compoent_id");
        if (canvas) {
            const pngUrl = canvas
                .toDataURL("image/jpg")
                .replace("image/jpg", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl
            downloadLink.download = `qr${url}.jpg`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setUrl("")
        }
    }


    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="body2" component="">
                        Código QR
                    </Typography>


                    <TextField label="Ingresa la url" autoComplete="off" fullWidth
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        size='small' variant="outlined"
                        sx={{ marginTop: 2, marginRight: 2 }} />

                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        {generateQRCode()}
                    </div>

                    <Button variant="contained" sx={{ marginTop: 2, width: '100%' }} onClick={() => downloadCode()}>
                        Descargar QR
                    </Button>
                </Box>
            </Modal>
        </Box>

    );
};

const MenuLateral = ({ children, handleDrawerOpen, handleDrawerClose, open }) => {
    const navigate = useNavigate();
    const { currentUser, logout } = useContext(AuthContext);

    // Conserve el valor seleccionado, despues de recargar
    const storedIndex = parseInt(localStorage.getItem('selectedIndex')) || 0;
    //  Usar el estado inicial del componente como el valor almacenado

    const [selectedIndex, setSelectedIndex] = useState(storedIndex);
    const [openQr, setOpenQr] = useState(false);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenQr = () => setOpenQr(true);

    const handleCloseQr = () => {
        setOpenQr(false)
    }

    const listItemStyle = {
        height: 42,
        // width: 42,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 0.8,
        marginBottom: 0.8,
        borderRadius: "12px",
        justifyContent: open ? 'initial' : 'center',
        '&.Mui-selected': {
            backgroundColor: '#1d54c0',
            color: 'white',
            '&:hover': {
                backgroundColor: '#1a389b',
            },
            '& .MuiListItemText-primary': {
                color: 'white',
            },
            '& .MuiSvgIcon-root': {
                color: 'white',
            },
        },
        '&:not(.Mui-selected):hover': {
            backgroundColor: '#F4EEEE',
        },
    };

    const CustomListItem = ({ to, text, icon, index }) => (

        <Tooltip title={text} placement="right">

            <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    component={Link}
                    to={to}
                    sx={listItemStyle}
                    selected={selectedIndex === index}
                    onClick={(event) => {
                        handleListItemClick(event, index);
                        handleDrawerClose();
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        </Tooltip>
    );

    const navegarFirma = () => {
        setSelectedIndex('')
        handleClose();
        navigate("/Farmanet/FirmaDigital")
    }

    // Actualizar el valor en localStorage cuando selectedIndex cambie
    useEffect(() => {
        localStorage.setItem('selectedIndex', selectedIndex.toString());
    }, [selectedIndex]);

    const theme = useTheme();

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ background: "#1d54c0" }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: 14 }}>
                        FamaNet
                        <span style={{ fontSize: 15 }}>
                            <br />
                            {currentUser.userData?.UsuNom}
                        </span>
                    </Typography>

                    <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>

                        {/* onClick={handleClick} */}

                        <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            sx={{ color: "white", borderRadius: "100%", width: "50px", height: "50px" }}
                            onClick={handleClick}
                        >
                            <SettingsIcon />
                        </IconButton>

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleOpenQr()}>
                                <QrCodeIcon sx={{ marginRight: "5px", fontSize: "20px" }} />
                                <Typography>Crear qr</Typography>
                            </MenuItem>
                            <MenuItem onClick={navegarFirma}>
                                <DrawIcon sx={{ marginRight: "5px", fontSize: "20px" }} />
                                <Typography>Crear firma digital</Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => logout()}>
                                <LogoutIcon sx={{ marginRight: "5px", fontSize: "20px" }} />
                                Cerrar sesión
                            </MenuItem>
                        </Menu>
                        <Modalqr open={openQr} handleClose={handleCloseQr} />
                    </div>
                </Toolbar>
            </AppBar>

            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer variant="permanent" open={open} >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List>

                        <CustomListItem to="/Farmanet"
                            text="Dashboard"
                            icon={<HomeRoundedIcon />} index={0} />

                        <CustomListItem to="/Farmanet/GerenciaVisual"
                            text="GerenciaVisual"
                            icon={<BarChartRoundedIcon />} index={1} />

                        <CustomListItem to="/Farmanet/Calidad" text="Calidad"
                            icon={<DoneAllRoundedIcon />} index={2} />

                        <CustomListItem to="/Farmanet/GestionComercial" text="GestionComercial"
                            icon={<PersonPinRoundedIcon />} index={3} />

                        <CustomListItem to="/Farmanet/Importaciones" text="Importaciones"
                            icon={<DirectionsBoatFilledRoundedIcon />} index={4} />

                        <CustomListItem to="/Farmanet/Financiera" text="Financiera"
                            icon={<AttachMoneyIcon />} index={5} />

                        <CustomListItem to="/Farmanet/Tecnica" text="Tecnica"
                            icon={<BiotechIcon />} index={6} />
                    </List>
                    <Divider />

                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                {/* <DrawerHeader /> */}
                <Box sx={{ paddingLeft: open ? '11rem' : '', paddingRight: '2rem', marginLeft: 10, }}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
}

export default MenuLateral;