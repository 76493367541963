import React, { useCallback, useEffect, useState } from 'react'
import newRequest from '../../../../utils/newRequest'
import { Grid, CircularProgress } from '@mui/material'
import TablaProducto from './TablaProducto'
import GraficoProducto from './GraficoProducto'

const PorProducto = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial,
    selectedProduct, selectedCliente, selectedLineas, selectedVendedor,
    selectedTercero, checked, Excluir, selectedZona }) => {

    const [rows, setRows] = useState([]); // Estado para almacenar las filas
    const [presupuestoTotal, setPresupuestoTotal] = useState([]);
    const [loading, setLoading] = useState(false);
    const converAñoAnt = selectAñoIncial - 1;
    const [noDataMessage, setNoDataMessage] = useState('');
    const [cambioTabla, setCambioTabla] = useState(true);

    const handleCambioTablaChange = useCallback((newCambioTabla) => {
        setCambioTabla(newCambioTabla);
    }, []);

    const fetchData = useCallback(async () => {
        try {
            setLoading(true); // Mostrar el modal de carga
            // Realizar la petición a la API y obtener los datos
            const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasProducto', {
                anioant: converAñoAnt,
                anio: selectAñoIncial,
                mes1: selectedMesInicial,
                mes2: selectedMesFinal,
                articulo: selectedProduct,
                cliente: selectedCliente,
                vendedor: selectedVendedor,
                linea: selectedLineas,
                tercero: selectedTercero,
                conProspecto: checked,
                excluir: Excluir,
                CambioDato: cambioTabla,
                zona: selectedZona

            })
            const data = res.data;
            if (data.length > 0) {
                const rowsWithId = data.map((row, index) => ({ id: index + 1, ...row }));
                setRows(rowsWithId);
                setNoDataMessage('Ventas por producto');
                setLoading(false);
            } else {
                setRows([]);
                setNoDataMessage('No hay datos disponibles');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [converAñoAnt, selectAñoIncial, selectedMesInicial, selectedMesFinal, selectedProduct, selectedCliente, selectedLineas, selectedVendedor, selectedTercero, checked, Excluir, cambioTabla, selectedZona]);


    useEffect(() => {
        // Aquí realizarías la llamada a la API para obtener los datos
        const getPresupuestoAnual = async () => {
            try {
                setLoading(true);
                const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialTotalPresupuestoAnual', {
                    total: selectAñoIncial
                });
                setPresupuestoTotal(res.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        getPresupuestoAnual();
        fetchData(); // Llamar a la función para obtener los datos al cargar el componente
    }, [fetchData, selectAñoIncial]); // Dependencia vacía para que se ejecute solo una vez al montar el componente

    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ marginBottom: "25px", }}>
                    {loading ?
                        (
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "404px" }} >
                                <CircularProgress />
                            </div>
                        )
                        :
                        (
                            <GraficoProducto selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial}
                                selectedMesFinal={selectedMesFinal} rows={rows} />
                        )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ marginBottom: "25px", }}>
                    {loading ?
                        (
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "404px" }} >
                                <CircularProgress />
                            </div>
                        )
                        :
                        (
                            <div >
                                <TablaProducto
                                    selectAñoIncial={selectAñoIncial}
                                    selectedMesInicial={selectedMesInicial}
                                    selectedMesFinal={selectedMesFinal}
                                    rows={rows} noDataMessage={noDataMessage}
                                    presupuestoTotal={presupuestoTotal}
                                    onChangeCambioTabla={handleCambioTablaChange} // Pasando la función como prop al hijo
                                    cambioTabla={cambioTabla} loading={loading}
                                />
                            </div>
                        )
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default PorProducto