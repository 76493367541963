import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthContextProvider } from './loginContext';


const theme = createTheme({
  typography: {
    // fontFamily: "Open Sans",
    fontFamily: 'Roboto',
    // Otras propiedades tipográficas que quieras personalizar
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div >
    <React.StrictMode>
      <AuthContextProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthContextProvider>
    </React.StrictMode>
  </div>
);
