import React, { useEffect, useState } from 'react'

import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, ToggleButton,
    ToggleButtonGroup, FormControl, InputLabel,
    Select, MenuItem, Fab, LinearProgress
    // Skeleton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import VistaEmpresa from './VistaEmpresa/VistaEmpresa';
import newRequest from '../../../utils/newRequest';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-12)

const Indicadores = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false); // Estado de carga

    const [añoSelect, setAñoSelect] = useState([]);
    const [selectAñoIncial, setAñoInical] = useState(currentYear);

    const [indicadores, setIndicadores] = useState([])
    // console.log("selectAñoIncial", selectAñoIncial)

    const meses = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
    ];

    const [mes, setMes] = useState(currentMonth);

    const handleChange = (event) => {
        setMes(event.target.value);
    };

    const [alignment, setAlignment] = useState('ESTRA');

    const handleChangeTipo = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const añoInicial = (year) => {
        if (year <= currentYear) {
            setAñoInical(year);
        } else {
            // Muestra una alerta o realiza alguna acción para indicar que la selección no es válida
            console.log("Error en el añoIncial", añoInicial)
        }
    };

    const DataAct = {
        proceso: alignment,
        año: selectAñoIncial
    }

    const indiC = async () => {
        try {
            setLoading(true); // Activar el estado de carga
            const res = await newRequest.post('/api/GerenciaVisual/Indicadores/MostrarIndicadores', DataAct, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            // setIndicadores(res.data);

            // Verifica si res.data es un array antes de establecerlo en el estado
            if (res.data.length) {
                setIndicadores(res.data);
            } else {
                setIndicadores([])
                // console.log("La respuesta no contiene un array de indicadores:", res.data);
            }
        } catch (error) {
            console.log("error 🦋", error)
        } finally {
            setLoading(false); // Desactivar el estado de carga después de la consulta
        }
    }

    const fetchData = async () => {
        try {
            const res = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno')
            setAñoSelect(res.data.reverse());
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
        indiC();
    }, [selectAñoIncial, alignment, mes])

    const handleBack = () => {
        navigate("/Farmanet/GerenciaVisual")
    }


    return (
        <Box >
            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Fab onClick={handleBack} style={{ background: "white", width: "45px", height: "45px" }}>
                            <ChevronLeftIcon />
                        </Fab>

                        <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                            <Typography color="text.primary">Gerencia visual</Typography>
                            <Typography color="text.primary">Indicadores</Typography>
                        </Breadcrumbs>
                    </Box>

                    <Typography variant="h6" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                        marginBottom: "25px", marginTop: "10px"
                    }}>
                        <MultilineChartIcon sx={{ paddingLeft: "5px", marginRight: "10px", color: "#FE0000", fontSize: "32px" }} />
                        Indicadores
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                    <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                        style={{
                            borderRadius: "13px",
                            position: "sticky",
                            top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                            zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                            boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                            transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                        }}
                    >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1, justifyItems: "center", }}>
                                    {/* <Grid container spacing={1} alignItems="center" sx={{ margin: 1, marginTop: "8px" }}> */}
                                    {/* Aqui va el año */}

                                    <Grid container spacing={1} alignItems="center" sx={{
                                        marginBottom: "17px", marginLeft: "10px", marginRight: "10px", marginTop: "8px",
                                        justifyContent: "space-between", display: "flex", justifyItems: "center",
                                    }}>

                                        <Grid item xs={12} sm={6} md={3} lg={1} xl={1}  >
                                            <Stack direction="row" alignItems="center" spacing={0.5}>
                                                {añoSelect.map((año) => (
                                                    <div key={año.PerMesano}>
                                                        <ToggleButtonGroup
                                                            color="primary" value={selectAñoIncial}
                                                            exclusive onClick={() => añoInicial(año.PerMesano)}
                                                            sx={{ height: "30px" }}
                                                        >
                                                            <ToggleButton key={año.PerMesano}
                                                                style={{
                                                                    backgroundColor: selectAñoIncial === año.PerMesano ? '#1976d2' : 'initial',
                                                                    color: selectAñoIncial === año.PerMesano ? 'white' : 'initial',
                                                                }}
                                                                value={año.PerMesano}>
                                                                {año.PerMesano}
                                                            </ToggleButton>
                                                        </ToggleButtonGroup>
                                                    </div>
                                                ))}
                                            </Stack>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={3} lg={4} xl={4} >
                                            <FormControl fullWidth size='small'>
                                                <InputLabel id="demo-simple-select-label">Mes del indicador</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={mes}
                                                    label="Mes del indicador"
                                                    onChange={handleChange}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 250,
                                                                // width: 250,
                                                            },
                                                        }
                                                    }}
                                                >
                                                    {meses.map((item, index) => (
                                                        <MenuItem value={index + 1} key={index}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} sx={{
                                            // <Grid item xs={5} sm={3} md={2} lg={3} xl={3} sx={{
                                            display: "flex", justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <ToggleButtonGroup size='small'
                                                color="primary" value={alignment} exclusive
                                                onChange={handleChangeTipo}
                                            >
                                                <ToggleButton value="ESTRA">Estrategicos</ToggleButton>
                                                <ToggleButton value="OPERA">Operativos</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>

                                        {/* <Grid item xs={3} sm={8} md={2} lg={3} xl={3} sx={{ */}
                                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3} sx={{
                                            display: "flex", justifyContent: "end",
                                            alignItems: "end"
                                        }}>
                                            <ToggleButtonGroup size='small'
                                                color="error" value={alignment} exclusive
                                            // onChange={handleChangeTipo}
                                            >
                                                <ToggleButton value="web">Proceso</ToggleButton>
                                                <ToggleButton value="android">Perspectiva</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>

                    </Paper>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                '& > :not(style)': {
                                    m: 1,
                                    width: "100%",
                                },
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography variant='subtitle1' sx={{ fontWeight: "bold", marginRight: "15px" }}>
                                    Medición de Indicadores :
                                </Typography>
                                <Breadcrumbs separator="›" aria-label="breadcrumb">
                                    <Typography color="text.primary">
                                        <span style={{ color: "#FF6E76", fontWeight: "bold", }}>
                                            {`MALO  < 80%`} <br />
                                        </span>
                                    </Typography>

                                    <Typography>
                                        <span style={{ color: "#FFB534", fontWeight: "bold", }}>
                                            {`REGULAR >= 80% & < 95%`} <br />
                                        </span>
                                    </Typography>
                                    <Typography color="text.primary">
                                        <span style={{ color: "#65B741", fontWeight: "bold", }}>
                                            {`BUENO >= 95% `}
                                        </span>
                                    </Typography>
                                </Breadcrumbs>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                            {loading ? (
                                <LinearProgress />
                            ) : (
                                <VistaEmpresa indicadores={indicadores} alignment={alignment}
                                    selectAñoIncial={selectAñoIncial} mes={mes} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Box>
    )
}

export default Indicadores