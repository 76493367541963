import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup, Checkbox, FormControlLabel,
    CircularProgress,
    Fab, Divider,
    Snackbar,
    Alert
} from '@mui/material';
import React, { Suspense, useContext, useEffect, useState } from 'react'
import newRequest from '../../../utils/newRequest';
import { useNavigate } from 'react-router-dom';
import PorMeses from './PorMeses/PorMeses';
import PorLinea from './PorLinea/PorLinea';
import Porperfil from './PorPerfil/Porperfil';
import PorVendedor from './PorVendedor/PorVendedor';
import PorProducto from './porProducto/PorProducto';
import PorZona from './porZona/PorZona';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import WarehouseRoundedIcon from '@mui/icons-material/WarehouseRounded';
import { AuthContext } from '../../../loginContext';
const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-11)

const VisualEmpresa = () => {

    const { isMobile } = useContext(AuthContext);

    const navigate = useNavigate();

    const [checked, setChecked] = useState(true);
    const [Excluir, SetExcluir] = useState(false);

    const [añoSelect, setAñoSelect] = useState([]);

    const [selectAñoIncial, setAñoInical] = useState(currentYear);
    const [selectedMesInicial, setSelectedMesInicial] = useState(currentMonth);

    // ? Seleccionar Año y Mes final
    const [selectedMesFinal, setSelectedMesFinal] = useState(currentMonth);

    //  ? Produto seleccionado
    const [selectProduct, setSelectProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);

    // ? Cliente seleccionado
    const [selectCliente, setSelectCliente] = useState([])
    const [selectedCliente, setSelectedCliente] = useState(null)

    // ? Opciones de la linea
    const [selectLinea, setSelectLinea] = useState([])
    const [selectedLineas, setSelectedLineas] = useState(null)

    // ? Opciones de los vendedores
    const [selectVendedor, setSelectVendedor] = useState([])
    const [selectedVendedor, setSelectedVendedor] = useState(null)

    // ? Opciones del tercero
    const [selectTercero, setSelectTercero] = useState([]);
    const [selectedTercero, setSelectedTercero] = useState(null);

    // ? Zona seleciconado
    const [selectZona, setSelectZona] = useState([]);
    const [selectedZona, setSelectedZona] = useState(null);

    const meses = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic",
    ];

    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState({ message: '', severity: 'info' });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleOpenSnackbar = (message, severity) => {
        setMessageInfo({ message, severity });
        setOpen(true);
    };



    const añoInicial = (year) => {
        if (year <= currentYear) {
            setAñoInical(year);
            setSelectedMesInicial(1);
            setSelectedMesFinal(12);
        } else {
            handleOpenSnackbar("Selección no es válida", "warning");
        }
    };

    const getMesesDisponibles = () => {
        return meses.map((mes, index) => ({
            nombre: mes,
            valor: index + 1,
        }));
    };

    const handleMesInicial = (month) => {
        if (selectAñoIncial === currentYear && month > currentMonth) {
            handleOpenSnackbar("No puedes seleccionar un mes posterior al mes actual", "warning");

        } else {
            setSelectedMesInicial(month);
        }
    };

    const handleMesFinal = (month) => {
        if (selectAñoIncial === currentYear && month < selectedMesInicial) {
            handleOpenSnackbar("No puedes seleccionar un mes anterior al mes inicial", "warning");
        } else {
            setSelectedMesFinal(month);
        }
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleChangeExcluir = (event) => {
        SetExcluir(event.target.checked);
    };

    const fetchData = async () => {
        try {
            // Añadir cabeceras a la solicitud: Puedes agregar una cabecera Cache-Control con el valor no-cache o no-store 
            // forzar que el servidor envíe una nueva respuesta sin importar si el contenido ha cambiado o no.
            const res = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno', {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                }
            });
            setAñoSelect(res.data.reverse());
        } catch (error) {
            console.error(error);
        }
    };

    const fetchSelectOptions = async (endpoint, setState) => {
        try {
            // const res = await newRequest.get(endpoint);
            const res = await newRequest.get(endpoint, {
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                }
            });
            setState(res.data.length > 0 ? res.data : []);
        } catch (error) {
            console.error(`Error fetching data from ${endpoint}:`, error);
        }
    };


    useEffect(() => {
        fetchData();
        fetchSelectOptions('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasArticulosSelect', setSelectProduct);
        fetchSelectOptions('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasClientesSelect', setSelectCliente);
        fetchSelectOptions('/api/GerenciaVisual/VisualEmpresa/vistaGerencialOpcionesLinea', setSelectLinea);
        fetchSelectOptions('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasVendedorSelect', setSelectVendedor);
        fetchSelectOptions('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasTerceroSelect', setSelectTercero);
        fetchSelectOptions('/api/GerenciaVisual/profundidadSelectZona', setSelectZona);
    }, []);

    const handleBack = () => {
        navigate("/Farmanet/GerenciaVisual")
    }

    return (
        <Box >
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={messageInfo.severity}
                    // variant="standard"
                    sx={{
                        width: '100%', background: "#222831", color: "white"
                    }}
                >
                    {messageInfo.message}
                </Alert>
            </Snackbar>

            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Fab onClick={handleBack} size='small' style={{ background: "white", }}>
                            <ChevronLeftIcon />
                        </Fab>

                        <Breadcrumbs aria-label="breadcrumb" style={{ paddingLeft: "15px" }}>
                            <Typography color="text.primary">Gerencia visual</Typography>
                            <Typography color="text.primary">Visual empresa</Typography>
                        </Breadcrumbs>
                    </Box>

                    <Typography variant="h6" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                        marginBottom: "25px", marginTop: "10px"
                    }}>
                        <WarehouseRoundedIcon sx={{ marginRight: "10px", color: "#FE0000" }} />
                        Visual empresa
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                        style={{
                            borderRadius: "13px",
                            // position: "sticky",
                            position: isMobile ? "relative" : " sticky",
                            top: isMobile ? 0 : 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                            zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                            boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                            transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                        }}
                    >
                        <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1 }}>
                            <Grid container spacing={1} alignItems="center" sx={{ margin: 1, marginTop: "8px" }}>

                                <Grid item xs={2} sm={2} md={1} lg={1} xl={1} sx={{ display: "flex" }}>
                                    <Stack direction="column" alignItems="center" spacing={0.5}>
                                        {añoSelect.map((año) => (
                                            <div key={año.PerMesano}>
                                                <ToggleButtonGroup
                                                    color="primary" value={selectAñoIncial}
                                                    exclusive onClick={() => añoInicial(año.PerMesano)}
                                                    sx={{ height: "30px" }}
                                                >
                                                    <ToggleButton key={año.PerMesano}
                                                        style={{
                                                            backgroundColor: selectAñoIncial === año.PerMesano ? '#1976d2' : 'initial',
                                                            color: selectAñoIncial === año.PerMesano ? 'white' : 'initial',
                                                        }}
                                                        value={año.PerMesano}>
                                                        {año.PerMesano}
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </div>
                                        ))}
                                    </Stack>
                                </Grid>

                                {/* Aqui va los meses */}
                                <Grid item xs={10} sm={10} md={5} lg={6} xl={5} sx={{ display: "flex", }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Stack direction="row" alignItems="center" >
                                            <Grid item container xs={12} sm={12} lg={12} spacing={0.5}>
                                                {getMesesDisponibles().map((mes, index) => (
                                                    <Grid item key={index}>
                                                        <div key={mes.valor}>
                                                            <ToggleButtonGroup
                                                                size="small"
                                                                color="primary"
                                                                value={selectedMesInicial}
                                                                exclusive
                                                                onClick={() => handleMesInicial(index + 1)}
                                                                sx={{ height: "30px" }}
                                                            >
                                                                <ToggleButton
                                                                    value={mes.valor}
                                                                    style={{
                                                                        backgroundColor: selectedMesInicial === mes.valor ? '#1976d2' : 'initial',
                                                                        color: selectedMesInicial === mes.valor ? 'white' : 'initial',
                                                                    }}>
                                                                    {mes.nombre}
                                                                </ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </div>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Stack>

                                        <Stack direction="row" alignItems="center" sx={{ marginTop: "10px" }}>
                                            <Grid item container xs={12} sm={12} lg={12} spacing={0.5}>
                                                {getMesesDisponibles().map((mes, index) => (
                                                    <Grid item key={index}>
                                                        <div key={mes.valor}>
                                                            <ToggleButtonGroup
                                                                size="small"
                                                                color="primary"
                                                                value={selectedMesFinal}
                                                                exclusive
                                                                onClick={() => handleMesFinal(index + 1)}
                                                                sx={{ height: "30px" }}
                                                            >
                                                                <ToggleButton
                                                                    value={mes.valor}
                                                                    style={{
                                                                        backgroundColor: selectedMesFinal === mes.valor ? '#1976d2' : 'initial',
                                                                        color: selectedMesFinal === mes.valor ? 'white' : 'initial',
                                                                    }}>
                                                                    {mes.nombre}
                                                                </ToggleButton>
                                                            </ToggleButtonGroup>
                                                        </div>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                {/* Aqui va los select */}
                                <Grid item xs={12} sm={10} md={4} lg={4} xl={5} sx={{}}>
                                    <Box style={{ display: "flex" }}>
                                        {/* TERCERO */}
                                        <Grid container item xs={12} sm={6} >
                                            <Autocomplete
                                                id="cliente" name="Articulo" autoComplete={false} margin="dense"
                                                options={selectTercero} getOptionLabel={(option) => option.tercero}
                                                fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                                onChange={(event, newValue) => {
                                                    setSelectedTercero(newValue?.nitSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Tercero" size="small" variant="outlined" margin="dense"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: { fontSize: 14, },
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* <div>Valor seleccionado Tercero: {selectedTercero}</div> */}
                                        </Grid>
                                        {/* CLIENTES */}
                                        <Grid container item xs={12} sm={6}  >
                                            <Autocomplete
                                                id="cliente" name="Articulo" size="small" autoComplete={false} margin="dense"
                                                options={selectCliente} getOptionLabel={(option) => option.cliente}
                                                fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                                onChange={(event, newValue) => {
                                                    setSelectedCliente(newValue?.NitSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Clientes" size="small" variant="outlined" margin="dense"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: { fontSize: 14, }, // Cambia el tamaño de la fuente aquí
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* <div>Valor seleccionado cliente: {selectedCliente}</div> */}
                                        </Grid>
                                        {/* Zona */}
                                        <Grid container item xs={12} sm={6}>
                                            <Autocomplete
                                                id="zona" name="zona" size="small" margin="dense"
                                                autoComplete={false}
                                                options={selectZona}
                                                getOptionLabel={(option) => option.ZonNom}
                                                fullWidth sx={{ marginBottom: "5px", }}
                                                onChange={(event, newValue) => {
                                                    setSelectedZona(newValue?.ZonCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Zona" size="small" variant="outlined" margin="dense"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: { fontSize: 14, },
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* <div>Valor seleccionado: {selectedZona}</div> */}
                                        </Grid>
                                    </Box>

                                    <Box style={{ display: "flex" }}>
                                        {/* Productos */}
                                        <Grid container item xs={12} sm={6}>
                                            <Autocomplete
                                                id="cliente" name="Articulo" size="small" autoComplete={false} margin="dense"
                                                options={selectProduct} getOptionLabel={(option) => option.producto}
                                                fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                                onChange={(event, newValue) => {
                                                    setSelectedProduct(newValue?.ArtSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                    // estadoFechasetVisual(true)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Producto" size="small" variant="outlined" margin="dense"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: { fontSize: 14, },
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* <div>Valor seleccionado producto: {selectedProduct}</div> */}
                                        </Grid>
                                        {/* LINEA */}
                                        <Grid container item xs={12} sm={6}>
                                            <Autocomplete
                                                id="cliente" name="Articulo" size="small" autoComplete={false} margin="dense"
                                                options={selectLinea} getOptionLabel={(option) => option.InvGruNom}
                                                fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                                onChange={(event, newValue) => {
                                                    setSelectedLineas(newValue?.invGruCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                    // estadoFechasetVisual(true)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Linea" size="small" variant="outlined" margin="dense"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: { fontSize: 14, },
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* <div>Valor seleccionado linea: {selectedLineas}</div> */}
                                        </Grid>
                                        {/* Vendedor */}
                                        <Grid container item xs={12} sm={6}>
                                            <Autocomplete
                                                id="cliente" name="Articulo" margin="dense" size="small" autoComplete={false}
                                                options={selectVendedor}
                                                getOptionLabel={(option) => option.VenNom}
                                                fullWidth
                                                onChange={(event, newValue) => {
                                                    setSelectedVendedor(newValue?.VenCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                    // estadoFechasetVisual(true)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Vendedor" size="small" variant="outlined" margin="dense"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            style: { fontSize: 14, },
                                                        }}
                                                    />
                                                )}
                                            />
                                            {/* <div>Valor seleccionado vendedor: {selectedVendedor}</div> */}
                                        </Grid>
                                    </Box>
                                </Grid>

                                {/* Aqui va los check */}
                                <Grid item xs={12} sm={3} md={1} lg={1} xl={1} sx={{}} >
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={selectedTercero || selectedCliente} // <- si alguno de los dos tiene datos deshabilite el prospecto
                                        />
                                    } label="Prospecto" labelPlacement="end" />
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={Excluir}
                                            onChange={handleChangeExcluir}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            disabled={!selectedTercero} // <- Si no tiene datos deshabilitelo 
                                        />
                                    } label="Excluir" labelPlacement="end" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>

                    <Box sx={{ marginBottom: 3 }}>
                        <Paper variant="outlined" sx={{
                            p: { xs: 1, md: 1 }, borderRadius: "13px",
                            marginTop: 3,
                        }}>
                            <Suspense fallback={<CircularProgress />}>
                                < PorMeses selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial} selectedMesFinal={selectedMesFinal}
                                    selectedProduct={selectedProduct} selectedCliente={selectedCliente} selectedLineas={selectedLineas}
                                    selectedVendedor={selectedVendedor} selectedTercero={selectedTercero}
                                    checked={checked} Excluir={Excluir} selectedZona={selectedZona}
                                />
                            </Suspense>

                            <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                            <Suspense fallback={<CircularProgress />}>
                                <PorVendedor selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial} checked={checked} Excluir={Excluir}
                                    selectedMesFinal={selectedMesFinal} selectedProduct={selectedProduct}
                                    selectedCliente={selectedCliente} selectedLineas={selectedLineas}
                                    selectedVendedor={selectedVendedor} selectedTercero={selectedTercero}
                                    selectedZona={selectedZona}
                                />
                            </Suspense>

                            <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                            <Suspense fallback={<CircularProgress />}>
                                <PorProducto selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial} checked={checked} Excluir={Excluir}
                                    selectedMesFinal={selectedMesFinal} selectedProduct={selectedProduct}
                                    selectedCliente={selectedCliente} selectedLineas={selectedLineas}
                                    selectedVendedor={selectedVendedor} selectedTercero={selectedTercero}
                                    selectedZona={selectedZona}
                                />
                            </Suspense>

                            <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                            <Suspense fallback={<CircularProgress />}>
                                <PorZona selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial}
                                    selectedMesFinal={selectedMesFinal} selectedProduct={selectedProduct} checked={checked} Excluir={Excluir}
                                    selectedCliente={selectedCliente} selectedLineas={selectedLineas}
                                    selectedVendedor={selectedVendedor} selectedTercero={selectedTercero}
                                    selectedZona={selectedZona}
                                />
                            </Suspense>

                            <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                            <Suspense fallback={<CircularProgress />}>
                                <PorLinea selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial} checked={checked} Excluir={Excluir}
                                    selectedMesFinal={selectedMesFinal} selectedProduct={selectedProduct}
                                    selectedCliente={selectedCliente} selectedLineas={selectedLineas}
                                    selectedVendedor={selectedVendedor} selectedTercero={selectedTercero}
                                    selectedZona={selectedZona}
                                />
                            </Suspense>

                            <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />
                            <Suspense fallback={<CircularProgress />}>
                                <Porperfil selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial}
                                    selectedMesFinal={selectedMesFinal} selectedProduct={selectedProduct} checked={checked} Excluir={Excluir}
                                    selectedCliente={selectedCliente} selectedLineas={selectedLineas}
                                    selectedVendedor={selectedVendedor} selectedTercero={selectedTercero} selectedZona={selectedZona}
                                />
                            </Suspense>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default VisualEmpresa;