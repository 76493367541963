import React, { useEffect, useState } from 'react'
import { Box, Typography, LinearProgress, Dialog, DialogContent, CircularProgress, Grid } from '@mui/material';
import newRequest from '../../../../utils/newRequest';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import TablaIndicadoresEmpresa from './TablaIndicadoresEmpresa';
import ModalIndicadoresEmpresa from './ModalIndicadoresEmpresa';
import ModalDos from './ModalDos';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography variant='h5'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const ListaIndicadores = ({ proceso, obtenerProceso }) => {

    const [loading, setLoading] = useState(false);
    const [firstProEmpSecSet, setFirstProEmpSecSet] = useState(false);

    // Estado para abrir y cerrar el modal
    const [openDrawer, setOpenDrawer] = useState(false);
    // Estado para abrir y cerrar el modal del peso
    const [openDrawerDos, setOpenDrawerDos] = useState(false);

    const [selectedID, setSelectedID] = useState(""); // Estado para almacenar el ID seleccionado

    const [procesoEmpresa, setprocesoEmpresa] = useState([])
    const [objetivoIndicador, setObjetivoIndicador] = useState([])
    const [datosIndicador, setDatosIndicador] = useState([])
    const [procesosEmpresa, setprocesosEmpresa] = useState([])

    // 
    const [value, setValue] = useState(0);
    // const [proceso, setProceso] = useState([]);
    const [selectedProEmpSec, setSelectedProEmpSec] = useState(selectedID?.ProEmpSec); // Ubica la prima posición
    const [cantidadIndicador, setcantidadIndicador] = useState([])

    const [valueDos, setValueDos] = useState(0);

    const handleChangeDos = (event, newValue) => {
        setValueDos(newValue);
    };

    const handleChange = (event, newValue) => {
        const selectedData = proceso[newValue];
        setSelectedProEmpSec(selectedData.ProEmpSec);
        setValue(newValue);
        // Establecer el valor predeterminado para las pestañas internas en "ESTRATEGICO"
        setValueDos(0);
    };
    const procesoData = {
        id: selectedProEmpSec,
        tipo: valueDos
        // id: proceso[0]?.ProEmpSec,
    }


    const ObtenerIndicador = async () => {
        try {
            setLoading(true)
            const res = await newRequest.post(`/api/Calidad/Indicadores/ObtenerIndicador`, procesoData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
            if (res.data.length) {
                setDatosIndicador(res.data);
                setLoading(false)
            } else {
                setDatosIndicador([]);
            }
        } catch (error) {
            // console.log("error", error);
        } finally {
            setLoading(false)
        }
    };

    // Funcion para traer cantidades operativas o Estrategicas
    const obtenerCantidadIndicador = async () => {
        try {
            const res = await newRequest.post(`/api/Calidad/Indicadores/DatosCantidadIndicador`, {
                id: selectedProEmpSec,
            });
            if (res.data.length) { // Incio de condición
                setcantidadIndicador(res.data)
            } else {
                setcantidadIndicador([])
            } // Fin de condición
        } catch (error) {
            // console.log("error", error);
        }
    }

    // Función para manejar el ID seleccionado
    const handleIDSelected = (datos) => {
        setSelectedID(datos);
    };

    const ProcesosEmpresaIndicador = async () => {
        try {
            const res = await newRequest.get("/api/Calidad/Indicadores/ProcesosEmpresaIndicador")
            setprocesoEmpresa(res.data)

        } catch (error) {
            console.error(error);
        }
    }

    const ObjetivoIndicador = async () => {
        try {
            const res = await newRequest.get("/api/Calidad/Indicadores/ObjetivoIndicador")
            if (res.status !== 200) {
                throw new Error('Error en la respuesta del servidor')
            } else {
                setObjetivoIndicador(res.data)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const getProcesos = async () => {
        try {
            const res = await newRequest.get('/api/Calidad/Indicadores/ProcesosEmpresaIndicador');
            setprocesosEmpresa(res.data)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        // Llama a la función para obtener la cantidad de indicadores al cargar la página
        obtenerCantidadIndicador();
    }, [valueDos, value]);

    // useEffect(() => {
    //     // Actualiza selectedProEmpSec solo si proceso[0]?.ProEmpSec existe
    //     if (proceso.length > 0 && proceso[0]?.ProEmpSec !== undefined) { // Inicio de condicón
    //         setSelectedProEmpSec(proceso[0]?.ProEmpSec);
    //     } // Fin de condición
    // }, [proceso]);


    useEffect(() => {
        if (!firstProEmpSecSet && proceso.length > 0) {
            setSelectedProEmpSec(proceso[0].ProEmpSec);
            setFirstProEmpSecSet(true);
        }
    }, [proceso, firstProEmpSecSet]);

    useEffect(() => {
        ProcesosEmpresaIndicador();
        ObjetivoIndicador();
        getProcesos();


        // Solo si selectedProEmpSec es válido, realiza la solicitud
        if (selectedProEmpSec !== null) {
            ObtenerIndicador();
            obtenerCantidadIndicador();
        }
    }, [selectedProEmpSec, valueDos, proceso]);


    // if (loading) {
    //     return (
    //         <div style={{ height: 638, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             Cargando...
    //         </div>
    //     );
    // }


    return (
        <div style={{ height: 580 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                    <Box sx={{ display: 'flex', height: 580, marginTop:-2 }}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={value}
                            onChange={handleChange}
                            sx={{
                                borderRight: 1,
                                borderColor: 'divider',
                                textTransform: "lowercase"
                            }}
                        >

                            {proceso.map((data, index) => (
                                <Tab
                                    key={index}
                                    label={`${data.ProEmpNom} (${data.total})`}
                                    sx={{
                                        display: "flex", alignItems: "flex-start", textAlign: "left", 
                                        textTransform: "lowercase"
                                    }}
                                />
                            ))}
                        </Tabs>
                    </Box>
                </Grid>

                {/* TABS INTERNA */}
                <Grid item xs={12} sm={10}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                        <Tabs value={valueDos} onChange={handleChangeDos} aria-label="basic tabs example">
                            <Tab
                                label={`estrategico (${cantidadIndicador[0]?.Estra || 0})`}
                                sx={{
                                    display: "flex", alignItems: "flex-start", textAlign: "left"
                                }}
                            />
                            <Tab
                                label={`operativo (${cantidadIndicador[0]?.Opera || 0})`}
                                sx={{
                                    display: "flex", alignItems: "flex-start", textAlign: "left"
                                }}
                            />
                        </Tabs>
                    </Box>

                    {/* ESTRATEGICO */}

                    <CustomTabPanel value={valueDos} index={0}>
                        {loading ? <>
                            {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10rem" }}> */}
                            <LinearProgress />
                            {/* </Box> */}
                        </> : <>
                            <Box sx={{ marginLeft: -4, marginRight: -4 }}>


                                {procesosEmpresa.map((data, index) => (
                                    <TabPanel key={data.ProEmpSec} value={value} index={index}>
                                        <div >
                                            <TablaIndicadoresEmpresa datosIndicador={datosIndicador}
                                                setOpenDrawer={setOpenDrawer}
                                                setOpenDrawerDos={setOpenDrawerDos}
                                                onIDSelected={handleIDSelected}
                                                ObtenerIndicador={ObtenerIndicador}
                                                obtenerProceso={obtenerProceso}
                                                obtenerCantidadIndicador={obtenerCantidadIndicador}
                                            />
                                        </div>
                                    </TabPanel>
                                ))}

                            </Box>
                        </>}
                    </CustomTabPanel>

                    <CustomTabPanel value={valueDos} index={1}>
                        {/* OPERACIONAL */}
                        {loading ? <>
                            {/* <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10rem" }}> */}
                            <LinearProgress />
                            {/* </Box> */}
                        </> : <>
                            <Box sx={{ marginLeft: -4, marginRight: -4 }}>

                                {procesosEmpresa.map((data, index) => (
                                    <TabPanel key={data.ProEmpSec} value={value} index={index}>
                                        <div >
                                            <TablaIndicadoresEmpresa datosIndicador={datosIndicador}
                                                setOpenDrawer={setOpenDrawer}
                                                setOpenDrawerDos={setOpenDrawerDos}
                                                ObtenerIndicador={ObtenerIndicador}
                                                obtenerProceso={obtenerProceso}
                                                obtenerCantidadIndicador={obtenerCantidadIndicador}
                                            />
                                        </div>
                                    </TabPanel>
                                ))}
                            </Box>
                        </>}
                    </CustomTabPanel>

                </Grid>
            </Grid>

            <Box>
                <Dialog
                    open={openDrawer}
                    onClose={() => setOpenDrawer(false)}
                    maxWidth="md" // Puedes ajustar este valor según tus necesidades
                    PaperProps={{
                        sx: { borderRadius: "13px" }
                    }}
                    sx={{
                        width: "100%", display: "flex", justifyContent:
                            "center", alignItems: "center"
                    }}
                >
                    <DialogContent>
                        <ModalIndicadoresEmpresa
                            setOpenDrawer={setOpenDrawer}
                            procesoEmpresa={procesoEmpresa} selectedID={selectedID}
                            objetivoIndicador={objetivoIndicador}
                            ObtenerIndicador={ObtenerIndicador}
                            obtenerProceso={obtenerProceso}
                            obtenerCantidadIndicador={obtenerCantidadIndicador}

                        // obtenerProceso={proceso}
                        />
                    </DialogContent>
                </Dialog>
            </Box>

            <Box>
                <Dialog
                    open={openDrawerDos}
                    onClose={() => setOpenDrawerDos(false)}
                    maxWidth="md" // Puedes ajustar este valor según tus necesidades
                    PaperProps={{
                        sx: { borderRadius: "13px" }
                    }}
                    sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                    <DialogContent>
                        <ModalDos
                            //SE PARA EL VALUEDOS PARA IDENTIFICAR SI ES ESTRATEGICO O OPERACIONAL
                            valueDos={valueDos}
                            selectedID={selectedID}
                            setOpenDrawerDos={setOpenDrawerDos}
                            ObtenerIndicador={ObtenerIndicador}
                            obtenerProceso={obtenerProceso}
                            obtenerCantidadIndicador={obtenerCantidadIndicador}
                        />
                    </DialogContent>
                </Dialog>
            </Box>
        </div>
    )
}

export default ListaIndicadores