import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { Grid, CircularProgress, FormControlLabel, Radio, FormControl, RadioGroup } from '@mui/material'
import newRequest from '../../../../utils/newRequest'
import TablaVendedor from './TablaVendedor'
import GraficoVendedor from './GraficoVendedor'
import { GraficoVendedorDos } from './GraficoVendedorDos'

const PorVendedor = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial,
    selectedProduct, selectedCliente, selectedLineas, selectedVendedor,
    selectedTercero, checked, Excluir, selectedZona, }) => {

    const [rows, setRows] = useState([]); // Estado para almacenar las filas
    const [presupuestoTotal, setPresupuestoTotal] = useState([]);
    const [loading, setLoading] = useState(false);
    const converAñoAnt = selectAñoIncial - 1;
    const [noDataMessage, setNoDataMessage] = useState('');
    const [cambioGrafica, setCambioGrafica] = useState(true);

    const [cambioTabla, setCambioTabla] = useState(true);


    const handleSwitchChange = useCallback((event) => {
        const newCambioGrafica = event.target.value === 'true'; // Convertir el valor de cadena a booleano
        if (cambioGrafica && !newCambioGrafica) {
        }
        setCambioGrafica(newCambioGrafica);
    }, [cambioGrafica]);


    const handleCambioTablaChange = useCallback((newCambioTabla) => {
        setCambioTabla(newCambioTabla);
    }, []);


    console.log("cambioTabla", cambioTabla)

    const fetchData = useCallback(async () => {
        try {
            setLoading(true); // Mostrar el modal de carga
            const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasVendedor', {
                anioant: converAñoAnt,
                anio: selectAñoIncial,
                mes1: selectedMesInicial,
                mes2: selectedMesFinal,
                articulo: selectedProduct,
                cliente: selectedCliente,
                vendedor: selectedVendedor,
                linea: selectedLineas,
                tercero: selectedTercero,
                conProspecto: checked,
                excluir: Excluir,
                CambioDato: cambioTabla,
                zona: selectedZona,
            })
            const data = res.data;
            if (data.length > 0) {
                const rowsWithId = data.map((row, index) => ({ id: index + 1, ...row }));
                setRows(rowsWithId);
                setNoDataMessage('Ventas por vendedor');
            } else {
                setRows([]);
                setNoDataMessage('No hay datos disponibles');
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false)
        }
    }, [converAñoAnt, selectAñoIncial, selectedMesInicial, selectedMesFinal,
        selectedProduct, selectedCliente, selectedLineas,
        selectedVendedor, selectedTercero, checked, Excluir,
        cambioTabla,
        selectedZona])

    useEffect(() => {
        const getPresupuestoAnual = async () => {
            try {
                setLoading(true);
                const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialTotalPresupuestoAnual', {
                    total: selectAñoIncial
                });
                setPresupuestoTotal(res.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getPresupuestoAnual();
        fetchData(); // Llamar a la función para obtener los datos al cargar el componente
    }, [fetchData, selectAñoIncial]); // Dependencia vacía para que se ejecute solo una vez al montar el componente

    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ marginBottom: "25px", }}>
                    {loading ?
                        (
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "350px" }} >
                                <CircularProgress />
                            </div>
                        ) :
                        (
                            <Suspense>
                                <FormControl sx={{ textAlign: "center", display: "flex", alignItems: "center" }}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-form-control-label-placement"
                                        name="position"
                                        defaultValue="top"
                                    >
                                        <FormControlLabel
                                            value={true}
                                            checked={cambioGrafica === true}
                                            onChange={handleSwitchChange}
                                            control={<Radio />}
                                            label="ventas"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value={false}
                                            checked={cambioGrafica === false}
                                            control={<Radio />}
                                            onChange={handleSwitchChange}
                                            label="% cumplimiento presupuesto"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {
                                    cambioGrafica ? (
                                        <div>
                                            <GraficoVendedor selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial}
                                                selectedMesFinal={selectedMesFinal} rows={rows} />
                                        </div>
                                    ) :
                                        (
                                            <div>
                                                <GraficoVendedorDos selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial}
                                                    selectedMesFinal={selectedMesFinal} rows={rows} />
                                            </div>
                                        )
                                }
                            </Suspense>

                        )}

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ marginBottom: "25px", }}>
                    {loading ?
                        (
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "350px" }} >
                                <CircularProgress />
                            </div>
                        ) :
                        (
                            <Suspense>
                                <TablaVendedor selectAñoIncial={selectAñoIncial} selectedMesInicial={selectedMesInicial}
                                    selectedMesFinal={selectedMesFinal} rows={rows}
                                    presupuestoTotal={presupuestoTotal} loading={loading}
                                    noDataMessage={noDataMessage}
                                    cambioTabla={cambioTabla}
                                    onChangeCambioTabla={handleCambioTablaChange} // Pasando la función como prop al hijo
                                />
                            </Suspense>
                        )}

                </Grid>
            </Grid>
        </div >
    )
}

export default PorVendedor