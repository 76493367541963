import React, { useEffect, useState, lazy,  useRef, useCallback } from 'react'
import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup, Modal, Button, IconButton,
    Fab,
    Divider
    // Skeleton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import newRequest from '../../../utils/newRequest';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PaidIcon from '@mui/icons-material/Paid';

const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-11)

const PorPresupuesto = lazy(() => import('./PorPresupuesto/PorPresupuesto'));
const ClienteSinProducto = lazy(() => import('./ClienteSinProducto/ClienteSinProducto'));
const PorArticulo = lazy(() => import('./PorArticulo/PorArticulo'));


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

// function createData(name, calories, fat) {
//     return { name, calories, fat };
// }

const rowsPerPageOptions = [5, 10, 25]; // Opciones para el número de filas por página

const Presupuesto = () => {
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const [state, setState] = useState({
        añoSelect: [],
        selectAñoIncial: currentYear,
        selectedMesInicial: currentMonth,
        selectedMesFinal: currentMonth,
        selectProduct: [],
        selectedProduct: [],
        selectCliente: [],
        selectedCliente: null,
        selectTercero: [],
        selectedTercero: null,
        selectVendedor: [],
        selectedVendedor: null,
        arcExcel: "second",
        open: false,
        loadinDatos: [],
    });

    const handleFileChange = useCallback(async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('archivoExcel', file);
            try {
                const res = await newRequest.post('/api/GerenciaVisual/Presupuesto/CarguePresupuesto', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setState((prevState) => ({ ...prevState, loadinDatos: res.data }));
            } catch (error) {
                console.error('Error al enviar el archivo al servidor:', error);
            }
        } else {
            alert("Selecciona una arhivo");
        }
    }, []);

    const handleChangePage = useCallback((event, newPage) => setPage(newPage), []);
    const handleChangeRowsPerPage = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, []);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, state.loadinDatos?.rowsData?.length - page * rowsPerPage);

    const añoInicial = useCallback((year) => {
        // estadoFechasetVisual(true);
        if (year <= currentYear) {
            setState((prevState) => ({
                ...prevState,
                selectAñoIncial: year,
                selectedMesInicial: 1,
                selectedMesFinal: 12,
            }));
        } else {
            Store.addNotification({
                title: "La selección no es válida.",
                message: "El año inicial no puede ser mayor al año final",
                type: "info",
                showIcon: true,
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true,
                },
            });
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno');
                setState((prevState) => ({ ...prevState, añoSelect: res.data.reverse() }));
            } catch (error) {
                console.error(error);
            }
        };

        const selectCliente = async () => {
            try {
                const resCliente = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasClientesSelect');
                setState((prevState) => ({ ...prevState, selectCliente: resCliente.data }));
            } catch (error) {
                console.log("error 🦋", error);
            }
        };

        const selectArticulos = async () => {
            try {
                const resProductos = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasArticulosSelect');
                setState((prevState) => ({ ...prevState, selectProduct: resProductos.data }));
            } catch (error) {
                console.log("error 🦋", error);
            }
        };

        const selectTercero = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasTerceroSelect');
                setState((prevState) => ({ ...prevState, selectTercero: res.data }));
            } catch (error) {
                console.log("error 🦋", error);
            }
        };

        const selectVendedor = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasVendedorSelect');
                setState((prevState) => ({
                    ...prevState,
                    selectVendedor: res.data.length > 0 ? res.data : [],
                }));
            } catch (error) {
                console.log("error 🦋", error);
            }
        };

        selectVendedor();
        selectCliente();
        selectArticulos();
        selectTercero();
        fetchData();
    }, []);

    const handleBack = useCallback(() => navigate("/Farmanet/GerenciaVisual"), [navigate]);


    return (
        <div>
            <Box>
                <Box sx={{ height: 450, transform: 'translateZ(0px)', flexGrow: 1, position: "fixed", zIndex: 3, bottom: 16, right: 16 }}>
                    <SpeedDial
                        ariaLabel="SpeedDial basic example"
                        sx={{ position: 'absolute', bottom: 16, right: 16 }}
                        icon={<SpeedDialIcon />}
                        FabProps={{
                            sx: {
                                bgcolor: 'black',
                                '&:hover': {
                                    bgcolor: 'black',
                                }
                            }
                        }}
                    >
                        <SpeedDialAction
                            onClick={() => setState((prevState) => ({ ...prevState, open: true }))}
                            icon={<FileCopyIcon sx={{ color: "white" }} />}
                            tooltipTitle="Cargar Presupuesto"
                            FabProps={{
                                sx: {
                                    bgcolor: '#357a38',
                                    '&:hover': {
                                        bgcolor: '#357a38',
                                    }
                                }
                            }}
                        />
                    </SpeedDial>

                    <input
                        ref={fileInputRef}
                        type="file"
                        name='archivoExcel'
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </Box>
                <Modal
                    open={state.open}
                    onClose={() => setState((prevState) => ({ ...prevState, open: false }))}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: " 70vh",
                        bgcolor: 'background.paper',
                        borderRadius: '40px',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'row' }}>
                                    <Button variant='contained' endIcon={<FileDownloadIcon />} color='success'
                                        sx={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                    >plantilla Presupuesto</Button>
                                    <Button variant='contained' endIcon={<FileUploadIcon />}
                                        sx={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                        }}
                                        onClick={() => fileInputRef.current.click()}
                                    > Cargar presupuesto
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                {state.loadinDatos && state.loadinDatos.rowsData ? (
                                    <Box sx={{ overflowY: 'auto', overflowX: 'auto' }}>
                                        <Box>
                                            <TableContainer sx={{ maxHeight: 440, minHeight: 220, height: 440 }}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Año</TableCell>
                                                            <TableCell align="right">Mes</TableCell>
                                                            <TableCell align="right">Presupuesto</TableCell>
                                                            <TableCell align="right">Articulo</TableCell>
                                                            <TableCell align="right">Asesor</TableCell>
                                                            <TableCell align="right">Cantidad</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(rowsPerPage > 0
                                                            ? state.loadinDatos?.rowsData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                            : state.loadinDatos?.rowsData
                                                        ).map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{row.año}</TableCell>
                                                                <TableCell align="right">{row.mes}</TableCell>
                                                                <TableCell align="right">{row.presupuesto}</TableCell>
                                                                <TableCell align="right">{row.articulo}</TableCell>
                                                                <TableCell align="right">{row.asesor}</TableCell>
                                                                <TableCell align="right">{row.cantidad}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                        {emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={6} />
                                                            </TableRow>
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                labelRowsPerPage="Filas por página"
                                                rowsPerPageOptions={rowsPerPageOptions}
                                                component="div"
                                                count={state.loadinDatos?.rowsData?.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Box>
                                    </Box>
                                ) : (
                                    <Typography>No hay datos para mostrar</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Button variant='contained' color='error' endIcon={<CloseIcon />}
                            sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                            onClick={() => setState((prevState) => ({ ...prevState, open: false }))}
                        > Cerrar
                        </Button>
                    </Box>
                </Modal>

                <Grid item xs={12} sm={6} md={12}>
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Fab onClick={handleBack} style={{ background: "white", width: "45px", height: "45px" }}>
                                <ChevronLeftIcon />
                            </Fab>
                            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                                <Typography color="text.primary">Gerencia visual</Typography>
                                <Typography color="text.primary">Presupuesto</Typography>
                            </Breadcrumbs>
                        </Box>
                        <Typography variant="h6" color="black" gutterBottom style={{
                            display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                            marginBottom: "25px", marginTop: "10px"
                        }}>
                            <PaidIcon sx={{ marginRight: "10px", color: "#FE0000" }} />
                            Presupuesto
                        </Typography>
                    </Box>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Paper variant="elevation" square elevation={3}
                            style={{
                                borderRadius: "13px",
                                position: "sticky",
                                top: 55,
                                zIndex: 3,
                                boxShadow: "0px 0px 30px 10px rgba(0, 0, 0, 0.2)",
                                transition: "box-shadow 0.3s",
                            }}
                        >
                            <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1, justifyItems: "center" }}>
                                <Grid container spacing={1} alignItems="center" sx={{ margin: 1, marginTop: "8px", justifyContent: "space-between", display: "flex", justifyItems: "center" }}>
                                    <Grid item xs={12} sm={1} md={5} lg={5} xl={5} sx={{ display: "flex" }}>
                                        <Stack direction="row" alignItems="center" spacing={0.5}>
                                            {state.añoSelect.map((año) => (
                                                <div key={año.PerMesano}>
                                                    <ToggleButtonGroup
                                                        color="primary" value={state.selectAñoIncial}
                                                        exclusive onClick={() => añoInicial(año.PerMesano)}
                                                        sx={{ height: "30px" }}
                                                    >
                                                        <ToggleButton key={año.PerMesano}
                                                            style={{
                                                                backgroundColor: state.selectAñoIncial === año.PerMesano ? '#1976d2' : 'initial',
                                                                color: state.selectAñoIncial === año.PerMesano ? 'white' : 'initial',
                                                            }}
                                                            value={año.PerMesano}>
                                                            {año.PerMesano}
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                            ))}
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} sm={11} md={7} lg={7} xl={7}>
                                        <div style={{ display: "flex" }}>
                                            <Grid container item xs={12} sm={6}>
                                            </Grid>

                                            <Grid container item xs={12} sm={6}>
                                                <Autocomplete
                                                    id="Vendedor" name="Vendedor" margin="dense" size="small" autoComplete={false}
                                                    options={state.selectVendedor} sx={{ padding: "5px" }}
                                                    getOptionLabel={(option) => option.VenNom}
                                                    fullWidth
                                                    onChange={(event, newValue) => setState((prevState) => ({ ...prevState, selectedVendedor: newValue?.VenCod || '' }))}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Vendedor" size="small" variant="outlined"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, height: 35 },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>

                                            <Grid container item xs={12} sm={6}>
                                                <Autocomplete
                                                    id="cliente" name="Articulo" size="small" autoComplete={false}
                                                    options={state.selectProduct} getOptionLabel={(option) => option.producto}
                                                    fullWidth sx={{ padding: "5px" }}
                                                    onChange={(event, newValue) => setState((prevState) => ({ ...prevState, selectedProduct: newValue?.ArtSec || '' }))}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Producto" variant="outlined" size='small'
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, height: 35 },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>

                        <Box sx={{ marginBottom: 3 }}>
                            <Paper variant="outlined" sx={{
                                p: { xs: 1, md: 1 }, borderRadius: "13px",
                                marginTop: 3,
                            }}>
                                {/* <Suspense fallback={<CircularProgress />}> */}
                                <ClienteSinProducto {...state} />
                                {/* </Suspense> */}

                                <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                                {/* <Suspense fallback={<CircularProgress />}> */}
                                <PorPresupuesto {...state} />
                                {/* </Suspense> */}


                                <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                                {/* <Suspense fallback={
                                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <CircularProgress /> Cargando información
                                    </Box>
                                }> */}
                                <PorArticulo {...state} />
                                {/* </Suspense> */}

                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Presupuesto