import React, { useEffect, useState } from 'react';
import newRequest from '../../../../utils/newRequest';
import { TablaPyG } from './TablaPyG';

const PorPyG = () => {

    const [data, setdata] = useState([]);
    const [costoVenta, setCostoVenta] = useState([]);
    const [otrosGastos, setotrosGastos] = useState([]);
    const [loading, setloading] = useState(true);

    // Obtenenos los datos
    const getPyGData = async () => {
        try {
            setloading(true); // Mostrar indicador de carga
            const [responsePyG, responseCostoVenta, responseOtrosGastos] = await Promise.all([
                newRequest.post('/api/Financiera/financieraRoutes/FinancieraPyG', {}),
                newRequest.post('/api/Financiera/financieraRoutes/financieraPyGCostoVentas', {}),
                newRequest.post('/api/Financiera/financieraRoutes/financieraPyGOtro', {})
            ]);
            setdata(Array.isArray(responsePyG.data) ? responsePyG.data : []);
            setCostoVenta(Array.isArray(responseCostoVenta.data) ? responseCostoVenta.data : []);
            setotrosGastos(Array.isArray(responseOtrosGastos.data) ? responseOtrosGastos.data : []);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setloading(false); // Ocultar indicador de carga después de la actualización
        }
    };

    const ejecutarSp = async () => {
    }

    // useEffect(() => {
    //     // const ws = new WebSocket('ws://localhost:8000'); // Asegúrate de que el puerto y la URL sean correctos

    //     const ws = new WebSocket('ws://localhost:8000'); // Asegúrate de que el puerto y la URL sean correctos
    //     //const ws = new WebSocket('wss://www.laboratoriofarmacomercial.com/farmaApi/'); // Asegúrate de que el puerto y la URL sean correctos

    //     ws.onopen = () => {
    //         console.log('Conectado al WebSocket');
    //         // Envía un mensaje inicial para ejecutar el SP
    //         ws.send('Iniciar actualización de PUC');
    //     };

    //     ws.onmessage = async (event) => {
    //         try {
    //             const receivedData = JSON.parse(event?.data);

    //             console.log("Mensaje recibido del servidor:", receivedData?.message);
    //             if (receivedData?.message === "PUC actualizado correctamente 🟢") {
    //                 console.log(`PUC actualizado para el mes ${receivedData?.mesActual} del año ${receivedData?.año}`);
    //                 // Ejecuta la obtención de datos después de la actualización del PUC
    //                 await getPyGData();
    //             }
    //         } catch (error) {
    //             console.error('Error al procesar los datos del WebSocket:', error);
    //         }
    //     };

    //     ws.onclose = () => {
    //         console.log('Desconectado del WebSocket');
    //     };

    //     return () => {
    //         ws.close();
    //     };
    // }, []);

    useEffect(() => {
        const ws = new WebSocket('ws://localhost:8000'); // Asegúrate de que el puerto y la URL sean correctos

        ws.onopen = () => {
            console.log('Conectado al WebSocket');
            ws.send('Iniciar actualización de PUC');
        };

        ws.onmessage = async (event) => {
            try {
                const receivedData = JSON.parse(event?.data);
                console.log("Mensaje recibido del servidor:", receivedData?.message);
                if (receivedData?.message === "PUC actualizado correctamente 🟢") {
                    await getPyGData();
                }
            } catch (error) {
                console.error('Error al procesar los datos del WebSocket:', error);
            }
        };

        ws.onclose = () => {
            console.log('Desconectado del WebSocket');
        };

        return () => {
            ws.close();
        };
    }, []);


    return (
        <div>
            {loading ? (
                <div>Cargando</div>
            ) : (
                <TablaPyG ejecutarSp={ejecutarSp} data={data} costoVenta={costoVenta} otrosGastos={otrosGastos} />
            )}
        </div>
    );
};

export default PorPyG;
