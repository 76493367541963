import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { CardActionArea, Divider, Grid } from '@mui/material';

// import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import PersonPinRoundedIcon from '@mui/icons-material/PersonPinRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import WalletIcon from '@mui/icons-material/Wallet';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const GestionComercial = () => {

    const gridItems = [
        {
            icon: <ApartmentRoundedIcon style={{ fontSize: "45px" }} />, title: "Visual empresa",
            link: "/Farmanet/GestionComercial/VisualEmpresa"
        },
        {
            icon: <QueryStatsIcon style={{ fontSize: "45px" }} />, title: "Presupuesto",
            link: "/Farmanet/GestionComercial/PresupuestoComercial"
        },
        {
            icon: <AttachMoneyRoundedIcon style={{ fontSize: "40px" }} />, title: "Ventas",
            link: "/Farmanet/GestionComercial/Ventas"
        },
        {
            icon: <WalletIcon style={{ fontSize: "40px" }} />, title: "Cartera",
            link: "/Farmanet/GestionComercial/Cartera"
        },
        // Add more grid items as needed
    ];

    const CustomGridItem = ({ icon, title, link }) => {
        return (
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <Paper variant="elevation" elevation={2} style={{ borderRadius: "12px" }}>
                    <Card sx={{ display: 'flex', height: "130px", borderRadius: "12px" }}>
                        <CardActionArea component="a" href={link} sx={{ display: "flex" }}>
                            <Grid item xs={3} sm={3} md={3}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    // bgcolor="#EEF2FF"
                                    // height="40px"
                                    width="100%"
                                >
                                    {icon}
                                </Box>
                            </Grid>
                            <Divider orientation='vertical' style={{ height: "80px" }} />
                            <Grid item xs={9} sm={9} md={9}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography sx={{ fontSize: 14, color: "#FE0000", }} color="text.secondary" gutterBottom>
                                            Gestión comercial
                                        </Typography>
                                        <Typography variant="h5" component="div" style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                        }}>
                                            {title}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Paper>
            </Grid>
        );
    };

    return (
        <div>
            <Box >
                <Grid item xs={12} sm={6} md={12} >
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Typography variant="h5" color="black" gutterBottom style={{
                            display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                            marginBottom: "25px", marginTop: "1.5rem"
                        }}>
                            <PersonPinRoundedIcon style={{ marginRight: "5px", color: "#FE0000" }} />
                            Gestión Comerial
                        </Typography>
                    </Box>
                </Grid>

                <Grid container spacing={2}>
                    {gridItems.map((item, index) => (
                        <CustomGridItem key={index} {...item} />
                    ))}
                </Grid>
            </Box>


        </div>
    )
}

export default GestionComercial