import React, {
  useContext,
  //  useEffect,
  useState, lazy, Suspense,
  useEffect
} from 'react';
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Cookies from 'js-cookie';
import routes from './routes';
import { AuthContext } from './loginContext';
import './app.css';
// import newRequest from './utils/newRequest';
import { ToastContainer } from 'react-toastify';

import { Plataformas } from "./PaginaWeb/paginas/Plataformas/Plataformas.jsx"
import { FormularioQuejas } from "./PaginaWeb/paginas/FormularioQuejas/FormularioQuejas.jsx"
import { Eventos } from './PaginaWeb/paginas/Eventos/Eventos.jsx';
import MenuLateral from './components/MenuLateral.jsx';
import newRequest from './utils/newRequest.js';
// Lazy loaded components
const Login = lazy(() => import('./Login.jsx'));
// const MenuLateral = lazy(() => import('./components/MenuLateral.jsx'));
// const MenuLateral = lazy(() => import('./components/MenuLateral.jsx'));
const NavbarInicio = lazy(() => import('./components/NavbarInicio/NavbarInicio.jsx'));
const Inicio = lazy(() => import('./PaginaWeb/paginas/inicio/Inicio.jsx'));
const QuienesSomos = lazy(() => import('./PaginaWeb/paginas/QuienesSomos/QuienesSomos.jsx'));
// const Eventos = lazy(() => import('./PaginaWeb/paginas/Eventos/Eventos.jsx'));
const Portafolio = lazy(() => import('./PaginaWeb/paginas/Portafolio/Portafolio.jsx'));

function App() {
  const { currentUser, isAuthenticated, logout } = useContext(AuthContext);
  const [validacion, setvalidacion] = useState(null)

  const Layout = () => {
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
      setOpen(true);
    };

    const handleDrawerClose = () => {
      setOpen(false);
    };

    return (
      <Box sx={{}}>
        {isAuthenticated && (
          <MenuLateral open={open} handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose} />
        )}
        <Box sx={{ paddingLeft: open ? '10rem' : '', width: 'auto', marginLeft: 8 }}>
          <div style={{ padding: "1rem" }}>
            <Outlet />
          </div>

        </Box>
      </Box>
    );
  };

  const InicioLayout = () => (
    <div>
      <NavbarInicio />
      <Outlet />
    </div>
  );

  const ProtectedRoute = ({ children }) => {
    if (!currentUser && !isAuthenticated) {
      return <Navigate to="/login" replace />;
    }
    return children ? children : <Outlet />;
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      // Validamos la sesion del token
      const GetValidar = async () => {
        try {
          // const validar = await newRequest.get('/api/login/validar');
          // setvalidacion(validar.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            // alert("¡Tu sesión ha expirado, vuelve a iniciar la sesion!"); // Mostrar alerta en caso de 401
            console.log("Tu sesion Expiro, Vuelve a iniciar sesión")
            logout(); // Cerrar la sesión
            Cookies.remove("token"); // Eliminar la cookie del token al cerrar sesión
            Cookies.remove("session_id"); // Eliminar la cookie del token al cerrar sesión
            localStorage.removeItem("token"); // Eliminar el valor del almacenamiento local al cerrar sesión
            localStorage.removeItem("session_id"); // Eliminar el valor del almacenamiento local al cerrar sesión
            localStorage.removeItem('selectedIndex')
          } else {
            console.error("Error al validar token:", error);
          }
          console.error("Error al validar token:", error);
        }
      }

      GetValidar(); // Obtener la validación del token al cargar la aplicación
      //Establecer un intervalo para verificar el token cada 30 segundos
      const interval = setInterval(() => {
        GetValidar();
        // }, 30000) //30 segundos
        ;
      }, 4 * 60 * 60 * 1000); // 4 horas en milisegundos
      // Limpieza del intervalo cuando el componente se desmonta o isAuthenticated cambia
      return () => clearInterval(interval);
    }
  }, [isAuthenticated, logout]);

  useEffect(() => {
    if (validacion) {
      const tokenExpiration = validacion.decoded.exp * 1000; // Convertir a milisegundos
      const currentTime = Date.now();
      const timeUntilExpiration = tokenExpiration - currentTime;
      // Mostrar alerta solo si el tiempo hasta la expiración es menor o igual a cero
      if (timeUntilExpiration <= 0) {
        alert("¡Tu sesión ha expirado!");
      }
      else if (timeUntilExpiration <= 60000) { // 60000 milisegundos = 1 minuto
        alert("¡Tu sesión está a punto de expirar!");
      }
    }
  }, [validacion]);

  const router = createBrowserRouter([
    {
      path: '/Farmanet',
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: routes.map((route, index) => ({
        index: index,
        path: route.path,
        element: route.element,
      })),
    },
    {
      path: '/',
      element: <InicioLayout />,
      children: [
        { path: '/', element: <Inicio /> },
        { path: 'QuienesSomos', element: <QuienesSomos /> },
        { path: 'Eventos', element: <Eventos /> },
        { path: 'Portafolio', element: <Portafolio /> },
        { path: 'Contacto', element: <FormularioQuejas /> },
        {
          path: "Plataformas",
          element: isAuthenticated ? <Plataformas /> : <Navigate to="/" />
        },
      ],
    },
    {
      path: '/login',
      element: <Login />,
    },
  ]);

  return (
    <Box>
      <ToastContainer />
      <Suspense fallback={<div>Cargando...</div>}>
        <RouterProvider router={router} />
      </Suspense>
    </Box>
  );
}

export default App;

