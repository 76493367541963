import React, { useEffect, useRef, useMemo } from 'react';
import { Box } from '@mui/material';
import * as echarts from 'echarts';

const GraficoMeses = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial, rows }) => {
    const chartRef = useRef(null);

    // Memoización de los datos del gráfico
    const data = useMemo(() => {
        const nombresMeses = [
            "Ene", "Feb", "Mar", "Abr", "Ma<<<<<<<<<<<<y", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ];

        return rows.map((row, index) => {
            const nombreMes = nombresMeses[row.mes - 1];
            const ventasKey = `Ventas${selectAñoIncial}`;
            const costoKey = `Costo${selectAñoIncial}`;
            const presupuestoKey = `Presupuesto${selectAñoIncial}`;

            const ventas = row[ventasKey];
            const costo = row[costoKey];
            const presupuesto = row[presupuestoKey];
            let tendencia = presupuesto;
            if (index >= 1) {
                const ventasAnterior = +rows[index - 1][ventasKey];
                const presupuestoAnterior = +rows[index - 1][presupuestoKey];
                tendencia += (presupuestoAnterior - ventasAnterior);
            }

            return {
                mes: nombreMes,
                ventas,
                costo,
                presupuesto,
                tendencia
            };
        });
    }, [rows, selectAñoIncial]);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        const option = {
            title: { text: 'Ventas por mes' },
            tooltip: { trigger: 'axis' },
            legend: { top: 'bottom' },
            grid: { left: '3%', right: '4%', containLabel: true },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: data.map(item => item.mes)
            },
            yAxis: { type: 'value' },
            series: [
                { name: 'Ventas', type: 'line', data: data.map(item => item.ventas), smooth: true },
                { name: 'Costo', type: 'line', data: data.map(item => item.costo), smooth: true },
                { name: 'Presupuesto', type: 'line', data: data.map(item => item.presupuesto), smooth: true },
                // { name: 'Tendencia', type: 'line', data: data.map(item => item.tendencia), smooth: true }
            ]
        };

        chartInstance.setOption(option);

        function handleResize() {
            chartInstance.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            chartInstance.dispose();
        };
    }, [data]);

    return (
        <div>
            <Box sx={{ width: "100%", marginTop: "15px" }}>
                <div ref={chartRef} style={{ width: '100%', height: '400px' }}></div>
            </Box>
        </div>
    );
}

export default GraficoMeses;
