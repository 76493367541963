import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { Box } from '@mui/material';

export const GraficoVendedorDos = ({ selectedMesFinal, selectedMesInicial, selectAñoIncial, rows }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        const data = rows.map((row) => {
            // const nombreVende = row.vendedor;
            const nombreVende = row.vendedor.split(' ')[0];
            const propiedades = Object.keys(row);
            const prop1 = propiedades[3].toString();
            const prop2 = propiedades[2].toString();

            let total;
            if (row[prop2] !== 0 && row[prop1] !== 0) {
                total = (row[prop1] / row[prop2] * 100 - 100);
            } else {
                total = 0;
            }
            const formattedTotal = isNaN(total) ? 0 : total.toFixed(2);

            return {
                name: nombreVende,
                venVsPre: parseFloat(formattedTotal),
            };
        });

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                confine: true, // Mantener el tooltip dentro del contenedor
                formatter: (params) => {
                    const value = params[0].value;
                    return `${params[0].name}: ${value.toFixed(2)}%`;
                }
            },
            legend: {
                data: ['Cumplimiento de presupuesto']
            },

            xAxis: {
                type: 'category',
                data: data.map(item => item.name),
                axisLabel: {
                    // interval: 0, 
                    rotate: 60,
                    formatter: function (value) {
                        // Truncar si el nombre es más largo de 10 caracteres y agregar "..."
                        return value.length > 10 ? value.slice(0, 5) + '...' : value;
                    }
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    // fontSize: 15,
                    formatter: (value) => {
                        if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billones
                        if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`; // Millones
                        if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`; // Miles
                        if (value < 0) return `-${(-value / 1e6).toFixed(1)}M`; // Acortar valores negativos
                        return value; // Valor original si no supera los miles
                    }
                }
            },
            series: [
                {
                    name: 'Cumplimiento de presupuesto',
                    type: 'line',
                    data: data.map(item => item.venVsPre),
                    itemStyle: {
                        color: (params) => {
                            return params.value >= 0 ? '#0A6EBD' : 'rgb(255, 89, 89)';
                        }
                    },
                    smooth: true 
                }
            ]
        };

        chartInstance.setOption(option);

        function handleResize() {
            chartInstance.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            chartInstance.dispose();
        };
    }, [rows]);

    return (
        <div>
            <Box sx={{ marginTop: "15px", width: "100%", height: "400px" }} ref={chartRef}></Box>
        </div>
    );
};

export default GraficoVendedorDos;
