import React, { useEffect, useState } from 'react'
import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup, Checkbox, FormControlLabel, InputAdornment, Button, ButtonBase,
    Card, CardContent, CardActions, CardActionArea, IconButton, CardMedia, CardHeader, Tooltip, tooltipClasses
    // Skeleton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

import IndicadorPorcentaje from './IndicadorPorcentaje';
import newRequest from '../../../../../utils/newRequest';

const CartaIndicador = ({ item, chartId, metricas, alignment, selectAñoIncial, mes }) => {

    const BodyConsultaInd = {
        año: selectAñoIncial,
        mes: mes,
        codigoProceso: alignment,
        Idproceso: item.ProEmpSec,
    };

    const [datosInd, setdatosInd] = useState([])

    const ObtenerConsultaIndicadores = async () => {
        try {
            const res = await newRequest.post(
                `/api/GerenciaVisual/Indicadores/ConsultaCmIndicador`,
                BodyConsultaInd,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (res.data.length) {
                setdatosInd(res.data)
            } else {
                setdatosInd([])
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const validacion = () => {
        let c = 0;
        let desempeño = 0;
        let cummeta = 0;
        let cummetasindat = 0;
        let f = 0;
        let pf = 0;
        let rojo = '';
        let amarillo = '';
        let verde = '';
        let recalculopeso = 0;
        let desempeño2 = 0;

        let meta;
        let result;
        let cumplimiento;
        let pesofinal;
        let pesofinal2;
        let meta1, meta2, meta_1, meta_2;

        let resultado;

        if (datosInd[0] !== "vacio") {

            datosInd.forEach(row => {

                if (!isNaN(row['Resultado Indicador'])) {
                    recalculopeso = recalculopeso + row['Ponderacion'];
                }
            });

            datosInd.forEach((row) => {
                // inicio de la condición
                if (row['Meta'] !== '' && row['Resultado Indicador'] !== '' && !isNaN(row['Resultado Indicador'])) {

                    const Criterioo = row['Criterio'].replace(/[ÁáÍí]/g, (match) => {
                        return match == 'Á' ? 'A' : match.toLowerCase();
                    });

                    // console.log("Criterioo", Criterioo)

                    switch (Criterioo.toLowerCase()) {  // Inicio del caso
                        case 'maximo':
                            // Verificar si la cadena contiene el símbolo '%'
                            if (row['Meta'].includes('%')) {
                                // meta = row['Meta'].replace('%', '') / 100;
                                meta = parseFloat(row['Meta'].replace('%', '')) / 100;
                                // console.log("first meta", meta)

                                if (row['Resultado Indicador'] == 0 || row['Resultado Indicador'] == null) {
                                    result = 0;
                                } else {
                                    result = meta / row['Resultado Indicador'];
                                }

                                if (meta >= row['Resultado Indicador']) {
                                    cumplimiento = 1;
                                } else {
                                    cumplimiento = result;
                                }

                            } else {
                                if (row['Resultado Indicador'] == 0 || row['Resultado Indicador'] == null) {
                                    result = 0;
                                } else {
                                    result = row['Meta'] / row['Resultado Indicador'];
                                }

                                if (row['Meta'] >= row['Resultado Indicador']) {
                                    cumplimiento = 1;
                                } else {
                                    cumplimiento = result;
                                }
                            }
                            break;

                        case "minimo":
                            // Verificar si la cadena contiene el símbolo '%'
                            if (row['Meta'].includes('%')) {
                                // meta = row['Meta'].replace('%', '') / 100;
                                meta = (parseFloat(row['Meta'].replace('%', '')) / 100);
                                result = row['Resultado Indicador'] / meta;
                                if (meta <= row['Resultado Indicador']) {
                                    cumplimiento = 1;
                                } else {
                                    cumplimiento = result;
                                }
                            } else {
                                result = row['Resultado Indicador'] / row['Meta'];
                                if (row['Meta'] <= row['Resultado Indicador']) {
                                    cumplimiento = 1;
                                } else {
                                    cumplimiento = result;
                                }
                            }
                            break

                        case 'rango':
                            if (row['Resultado Indicador'] == 0 || row['Resultado Indicador'] == null) {
                                cumplimiento = 'Cum sin Datos';
                            } else {
                                const metax = row['Meta'].split("-");
                                meta1 = metax[0];
                                meta2 = metax[1];

                                // Verificar si la cadena contiene el símbolo '%'
                                if (row['Meta'].includes('%') !== false) {
                                    meta_1 = meta1.replace('%', '', meta1) / 100;
                                    meta_2 = meta2.replace('%', '', meta2) / 100;

                                    if (row['Resultado Indicador'] < meta_1) {
                                        result = row['Resultado Indicador'] / meta_1;
                                        if (meta_1 <= row['Resultado Indicador']) {
                                            cumplimiento = 1;
                                        } else {
                                            cumplimiento = result;
                                        }
                                    }

                                    if (row['Resultado Indicador'] > meta_2) {
                                        result = meta_2 / row['Resultado Indicador'];
                                        if (meta_2 >= row['Resultado Indicador']) {
                                            cumplimiento = 1;
                                        } else {
                                            cumplimiento = result;
                                        }
                                    }

                                    if (row['Resultado Indicador'] < meta_2 && row['Resultado Indicador'] > meta_1) {
                                        cumplimiento = 1;
                                    }

                                } else {
                                    if (row['Resultado Indicador'] < meta1) {
                                        result = row['Resultado Indicador'] / meta1;
                                        if (meta1 <= row['Resultado Indicador']) {
                                            cumplimiento = 1;
                                        } else {
                                            cumplimiento = result;
                                        }
                                    }
                                    if (row['Resultado Indicador'] > meta2) {
                                        result = meta2 / row['Resultado Indicador'];
                                        if (meta2 >= row['Resultado Indicador']) {
                                            cumplimiento = 1;
                                        } else {
                                            cumplimiento = result;
                                        }
                                    }

                                    if (row['Resultado Indicador'] < meta2 && row['Resultado Indicador'] > meta1) {
                                        cumplimiento = 1;
                                    }
                                }
                            }
                            break;
                        default:
                            cumplimiento = "Criterio Erroneo"
                            break
                    } // Fin del caso

                } else { // si no de la condición
                    cumplimiento = 'Cum Sin Datos';
                } // fin de la condiciópn


                // Verificar si la variable es numérica
                if (!isNaN(cumplimiento)) { // Inicio de la condición
                    cummeta = cummeta + (cumplimiento * 100)
                } else {
                    cummetasindat++;
                } // Fin de la condición

                // Inicio de la condición
                if (row['Ponderacion'] == null || row['Ponderacion'] == '' || row['Ponderacion'] == 0) {
                    pf++;
                } // Fin de la condición

                // Inici de la condición
                if (row['Ponderacion'] !== '' && cumplimiento !== '' && !isNaN(row['Ponderacion']) && !isNaN(cumplimiento)) {
                    pesofinal = row['Ponderacion'] * cumplimiento;
                    if (!isNaN(row['Resultado Indicador'])) {
                        pesofinal2 = row['Ponderacion'] / recalculopeso * 100 * cumplimiento;
                        desempeño2 = desempeño2 + pesofinal2;
                    }
                } else {
                    pesofinal = 0;
                } // Fin de la condición

                desempeño = desempeño + pesofinal;
                // console.log("desempeño", desempeño)
                c++;
                f++;
            });


            if (cummetasindat > 0) {
                resultado = desempeño2.toFixed(2).replace(',', '.') + '%';
            } else {
                resultado = desempeño.toFixed(2).replace(',', '.') + '%';
            }

        }
        return { desempeño2, desempeño, cummetasindat, recalculopeso, resultado };
    }

    let { desempeño2, desempeño, cummetasindat, recalculopeso, resultado } = validacion();

    // console.log("resultado :", desempeño2, desempeño, cummetasindat)



    useEffect(() => {
        ObtenerConsultaIndicadores();
    }, [selectAñoIncial, alignment, mes, desempeño2, desempeño, cummetasindat, recalculopeso, resultado])


    return (
        <div>
            <Card elevation={2} sx={{ borderRadius: "13px", height: 160, }}>
                <CardActionArea>
                    <CardContent>
                        <Grid container >
                            <Grid item xs={6}>
                                <Box >
                                    <IndicadorPorcentaje chartId={chartId} porcentaje={resultado} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} >
                                <Box style={{marginLeft:"30px"}}>
                                    <Typography variant='h6' sx={{ textAlign: "left", fontSize: "0.9rem" }}>
                                        Proceso:
                                        {item.ProEmpSec}
                                    </Typography>
                                    <Typography variant='h6' sx={{
                                        textAlign: "left", fontSize: "18px", overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>
                                        {item.ProEmpNom}
                                    </Typography>
                                    <Typography>
                                        {resultado} ({recalculopeso})
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>

                </CardActionArea>
            </Card>
        </div >
    )
}

export default CartaIndicador