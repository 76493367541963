import React, { useContext, useEffect, useState } from 'react'
import {
    Box, Breadcrumbs, Grid, Typography, Fab
    // Skeleton
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../loginContext';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';

import ProcesoEmpresa from './ProcesoEmpresa/ProcesoEmpresa';
import newRequest from '../../../utils/newRequest';
import TipoProcesos from './TipoProcesos/TipoProcesos';
import PerspectivaEmpresa from "./PerspectivaEmpresa/PerspectivaEmpresa.jsx"
import ObjetivoEmpresa from './ObjetivoEmpresa/ObjetivoEmpresa';
import PoliticasEmpresa from './PoliticasEmpresa/PoliticasEmpresa';

import IndicadoresEmpresa from './IndicadoresEmpresa/IndicadoresEmpresa';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import Uno from "../../../images/SinPermiso.jpg"


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Procesos = () => {


    const navigate = useNavigate();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleBack = () => {
        navigate("/Farmanet/Calidad")
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12} >
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                            <Fab onClick={handleBack} style={{ background: "white", width: "45px", height: "45px" }}>
                                <ChevronLeftIcon />
                            </Fab>
                            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                                {/* <Link underline="hover" color="inherit" to="/Farmanet/Calidad">Calidad</Link> */}
                                <Typography color="text.primary">Calidad</Typography>
                                <Typography color="text.primary">Parametros</Typography>
                            </Breadcrumbs>
                        </Box>
                        <Typography variant="h6" color="black" gutterBottom style={{
                            display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                            marginBottom: "25px", marginTop: "10px"
                        }}>
                            {/* <i className="fa-solid fa-gears" style={{
                                    paddingLeft: "5px", marginRight: "10px",
                                    color: "#FF2442"
                                }}></i> */}
                            <ManageAccountsIcon style={{ marginRight: "10px", color: "#FF2442", fontSize: "25px" }} />
                            Parametros
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

                        <Tab label="Procesos" {...a11yProps(0)} style={{ textTransform: "lowercase" }} />
                        <Tab label="Tipo" {...a11yProps(1)} style={{ textTransform: "lowercase" }} />
                        <Tab label="Perspectiva" {...a11yProps(2)} style={{ textTransform: "lowercase" }} />
                        <Tab label="Objetivo" {...a11yProps(3)} style={{ textTransform: "lowercase" }} />
                        <Tab label="Politicas Empresas" {...a11yProps(4)} style={{ textTransform: "lowercase" }} />
                        <Tab label=" Indicadores" {...a11yProps(5)} style={{ textTransform: "lowercase" }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} >
                    <ProcesoEmpresa />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <TipoProcesos />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <PerspectivaEmpresa />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <ObjetivoEmpresa />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={4}>
                    <PoliticasEmpresa />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={5}>
                    <IndicadoresEmpresa />
                </CustomTabPanel>
            </Box>
        </div>
    )
}

export default Procesos