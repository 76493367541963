/* eslint-disable react/jsx-pascal-case */
import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import BarChartIcon from '@mui/icons-material/BarChart';
import Tooltip from '@mui/material/Tooltip';
import {
    MaterialReactTable,
    MRT_ShowHideColumnsButton,
    useMaterialReactTable,
} from 'material-react-table';
import OpcionesTabla from '../VentasPorZona/OpcionesTabla';

const TablaVentasClientes = ({ dataZona, selectedOption, handleOpen, handleOptionChange }) => {
    const campos = dataZona.length > 0 ? Object.keys(dataZona[0]) : [];
    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    const formatNumber = (value) => {
        if (selectedOption === 'a' || selectedOption === 'b') {
            return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP', // Colombian peso
                maximumFractionDigits: 0,
            }).format(value);
        } else if (selectedOption === 'c') {
            return new Intl.NumberFormat(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value);
        }
        return value;
    };

    // Función para calcular la suma de cada fila
    const calculateRowTotal = (row) => {
        return Object.keys(row).reduce((total, key) => {
            if (key !== 'Cliente' && key !== 'Total') {
                return total + (parseFloat(row[key]) || 0);
            }
            return total;
        }, 0);
    };

    // Agregar el total a cada fila
    const dataWithTotals = useMemo(() => {
        return dataZona.map(row => ({
            ...row,
            Total: calculateRowTotal(row),
        }));
    }, [dataZona]);

    // Calcula la suma total de todos los totales
    const sumaTotal = useMemo(() => {
        return dataWithTotals.reduce((total, row) => total + row.Total, 0);
    }, [dataWithTotals]);

    const columns = [
        {
            id: 'Cliente',
            accessorKey: 'Cliente',
            header: 'Cliente',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            size: 10,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    width: "10rem",
                    maxWidth: "10rem",
                },
            },
            Cell: ({ cell }) => (
                <Tooltip title={cell.row.original.Cliente}>
                    <div>
                        {cell.row.original.Cliente}
                    </div>
                </Tooltip>
            ),
            footer: 'Total',
        },
        ...campos
            .filter(campo => campo !== 'Cliente' && campo !== 'Total')
            .map((campo, index) => ({
                id: campo,
                accessorKey: campo,
                header: meses[index % meses.length],
                enableColumnActions: false,
                muiTableHeadCellProps: {
                    align: 'center',
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: index % 2 === 0 ? '#1C64F2' : '#27AE60',
                        color: "white",
                    }
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                size: 10,
                Cell: ({ cell }) => (
                    <div>
                        {formatNumber(cell.getValue())}
                    </div>
                ),
                Footer: ({ column }) => {
                    const sum = dataWithTotals.reduce((total, row) => total + (parseFloat(row[column.id]) || 0), 0);
                    return (
                        <div style={{ alignItems: "left", display: "flex", justifyContent: "left" }}>
                            {formatNumber(sum)}
                        </div>
                    );
                },
            })),
        {
            id: 'Total',
            accessorKey: 'Total',
            header: 'Total',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            size: 10,
            Cell: ({ cell }) => (
                <div>
                    {formatNumber(cell.getValue())}
                </div>
            ),
        },
    ];


    const table = useMaterialReactTable({
        columns,
        data: dataWithTotals,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: {
            columnPinning: { left: ['Cliente'], right: ['Total'] },
            density: 'compact'
        },
        localization: {
            toggleDensity: "Alternar densidad",
            rowsPerPage: "Filas por página",
            toggleFullScreen: "Alternar pantalla completa",
            search: "Buscar",
            columnActions: "mostrar/ocultar columnas",
            showHideSearch: "Filtro de busqueda",
            showHideColumns: "mostrar / ocultar columnas"
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <div style={{ gap: '1rem', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant='lg' fontWeight="bold">Ventas por cliente</Typography>
                <OpcionesTabla onOptionChange={handleOptionChange} //aqui pasa el hijo
                    selectedOptionPadre={selectedOption} />
            </div>
        ),
        renderBottomToolbarCustomActions: ({ table }) => (
            <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: "bolder", marginTop: 1 }} gutterBottom>
                    Total General:
                    <span style={{ fontSize: "17px", marginLeft: "10px" }}>
                        {formatNumber(sumaTotal)}
                    </span>
                </Typography>
            </Box>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <Box>
                <MRT_ShowHideColumnsButton table={table} />
                <Tooltip title="Gráfica">
                    <IconButton onClick={handleOpen}>
                        <BarChartIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        muiTableContainerProps: { sx: { height: "24rem", width: "100%" } }
    });

    return (
        <Box sx={{ height: "100%", width: "100%" }}>
            <MaterialReactTable table={table} />
        </Box>
    );
};

export default TablaVentasClientes;
