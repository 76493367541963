import React, { useEffect, useState } from 'react'
import { Box, Grid, Paper, Typography, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';

import newRequest from '../../../../utils/newRequest';

import ListaIndicadores from './ListaIndicadores';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    const hidden = value !== index;


    return (
        <Box
            role="tabpanel"
            hidden={hidden}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const IndicadoresEmpresa = () => {

    const [loading, setLoading] = useState(false);
    const [proceso, setProceso] = useState([]);

    const obtenerProceso = async () => {
        try {
            setLoading(true)
            const res = await newRequest.get(`/api/Calidad/Indicadores/ProcesosIndicador`);
            const newData = res.data.length > 0 ? res.data : [];
            setProceso(newData);
        } catch (error) {
            console.log("error", error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        obtenerProceso();
    }, []);

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} >

                    {loading ?
                        <LinearProgress />
                        :
                        <Paper variant="outlined" sx={{
                            p: { xs: 1, md: 1 }, borderRadius: "13px",
                            backgroundColor: "white"
                        }}>
                            <Box
                                sx={{
                                    marginTop: 2,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Box >
                                    <ListaIndicadores
                                        proceso={proceso}
                                        obtenerProceso={obtenerProceso}
                                    />
                                </Box>
                            </Box>
                        </Paper>
                    }

                </Grid>
            </Grid>
        </div>
    )
}

export default IndicadoresEmpresa