import React, { useContext, useEffect, useState, lazy, Suspense, useRef, useCallback } from 'react'
import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup, IconButton,
    Divider,
    Fab
    // Skeleton
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import newRequest from '../../../utils/newRequest';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PaidIcon from '@mui/icons-material/Paid';

// import PorPresupuesto from './PorPresupuesto/PorPresupuesto';
// import PorArticulo from './PorArticulo/PorArticulo';
// import ClienteSinProducto from './ClienteSinProducto/ClienteSinProducto';

// import TablaArticulos from './PorPresupuesto/TablaArticulos';
const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-11)

const PorPresupuesto = lazy(() => import('./PorPresupuesto/PorPresupuesto'));
const ClienteSinProducto = lazy(() => import('./ClienteSinProducto/ClienteSinProducto'));
const PorArticulo = lazy(() => import('./PorArticulo/PorArticulo'));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
    return { name, calories, fat };
}


const rowsPerPageOptions = [5, 10, 25]; // Opciones para el número de filas por página

const PresupuestoComercial = () => {
    const fileInputRef = useRef(null);

    const navigate = useNavigate();

    // const { estadoFechasetVisual } = useContext(ProfundidaContext); // SE CAPTURA LA INFORMACION DEL CONTEXT

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

    const [añoSelect, setAñoSelect] = useState([]);

    const [selectAñoIncial, setAñoInical] = useState(currentYear);
    const [selectedMesInicial, setSelectedMesInicial] = useState(currentMonth);

    // ? Seleccionar Año y Mes final
    // const [selectAñoFinal, setAñoFinal] = useState(currentYear);
    const [selectedMesFinal, setSelectedMesFinal] = useState(currentMonth);

    //  ? Produto seleccionado
    const [selectProduct, setSelectProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);

    // ? Cliente seleccionado
    const [selectCliente, setSelectCliente] = useState([])
    const [selectedCliente, setSelectedCliente] = useState(null)

    // ? Opciones del tercero
    const [selectTercero, setSelectTercero] = useState([])
    const [selectedTercero, setSelectedTercero] = useState(null)

    // ? Opciones de los vendedores
    const [selectVendedor, setSelectVendedor] = useState([])
    const [selectedVendedor, setSelectedVendedor] = useState(null)

    const [arcExcel, setarcExcel] = useState("second")

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let [loadinDatos, setloadinDatos] = useState([])

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        // Aquí puedes realizar acciones con el archivo seleccionado, como cargarlo o mostrar su contenido
        console.log('Archivo seleccionado:', file);

        if (file) {
            const formData = new FormData();
            formData.append('archivoExcel', file);
            try {
                const res = await newRequest.post('/api/GerenciaVisual/Presupuesto/CarguePresupuesto', formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data', // Utiliza este encabezado para enviar datos binarios
                        },
                    }
                );
                console.log('Respuesta del servidor:', res.data);
                setloadinDatos(res.data)

            } catch (error) {
                console.error('Error al enviar el archivo al servidor:', error);
            }

            console.log("arcExcel", arcExcel.type)
        } else {
            alert("Selecciona una arhivo")
        }
    };

    console.log("loadinDatos", loadinDatos)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, loadinDatos?.rowsData?.length - page * rowsPerPage);

    console.log("emptyRows", emptyRows, loadinDatos?.rowsData?.length)

    const añoInicial = (year) => {
        // setCondicionFecha(true)
        // estadoFechasetVisual(true)
        if (year <= currentYear) {
            setAñoInical(year);
            setSelectedMesInicial(1);
            setSelectedMesFinal(12)
        } else {
            // Muestra una alerta o realiza alguna acción para indicar que la selección no es válida
            Store.addNotification({
                title: "La selección no es válida.",
                message: "El año inicial no puede ser mayor al año final",
                type: "info",
                showIcon: true,
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                },
            });
        }
    };

    useEffect(() => {
        //Aqui se obtiene el año anterior y año actual
        const fetchData = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno')
                setAñoSelect(res.data.reverse());
            } catch (error) {
                console.error(error);
            }
        }

        const selectCliente = async () => {
            try {
                const resCliente = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasClientesSelect')
                setSelectCliente(resCliente.data)
            } catch (error) {
                console.log("error 🦋", error)
            }
        }

        const selectArticulos = async () => {
            try {
                const resProductos = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasArticulosSelect')
                setSelectProduct(resProductos.data);
            } catch (error) {
                console.log("error 🦋", error)
            }
        };

        const selectTercero = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasTerceroSelect');
                setSelectTercero(res.data)
            } catch (error) {
                console.log("error 🦋", error)

            }
        }

        const selectVendedor = async () => {
            try {
                const res = await newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasVendedorSelect');
                // setSelectVendedor(res.data)
                if (res.data.length > 0) {
                    setSelectVendedor(res.data);
                } else {
                    setSelectVendedor([])
                }
            } catch (error) {
                console.log("error 🦋", error)
            }
        }

        selectVendedor();
        selectCliente();
        selectArticulos();
        selectTercero();
        fetchData();

    }, [selectAñoIncial, selectedMesInicial, selectedMesFinal,
        selectedProduct, selectedCliente, selectedTercero, selectedVendedor])

    const handleBack = useCallback(() => navigate("/Farmanet/GestionComercial"), [navigate]);
    return (
        <div>
            <Box >

                <Grid item xs={12} sm={6} md={12}>
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Fab onClick={() => navigate(-1)} style={{ background: "white", width: "45px", height: "45px" }}>
                                <ChevronLeftIcon />
                            </Fab>
                            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                                <Typography color="text.primary">Volver</Typography>
                                {/* <Typography color="text.primary">Gestion Comercial</Typography> */}
                                {/* <Typography color="text.primary">Presupuesto</Typography> */}
                            </Breadcrumbs>
                        </Box>
                        <Typography variant="h6" color="black" gutterBottom style={{
                            display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                            marginBottom: "25px", marginTop: "10px"
                        }}>
                            <PaidIcon sx={{ marginRight: "10px", color: "#FE0000" }} />
                            Presupuesto
                        </Typography>
                    </Box>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                            style={{
                                borderRadius: "13px",
                                position: "sticky",
                                top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                                zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                                boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                                transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                            }}
                        >
                            <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1, justifyItems: "center", }}>
                                <Grid container spacing={1} alignItems="center" sx={{ margin: 1, marginTop: "8px", justifyContent: "space-between", display: "flex", justifyItems: "center", }}>
                                    {/* Aqui va el año */}
                                    <Grid item xs={12} sm={1} md={5} lg={5} xl={5} sx={{ display: "flex" }}>
                                        <Stack direction="row" alignItems="center" spacing={0.5}>
                                            {añoSelect.map((año) => (
                                                <div key={año.PerMesano}>
                                                    <ToggleButtonGroup
                                                        color="primary" value={selectAñoIncial}
                                                        exclusive onClick={() => añoInicial(año.PerMesano)}
                                                        sx={{ height: "30px" }}
                                                    >
                                                        <ToggleButton key={año.PerMesano}
                                                            style={{
                                                                backgroundColor: selectAñoIncial === año.PerMesano ? '#1976d2' : 'initial',
                                                                color: selectAñoIncial === año.PerMesano ? 'white' : 'initial',
                                                            }}
                                                            value={año.PerMesano}>
                                                            {año.PerMesano}
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </div>
                                            ))}
                                        </Stack>
                                    </Grid>

                                    {/* Aqui va los select */}
                                    <Grid item xs={12} sm={11} md={7} lg={7} xl={7} sx={{}}>

                                        <div style={{ display: "flex" }}>
                                            {/* Tercero */}
                                            <Grid container item xs={12} sm={6}>
                                                {/* <Autocomplete
                                                    id="cliente" name="Articulo" size="small" autoComplete={false}
                                                    options={selectTercero} getOptionLabel={(option) => option.tercero}
                                                    fullWidth sx={{ padding: "5px" }}
                                                    onChange={(event, newValue) => {
                                                        setSelectedTercero(newValue?.nitSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                        // estadoFechasetVisual(true)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Tercero" variant="outlined" size='small'
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, height: 35 },

                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div>Valor seleccionado Tercero: {selectedTercero}</div> */}
                                            </Grid>

                                            {/* Cliente */}
                                            <Grid container item xs={12} sm={6}>
                                                {/* <Autocomplete
                                                    id="cliente" name="Articulo" size="small" autoComplete={false}
                                                    options={selectCliente} getOptionLabel={(option) => option.cliente}
                                                    fullWidth sx={{ padding: "5px" }}
                                                    onChange={(event, newValue) => {
                                                        setSelectedCliente(newValue?.NitSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Cliente" variant="outlined" size='small'
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, height: 35 },
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <div>Valor seleccionado cliente: {selectedCliente}</div> */}
                                                <Autocomplete
                                                    id="Vendedor" name="Vendedor" margin="dense" size="small" autoComplete={false}
                                                    options={selectVendedor} sx={{ padding: "5px" }}
                                                    getOptionLabel={(option) => option.VenNom}
                                                    fullWidth
                                                    onChange={(event, newValue) => {
                                                        setSelectedVendedor(newValue?.VenCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                        // estadoFechasetVisual(true)
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Vendedor" size="small" variant="outlined"
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, height: 35 },
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <div>Valor seleccionado vendedor: {selectedVendedor}</div> */}
                                            </Grid>

                                            {/* Producto */}
                                            <Grid container item xs={12} sm={6}>
                                                <Autocomplete
                                                    id="cliente" name="Articulo" size="small" autoComplete={false}
                                                    options={selectProduct} getOptionLabel={(option) => option.producto}
                                                    fullWidth sx={{ padding: "5px" }}
                                                    onChange={(event, newValue) => {
                                                        setSelectedProduct(newValue?.ArtSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Producto" variant="outlined" size='small'
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: { fontSize: 14, height: 35 },
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {/* <div>Valor seleccionado producto: {selectedProduct}</div> */}
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>

                        <Box sx={{ marginBottom: 3 }}>
                            <Paper variant="outlined" sx={{
                                p: { xs: 1, md: 1 }, borderRadius: "13px",
                                marginTop: 3,
                            }}>

                                <ClienteSinProducto selectedCliente={selectedCliente} selectedTercero={selectedTercero}
                                    selectAñoIncial={selectAñoIncial} selectedVendedor={selectedVendedor} />

                                <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                                <PorPresupuesto selectedProduct={selectedProduct} selectedCliente={selectedCliente}
                                    selectedTercero={selectedTercero} selectAñoIncial={selectAñoIncial} selectedVendedor={selectedVendedor} />

                                <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                                <PorArticulo selectedProduct={selectedProduct} selectedCliente={selectedCliente}
                                    selectedTercero={selectedTercero} selectAñoIncial={selectAñoIncial} />
                            </Paper>
                        </Box>

                        {/* <Suspense fallback={<div> Cargando..</div>}>
                            <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3 }}>
                                
                            </Paper>

                            <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3 }}>
                               
                            </Paper>

                            <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3, marginBottom: 3 }}>
                              
                            </Paper>

                        </Suspense> */}

                        {/* <Suspense fallback={<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Cargando..</div>}>
                            <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3 }}>
                                <PorPresupuesto selectedProduct={selectedProduct} selectedCliente={selectedCliente}
                                    selectedTercero={selectedTercero} selectAñoIncial={selectAñoIncial} selectedVendedor={selectedVendedor} />
                            </Paper>
                        </Suspense> */}

                        {/* <Suspense fallback={<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Cargando..</div>} >
                            <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3, marginBottom: 3 }}>
                                <PorArticulo selectedProduct={selectedProduct} selectedCliente={selectedCliente}
                                    selectedTercero={selectedTercero} selectAñoIncial={selectAñoIncial} />
                            </Paper>
                        </Suspense> */}
                    </Grid>
                </Grid>
            </Box >

        </div >
    )
}

export default PresupuestoComercial