import { Typography, Grid, Box, } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from './loginContext';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';
import WbCloudyRoundedIcon from '@mui/icons-material/WbCloudyRounded';
import { VistaVendedores } from './Proyectos/Inicio/VistaVendedores/VistaVendedores';

const Home = () => {
    const { currentUser } = useContext(AuthContext);
    const [horario, setHorario] = useState("");

    useEffect(() => {
        const today = new Date();
        const curHr = today.getHours();

        if (curHr < 12) {
            setHorario(
                <WbCloudyRoundedIcon sx={{ marginRight: "10px", color: "#FE0000" }} />
            );
        } else if (curHr < 18) {
            setHorario(
                <WbSunnyRoundedIcon sx={{ marginRight: "10px", color: "#FE0000" }} />
            )
        } else {
            setHorario(
                <NightsStayIcon sx={{ marginRight: "10px", color: "#FE0000" }} />
            )
        }
    }, [currentUser.userData.UsuNom]);

    const rol = currentUser.userData.PerUsuCod[0]

    console.log("sdadsdasasasdsadas", currentUser.userData.PerUsuCod[0])

    return (
        <div>

            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Typography variant="h5" color="black" gutterBottom
                        style={{
                            display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                            marginBottom: "25px", marginTop: "1.5rem", fontWeight: "normal"
                        }}>
                        {horario} Dashboard
                    </Typography>
                </Box>
            </Grid>
            {rol === 21 ? < VistaVendedores /> : ""}
        </div >
    )
}

export default Home;