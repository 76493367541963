// SelectorPanel.jsx
import React from 'react';
import {
    Box, Grid, Paper, Stack, ToggleButton, ToggleButtonGroup,
    FormControl, InputLabel, Select, MenuItem, Typography
} from '@mui/material';

const SelectorPanel = ({
    añoSelect, selectAñoIncial, añoInicial, meses, mes, handleChange,
    indicadoresNombre, selectedIndicador, handleChangeNombre, alignment, handleChangeTipo, nombreInd
}) => {
    return (
        <Paper variant="elevation" square 
            style={{
                borderRadius: "13px",
                position: "sticky",
                top: 55,
                zIndex: 3,
                boxShadow: "0px 0px 30px 10px rgba(0, 0, 0, 0.2)",
                transition: "box-shadow 0.3s",
            }}
        >
            <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1 }}>
                <Grid container spacing={1} alignItems="center" sx={{
                    marginBottom: "17px", marginLeft: "10px", marginRight: "10px", marginTop: "8px",
                    justifyContent: "space-between", display: "flex", justifyItems: "center",
                }}>
                    {/* Aqui va el año */}
                    <Grid item xs={3} sm={2} md={1} lg={1} xl={1} sx={{ display: "flex" }}>
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                            {añoSelect.map((año, index) => (
                                <div key={index}>
                                    <ToggleButtonGroup
                                        color="primary" value={selectAñoIncial}
                                        exclusive onClick={() => añoInicial(año.PerMesano)}
                                        sx={{ height: "30px" }}
                                    >
                                        <ToggleButton key={año.PerMesano}
                                            style={{
                                                backgroundColor: selectAñoIncial === año.PerMesano ? '#1976d2' : 'initial',
                                                color: selectAñoIncial === año.PerMesano ? 'white' : 'initial',
                                            }}
                                            value={año.PerMesano}>
                                            {año.PerMesano}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>
                            ))}
                        </Stack>
                    </Grid>

                    <Grid item xs={6} sm={4} md={2} lg={4} xl={4} >
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label">Mes del indicador</InputLabel>
                            <Select
                                value={mes}
                                label="Mes del indicador"
                                onChange={handleChange}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 250,
                                        },
                                    }
                                }}
                            >
                                {meses.map((item, index) => (
                                    <MenuItem key={item} value={index}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4} md={2} lg={2} xl={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id="demo-simple-select-label">Proceso</InputLabel>
                            <Select
                                value={selectedIndicador}
                                label="Proceso"
                                onChange={handleChangeNombre}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 250,

                                        },
                                    }
                                }}
                            >
                                {indicadoresNombre.map((item, index) => (
                                    <MenuItem key={item.ProEmpSec} value={item.ProEmpSec}>
                                        {item.ProEmpNom}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* {selectedIndicador} */}
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2} sx={{
                        display: "flex", justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <ToggleButtonGroup size='small'
                            color="primary" value={alignment} exclusive
                            onChange={handleChangeTipo}
                        >
                            <ToggleButton value="ESTRA">Estrategicos</ToggleButton>
                            <ToggleButton value="OPERA">Operativos</ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} lg={2} xl={2} sx={{
                        display: "flex", justifyContent: "end", alignItems: "end",
                    }}>
                        <Box sx={{
                            display: "flex", alignItems: "center", justifyContent: "center",
                        }}>
                            <Typography variant='body1'
                            >
                                {nombreInd}
                            </Typography>
                        </Box>

                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

export default SelectorPanel;
