import { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
} from 'material-react-table';
import {
    Box, Typography, IconButton, Tooltip, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Button, TextField
} from '@mui/material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RefreshIcon from '@mui/icons-material/Refresh';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const TablaResultadoIndicador = ({ datosPorIndicador, datos }) => {

    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
        'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciciembre']

    const [currentHeader, setCurrentHeader] = useState('');
    const [currentObservation, setCurrentObservation] = useState('');


    const duplicatedData = meses.map((mes, index) => {
        // Encuentra el dato correspondiente al mes actual o crea un objeto vacío si no hay datos
        const dataForMonth = datosPorIndicador.find(item => item.DatIndMes === index + 1) || {};
        return {
            Mes: mes,
            // 'DatIndDatUno': dataForMonth.DatIndDatUno || 0,
            'DatIndDatUno': dataForMonth.DatIndDatUno,
            'DatIndDatDos': dataForMonth.DatIndDatDos,
            DatIndRes: dataForMonth.DatIndRes,
            DatIndAcum: dataForMonth.DatIndAcum,
            'DatIndResPro': dataForMonth.DatIndResPro,
            'DatIndAnaObs': dataForMonth.DatIndAnaObs,
            'DatIndMes': dataForMonth.DatIndMes,
            'IndProAno': dataForMonth.IndProAno,
        };
    });

    // console.log("datosPorIndicador datosPorIndicador", datosPorIndicador)


    const [open, setOpen] = useState(false);

    const handleOpen = (observation, header) => {
        setCurrentObservation(observation);
        setCurrentHeader(header)
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const tipo = datos?.IndProTipCod2;

    const columns = [
        {
            accessorKey: 'A',
            header: 'A',
            // enableSorting: false, // deshabilita la ordenación para esta columna
            // enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2',
                    fontWeight: 'normal',
                    fontSize: '15px !important',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            size: 20,
            Cell: ({ cell, row }) => {
                const hasObservations = cell.row.original;
                const observation = hasObservations?.DatIndAnaObs?.[0] || '';
                const header = `${hasObservations?.IndProAno}-${hasObservations?.DatIndMes}`
                return (
                    <div>
                        {/* {duplicatedData.DatIndAnaObs  ? ('x'): ("-")} */}
                        {observation ? (<ManageSearchIcon onClick={() => handleOpen(observation, header)} />) : "-"}
                    </div>
                );
            },
        },
        {
            accessorKey: 'Mes',
            header: 'Mes',
            size: 20,
            muiTableHeadCellProps: {
                align: 'left',
                sx: {
                    backgroundColor: '#E74C3C',
                    fontWeight: 'normal',
                    fontSize: '15px !important',
                    color: "white"
                }
            },
        },
        {
            accessorKey: 'DatIndDatUno',
            // header: `${datos?.IndProRel}`,
            header: (
                <Tooltip title={datos?.IndProRel} arrow>
                    <Typography
                        sx={{
                            fontSize: "14px",  // Ajusta este valor según tus necesidades
                            whiteSpace: "normal",
                            textOverflow: "ellipsis",
                            height: "40px",  // Ajusta este valor según tus necesidades
                            textTransform: "capitalize"
                        }}
                    >
                        {datos?.IndProRel}
                    </Typography>
                </Tooltip>
            ),
            size: 10,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#1C64F2',
                    fontWeight: 'normal',

                    fontSize: '15px !important',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            accessorKey: 'DatIndDatDos',
            // header: `${datos?.IndProRelDos}`,
            header: (
                <Tooltip title={datos?.IndProRelDos} arrow>
                    <Typography
                        sx={{
                            fontSize: "14px",  // Ajusta este valor según tus necesidades
                            whiteSpace: "normal",
                            textOverflow: "ellipsis",
                            height: "40px",  // Ajusta este valor según tus necesidades
                            textTransform: "capitalize"
                        }}
                    >
                        {datos?.IndProRelDos}
                    </Typography>
                </Tooltip>
            ),
            size: 10,
            muiTableHeadCellProps: {
                sx: {
                    backgroundColor: '#1C64F2',
                    fontWeight: 'normal',
                    fontSize: '15px !important',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'right',
            },
        },
        {
            accessorKey: 'DatIndRes',
            header: 'Resultado',
            size: 10,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2',
                    fontWeight: 'normal',
                    fontSize: '15px !important',
                    color: "white"
                }
            },
            muiTableBodyCellProps: ({ cell }) => {
                // Convierte los valores de porcentaje a números
                // const porcentajeEmpresa = parseFloat(datos?.IndProEmpresa.replace('%', ''));
                // const porcentajeEmpresaDos = parseFloat(datos?.IndProEmpresaDos.replace('%', ''));

                // const porcentajeEmpresa = parseFloat(datos?.IndProEmpresa?.replace('%', '')) || 0;
                // const porcentajeEmpresaDos = parseFloat(datos?.IndProEmpresaDos?.replace('%', '')) || 0;

                const porcentajeEmpresa = parseFloat((datos?.IndProEmpresa || '').replace('%', '')) || 0;
                const porcentajeEmpresaDos = parseFloat((datos?.IndProEmpresaDos || '').replace('%', '')) || 0;
                const datIndRes = parseFloat(cell.row.original.DatIndRes);

                return {
                    align: 'right',
                    sx: {
                        // backgroundColor:
                        //     tipo === "ULT" ?
                        //         datIndRes < porcentajeEmpresa || datIndRes < porcentajeEmpresaDos
                        //             ? '#8ADAB2' // Verde si es menor
                        //             : datIndRes > porcentajeEmpresa || datIndRes > porcentajeEmpresaDos
                        //                 ? '#FFCDD2' // Rojo si es mayor
                        //                 : '' // Sin color si no cumple ninguna condición
                        //         // border: '1px solid rgba(224, 224, 224, 1)',
                        //         : "",
                        backgroundColor:
                            tipo === "ULT" ?
                                (datIndRes < porcentajeEmpresa || datIndRes < porcentajeEmpresaDos
                                    ? '#8ADAB2' // Verde si es menor
                                    : (datIndRes > porcentajeEmpresa || datIndRes > porcentajeEmpresaDos
                                        ? '#FFCDD2' // Rojo si es mayor
                                        : '')) // Sin color si no cumple ninguna condición
                                : "",
                        // border: '1px solid rgba(224, 224, 224, 1)',
                    },
                };
            },
            Cell: ({ cell, row }) => (
                <Box>
                    <Typography sx={{ fontSize: '0.9rem !important', }}>
                        {/* {cell.row.original.DatIndRes !== null && cell.row.original.DatIndRes !== undefined
                            ? (datos?.IndProEmpresa && datos?.IndProEmpresa.includes('%')) ||
                                (datos?.IndProEmpresaDos && datos?.IndProEmpresaDos.includes('%'))
                                ? `${cell.row.original.DatIndRes}%`
                                : cell.row.original.DatIndRes
                            : ''} */}
                        {cell.row.original.DatIndRes !== null && cell.row.original.DatIndRes !== undefined
                            ? (
                                (datos?.IndProEmpresa && datos?.IndProEmpresa.includes('%')) ||
                                (datos?.IndProEmpresaDos && datos?.IndProEmpresaDos.includes('%'))
                            )
                                ? `${cell.row.original.DatIndRes}%`
                                : cell.row.original.DatIndRes
                            : ''}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorKey: 'DatIndAcum',
            header: 'Acumulado',
            size: 10,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2',
                    color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            muiTableBodyCellProps: ({ cell }) => {
                // Convierte los valores de porcentaje a números
                const porcentajeEmpresa = parseFloat((datos?.IndProEmpresa || '').replace('%', '')) || 0;
                const porcentajeEmpresaDos = parseFloat((datos?.IndProEmpresaDos || '').replace('%', '')) || 0;
                const datIndRes = parseFloat(cell.row.original.DatIndRes);

                return {
                    align: 'right',
                    sx: {
                        // backgroundColor:
                        //     tipo === "ACU" ?
                        //         datIndRes < porcentajeEmpresa || datIndRes < porcentajeEmpresaDos
                        //             ? '#6BCB77' // Verde si es menor
                        //             : datIndRes > porcentajeEmpresa || datIndRes > porcentajeEmpresaDos
                        //                 ? '#FF6B6B' // Rojo si es mayor
                        //                 : '' // Sin color si no cumple ninguna condición
                        //         // border: '1px solid rgba(224, 224, 224, 1)',
                        //         : "",
                        backgroundColor:
                            tipo === "ACU" ?
                                (datIndRes < porcentajeEmpresa || datIndRes < porcentajeEmpresaDos
                                    ? '#8ADAB2' // Verde si es menor
                                    : (datIndRes > porcentajeEmpresa || datIndRes > porcentajeEmpresaDos
                                        ? '#FFCDD2' // Rojo si es mayor
                                        : '')) // Sin color si no cumple ninguna condición
                                : "",
                        // border: '1px solid rgba(224, 224, 224, 1)',
                    },
                };
            },
            Cell: ({ cell, column }) => (
                <Box>
                    <Typography sx={{ fontSize: '0.9rem !important', }}>
                        {/* {cell.row.original.DatIndAcum !== null && cell.row.original.DatIndAcum !== undefined
                            ? (datos?.IndProEmpresa && datos?.IndProEmpresa.includes('%')) ||
                                (datos?.IndProEmpresaDos && datos?.IndProEmpresaDos.includes('%'))
                                ? `${cell.row.original.DatIndAcum}%`
                                : cell.row.original.DatIndAcum
                            : ''} */}
                        {cell.row.original.DatIndAcum !== null && cell.row.original.DatIndAcum !== undefined
                            ? (
                                (datos?.IndProEmpresa && datos?.IndProEmpresa.includes('%')) ||
                                (datos?.IndProEmpresaDos && datos?.IndProEmpresaDos.includes('%'))
                            )
                                ? `${cell.row.original.DatIndAcum}%`
                                : cell.row.original.DatIndAcum
                            : ''}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorKey: 'DatIndResPro',
            header: 'Promedio',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#27AE60',
                    color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            size: 10,
            muiTableBodyCellProps: ({ cell }) => {
                // Convierte los valores de porcentaje a números
                // const porcentajeEmpresa = parseFloat(datos?.IndProEmpresa.replace('%', '')) || 0;
                // const porcentajeEmpresaDos = parseFloat(datos?.IndProEmpresaDos.replace('%', '')) || 0;

                const porcentajeEmpresa = parseFloat((datos?.IndProEmpresa || '').replace('%', '')) || 0;
                const porcentajeEmpresaDos = parseFloat((datos?.IndProEmpresaDos || '').replace('%', '')) || 0;

                const datIndRes = parseFloat(cell.row.original.DatIndRes);

                return {
                    align: 'right',
                    sx: {
                        // backgroundColor:
                        //     tipo === "PRO" ?
                        //         datIndRes < porcentajeEmpresa || datIndRes < porcentajeEmpresaDos
                        //             ? '#8ADAB2' // Verde si es menor
                        //             : datIndRes > porcentajeEmpresa || datIndRes > porcentajeEmpresaDos
                        //                 ? '#FFCDD2' // Rojo si es mayor
                        //                 : '' // Sin color si no cumple ninguna condición
                        //         // border: '1px solid rgba(224, 224, 224, 1)',
                        //         : "",
                        backgroundColor:
                            tipo === "PRO" ?
                                (datIndRes < porcentajeEmpresa || datIndRes < porcentajeEmpresaDos
                                    ? '#8ADAB2' // Verde si es menor
                                    : (datIndRes > porcentajeEmpresa || datIndRes > porcentajeEmpresaDos
                                        ? '#FFCDD2' // Rojo si es mayor
                                        : '')) // Sin color si no cumple ninguna condición
                                : "",
                        // border: '1px solid rgba(224, 224, 224, 1)',
                    },
                };
            },
            Cell: ({ cell, column }) => (
                <Box>
                    <Typography sx={{ fontSize: '0.9rem !important', }}>
                        {/* {cell.row.original.DatIndResPro !== null && cell.row.original.DatIndResPro !== undefined
                            ? (datos?.IndProEmpresa && datos?.IndProEmpresa.includes('%')) ||
                                (datos?.IndProEmpresaDos && datos?.IndProEmpresaDos.includes('%'))
                                ? `${cell.row.original.DatIndResPro}%`
                                : cell.row.original.DatIndResPro
                            : ''} */}
                        {cell.row.original.DatIndResPro !== null && cell.row.original.DatIndResPro !== undefined
                            ? (
                                (datos?.IndProEmpresa && datos?.IndProEmpresa.includes('%')) ||
                                (datos?.IndProEmpresaDos && datos?.IndProEmpresaDos.includes('%'))
                            )
                                ? `${cell.row.original.DatIndResPro}%`
                                : cell.row.original.DatIndResPro
                            : ''}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorKey: 'Act',
            header: 'Act',
            muiTableHeadCellProps: {
                align: "center",
                sx: {
                    backgroundColor: '#27AE60',
                    color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            size: 20,
            muiTableBodyCellProps: ({ row }) => ({
                align: 'center',
                sx: {
                    // backgroundColor: row.index < datosPorIndicador.length ? '#EC8F5E' : '',
                    // border: '1px solid rgba(224, 224, 224, 1)',
                }
            }),
            Cell: ({ cell, row }) => {

                const Mensual = datos?.IndProFre === "Mensual";
                const Bimestral = datos?.IndProFre === "Bimestral";
                const Trimestral = datos?.IndProFre === "Trimestral";
                const Cuatrimestral = datos?.IndProFre === "Cuatrimestral";
                const Semestral = datos?.IndProFre === "Semestral";
                const Anual = datos?.IndProFre === "Anual";

                // Determinar si el ícono debe mostrarse según el tipo de frecuencia
                const MostrarIcono =
                    (Mensual && (cell.row.index + 1) % 1 === 0) ||
                    (Bimestral && (cell.row.index + 1) % 2 === 0) ||
                    (Trimestral && (cell.row.index + 1) % 3 === 0) ||
                    (Cuatrimestral && (cell.row.index + 1) % 4 === 0) ||
                    (Semestral && (cell.row.index + 1) % 6 === 0) ||
                    (Anual && (cell.row.index + 1) % 12 === 0);
                return (
                    <div>

                        {row.index < datosPorIndicador.length ?
                            MostrarIcono && (
                                <Tooltip title="Recargar">
                                    <IconButton sx={{ background: "white" }}
                                    //  onClick={() => handleButtonClick(cell.row.original)}
                                    >
                                        <RotateLeftIcon sx={{
                                            fontSize: "19px", position: "absolute",
                                        }} />
                                    </IconButton>
                                </Tooltip>
                            )
                            : '-'}
                        {!MostrarIcono && (
                            <span>
                                {cell.value !== null && cell.value !== undefined ? cell.value : "-"}
                            </span>
                        )}
                    </div>
                );
            },

        },
    ];

    return (
        <div>

            {/* {currentObservation} */}
            <Dialog
                open={open}
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: { borderRadius: "14px", width: "600px", height: "280px",  }
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {/* {"Use Google's location service?"} */}
                    Análisis Indicador {currentHeader}
                </DialogTitle>
                <DialogContent style={{ border: "1px solid #dddddd", marginRight: "1rem", marginLeft: "1rem", borderRadius: "8px" }}>

                    <Typography style={{ fontSize: "17px", marginLeft: "-12px", paddingTop: "10px" }}>
                        {currentObservation}
                    </Typography>

                </DialogContent>
                <DialogActions style={{marginBottom:"0.5rem"}}>
                    {/* <Button onClick={handleClose}>Cerrar</Button> */}
                    <Button variant="contained" style={{ background: "#e85242", color: "white" }}>Cerrar</Button>

                    {/* <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose} autoFocus>
                        Agree
                    </Button> */}
                </DialogActions>
            </Dialog>

            <MaterialReactTable
                data={duplicatedData}
                columns={columns}
                enableColumnActions={false}
                enableColumnFilters={false}
                enablePagination={false}
                enableFilters={false}
                enableSorting={false}
                enableColumnOrdering={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                // enableColumnFilterModes={false}
                localization={MRT_Localization_ES}
                enableStickyHeader
                enableStickyFooter
                muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                        backgroundColor: 'white', // Cambiar el color de fondo a rojo
                    },
                }}

                initialState={{
                    density: 'compact',
                    pagination: { pageIndex: 0, pageSize: 12 },
                }}
                renderTopToolbarCustomActions={() => (
                    <Box sx={{ display: "flex", width: "100%", }} >
                        <Typography variant='h6' sx={{
                            fontSize: "1rem", textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap', overflow: 'hidden', marginRight: "1rem"
                        }}>
                            Indicadores Generales
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "Center" }}>

                            <Typography variant="subtitle1" marginRight={1} sx={{
                                fontSize: "1rem", textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap', overflow: 'hidden',
                            }}>
                                <span style={{ fontWeight: "bold" }}>
                                    Tipo Medición:
                                </span>
                                <span style={{ fontWeight: "normal", marginLeft: 2 }}>
                                    {datos?.IndProTipCod === "ULT" ? "ULTIMO" : "" ||
                                        datos?.IndProTipCod === "ACU" ? "ACUMULADO" : "" ||
                                            datos?.IndProTipCod === "PRO" ? "PROMEDIO" : ""}
                                </span>
                            </Typography>
                            <Typography variant="subtitle1" marginRight={1} sx={{
                                fontSize: "1rem", textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap', overflow: 'hidden',
                            }}>
                                <span style={{ fontWeight: "bold" }}>
                                    Tipo Resultado:
                                </span>
                                <span style={{ fontWeight: "normal", marginLeft: 2 }}>
                                    {/* {datos?.IndProTipCod2} */}
                                    {datos?.IndProTipCod2 === "ULT" ? "ULTIMO" : "" ||
                                        datos?.IndProTipCod2 === "ACU" ? "ACUMULADO" : "" ||
                                            datos?.IndProTipCod2 === "PRO" ? "PROMEDIO" : ""}
                                </span>
                            </Typography>
                            <Typography variant="subtitle1" marginRight={1} sx={{
                                fontSize: "1rem", textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap', overflow: 'hidden',
                            }}>
                                <span style={{ fontWeight: "bold" }}>
                                    Criterio:
                                </span>
                                <span style={{ fontWeight: "normal", marginLeft: 2 }}>
                                    {datos?.IndProMetCri}
                                </span>
                            </Typography>
                        </Box>
                    </Box>
                    // </Box>
                )}//customize built-in buttons in the top-right of top toolbar

                muiTableBodyCellProps={{
                    sx: {
                        // border: '1px solid rgba(224, 224, 224, 1)',
                    },
                }}
                muiTableContainerProps={{ sx: { height: 478, } }}
                muiTableBodyRowProps={{ hover: false }}

            />
        </div>
    )
}

export default TablaResultadoIndicador