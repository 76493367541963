/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Tooltip, IconButton, Typography, Snackbar, Alert } from '@mui/material';
import newRequest from '../../../../utils/newRequest';
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import {
    MaterialReactTable, MRT_ShowHideColumnsButton,
    //  MRT_ToggleGlobalFilterButton,
    useMaterialReactTable,
} from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import 'react-toastify/dist/ReactToastify.css';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpcionesTabla from './OpcionesTabla';

const TablaMeses = ({
    selectedMesFinal,
    selectedMesInicial,
    selectAñoIncial,
    rows,
    selectedProduct,
    selectedCliente,
    selectedLineas,
    selectedVendedor,
    checked,
    Excluir,
    updateRowsData,
    selectedTercero,
    presupuestoTotal,
    noDataMessage,
    selectedZona,
    cambioTabla,
    onChangeCambioTabla
}) => {

    const [valorProyeccion, setvalorProyeccion] = useState(0);
    const converAñoAnt = selectAñoIncial - 1;
    const columnName = `Ventas${converAñoAnt}`;
    const ColumnañoInicial = `Ventas${selectAñoIncial}`;
    const ColumnaPresupuesto = `Presupuesto${selectAñoIncial}`;
    const ColumnaCosto = `Costo${selectAñoIncial}`;

    const getMonthName = (monthNumber) => [
        'Enero', 'Febreo', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciciembre'
    ][monthNumber - 1] || '';

    const handleCambioTabla = useCallback(() => {
        onChangeCambioTabla(!cambioTabla);
    }, [cambioTabla, onChangeCambioTabla]);


    const calculateTotal = (rows, column) => rows.reduce((total, row) => total + row[column], 0);

    const totalColumn = calculateTotal(rows, columnName);
    const totalColumnAñoInicial = calculateTotal(rows, ColumnañoInicial);
    const totalColumnaPresupuesto = calculateTotal(rows, ColumnaPresupuesto);
    const totalColumnaCosto = calculateTotal(rows, ColumnaCosto);

    const resultadoResven = ((totalColumnAñoInicial / totalColumn) * 100 - 100).toFixed(2);
    const formattedTotalResven = isNaN(resultadoResven) ? 0 : resultadoResven;

    // FORMULA VENTAS VS PRESUPUESTOS
    const resultadoVentvsPres = ((totalColumnAñoInicial / totalColumnaPresupuesto) * 100).toFixed(2);
    const formattedVentvsPres = isFinite(resultadoVentvsPres) ? resultadoVentvsPres : '0.00';

    const totalRentabilidad = (100 - (totalColumnaCosto / totalColumnAñoInicial * 100)).toFixed(2);
    const formatRentabilidad = isFinite(totalRentabilidad) ? totalRentabilidad : '0.00';


    // En tu componente
    const [open, setOpen] = useState(false);
    const [messageInfo, setMessageInfo] = useState({ message: '', severity: 'info' });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleOpenSnackbar = (message, severity) => {
        setMessageInfo({ message, severity });
        setOpen(true);
    };

    const totalProyeccion = rows.reduce((total, row) => {
        if (row[ColumnañoInicial] !== 0) {
            return (totalColumnAñoInicial / valorProyeccion * 12) / presupuestoTotal["Pr2"] * 100;
        }
        return total;
    }, 0);

    const formatNumber = (value) => new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);

    const handleButtonClick = async (row) => {
        try {
            // toast.info("Obteniendo datos...", { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, theme: "dark" });
            handleOpenSnackbar("Obteniendo datos...", "info");

            await new Promise((resolve) => setTimeout(resolve, 2000));
            await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasHistoricoVentas', {
                mes: row.mes,
                año: selectAñoIncial,
            });

            await updateRowsData();
            // toast.success("Datos obtenidos correctamente", {
            //     position: "bottom-center", autoClose: 2000, hideProgressBar: true,
            //     closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, theme: "dark"
            // });
            handleOpenSnackbar("Datos obtenidos correctamente", "success");


            await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasMes', {
                anioant: converAñoAnt,
                anio: selectAñoIncial,
                mes1: selectedMesInicial,
                mes2: selectedMesFinal,
                articulo: selectedProduct,
                cliente: selectedCliente,
                vendedor: selectedVendedor,
                linea: selectedLineas,
                tercero: selectedTercero,
                conProspecto: checked,
                excluir: Excluir,
                zona: selectedZona,
            });

        } catch (error) {
            // toast.error("Ocurrió un error al obtener los datos", { position: "bottom-center", autoClose: 2000, hideProgressBar: true, closeOnClick: true, pauseOnHover: false, draggable: true, progress: undefined, theme: "dark" });
            console.error(error);
            handleOpenSnackbar("Ocurrió un error al obtener los datos", "error");
        }
    };

    function getEstadoUnoIcono() {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>


                {resultadoResven < 0 ?
                    <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                        <ArrowDropDownIcon style={{ color: 'rgb(255, 89, 89)' }} />
                        Bajó
                    </Typography>
                    :
                    <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                        <ArrowDropUpIcon style={{ color: '#1F8A70' }} />
                        Subío
                    </Typography>
                }
            </div>
        )
    };

    function getVentasAntvdAct() {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {resultadoResven < 0 ?
                    <Typography variant="body2">
                        {resultadoResven < 0 ?
                            `${formattedTotalResven} % ` :
                            `${formattedTotalResven} % `}
                    </Typography>
                    :
                    <Typography variant="body2">
                        {resultadoResven < 0 ?
                            `${formattedTotalResven} % ` :
                            `${formattedTotalResven} % `}
                    </Typography>
                }
            </div>
        )
    };

    function getVentvsPresEstado() {
        const totalColumnAñoInicialNumber = Number(totalColumnAñoInicial);
        const totalColumnaPresupuestoNumber = Number(totalColumnaPresupuesto);
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {totalColumnAñoInicialNumber < totalColumnaPresupuestoNumber ? (
                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowDropDownIcon style={{ color: 'rgb(255, 89, 89)' }} />
                        Bajó
                    </Typography>
                ) : (
                    <Typography variant="body2" style={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowDropUpIcon style={{ color: '#1F8A70' }} />
                        Subió
                    </Typography>
                )}
            </Box>
        );
    };

    function getVentvsPres() {
        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {resultadoVentvsPres < 0 ?
                    <div>
                        <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                            {resultadoResven < 0 ?
                                `${formattedVentvsPres} % ` : `${formattedVentvsPres} % `}
                        </Typography>
                    </div>
                    :
                    <div>
                        <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}>
                            {resultadoResven < 0 ?
                                `${formattedVentvsPres} % ` :
                                `${formattedVentvsPres} % `}
                        </Typography>
                    </div>
                }
            </div>
        )
    };

    const getCommonMuiTableHeadCellProps = (backgroundColor) => ({
        align: 'left',
        sx: {
            fontWeight: 'normal',
            // fontSize: '12px !important',
            backgroundColor,
            color: 'white',
        },
    });

    const getCommonMuiTableBodyCellProps = (align = 'center') => ({
        align,
    });

    const CustomCell = ({ cell, cambioTabla, formatNumber }) => (
        <>
            {cambioTabla
                ? cell.getValue()?.toLocaleString?.('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
                : formatNumber(cell.getValue())}
        </>
    );

    const EstadoCell = ({ total }) => (
        <div style={{ width: '100%', textAlign: "center", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {total > 0 ? (
                    <>
                        <ArrowDropUpIcon style={{ color: '#1F8A70' }} />
                        Subió
                    </>
                ) : (
                    <>
                        <ArrowDropDownIcon style={{ color: 'rgb(255, 89, 89)' }} />
                        Bajó
                    </>
                )}
            </Box>
        </div>
    );

    const columns = [
        {
            accessorKey: 'A',
            header: 'A',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ cell }) => (
                <div>
                    <Tooltip title="Recargar">
                        <IconButton onClick={() => handleButtonClick(cell.row.original)}>
                            <RefreshIcon sx={{ fontSize: '18px', position: 'absolute' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps(),
            size: 30,
        },
        {
            accessorKey: 'mes',
            header: 'mes',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
            enableColumnActions: false,
            enableSorting: false,
            footer: 'Total',
            size: 30,
            Cell: ({ cell }) => getMonthName(cell.row.original.mes),
        },
        {
            accessorKey: `Ventas${converAñoAnt}`,
            header: `Ventas ${converAñoAnt}`,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
            enableColumnActions: false,
            Cell: ({ cell }) => <CustomCell cell={cell} cambioTabla={cambioTabla} formatNumber={formatNumber} />,
            footer: (
                <div style={{ width: '100%', display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    <Typography variant="body2">{formatNumber(totalColumn)}</Typography>
                </div>
            ),
            size: 30,
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
        },
        {
            accessorKey: `Ventas${selectAñoIncial}`,
            header: `Ventas${selectAñoIncial}`,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
            enableColumnActions: false,
            Cell: ({ cell }) => <CustomCell cell={cell} cambioTabla={cambioTabla} formatNumber={formatNumber} />,
            footer: (
                <div style={{ width: '100%', display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    <Typography variant="body2">{formatNumber(totalColumnAñoInicial)}</Typography>
                </div>
            ),
            size: 30,
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
        },
        {
            accessorKey: 'Estado',
            header: 'Estado',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#27AE60'),
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }) => {
                const dato = row.original;
                const prop1 = dato[Object.keys(dato)[3]];
                const prop2 = dato[Object.keys(dato)[2]];
                const total = (prop1 !== 0 && prop2 !== 0) ? (prop1 / prop2 * 100) - 100 : 0;
                return <EstadoCell total={total} />;
            },
            size: 30,
            footer: getEstadoUnoIcono(),
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps(),
        },
        {
            accessorKey: `ResVentas${converAñoAnt}vs${selectAñoIncial}Valor`,
            header: `Ven ${converAñoAnt}vs${selectAñoIncial}`,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#27AE60'),
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }) => {
                const dato = row.original;
                const prop1 = dato[Object.keys(dato)[3]];
                const prop2 = dato[Object.keys(dato)[2]];
                const total = (prop1 !== 0 && prop2 !== 0) ? (prop1 / prop2 * 100) - 100 : 0;
                const formattedTotal = isNaN(total) ? 0 : total.toFixed(2);
                return `${formattedTotal}%`;
            },
            size: 30,
            footer: getVentasAntvdAct(),
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps(),
        },
        {
            accessorKey: `Presupuesto${selectAñoIncial}`,
            header: `Presupuesto ${selectAñoIncial}`,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
            enableColumnActions: false,
            Cell: ({ cell }) => <CustomCell cell={cell} cambioTabla={cambioTabla} formatNumber={formatNumber} />,
            footer: (
                <div style={{ width: '100%', display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                    <Typography variant="body2">{formatNumber(totalColumnaPresupuesto)}</Typography>
                </div>
            ),
            size: 30,
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
        },
        {
            accessorKey: `estadoPresupuesto${selectAñoIncial}`,
            header: 'Estado',
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#E74C3C'),
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }) => {
                const dato = row.original;
                const prop1 = dato[Object.keys(dato)[3]];
                const prop2 = dato[Object.keys(dato)[5]];
                return (
                    <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                        <Box sx={{ display: 'flex', alignItems: 'left' }}>
                            {prop1 > prop2 ? (
                                <>
                                    <ArrowDropUpIcon style={{ color: '#1F8A70' }} />
                                    Subió
                                </>
                            ) : (
                                <>
                                    <ArrowDropDownIcon style={{ color: 'rgb(255, 89, 89)' }} />
                                    Bajó
                                </>
                            )}
                        </Box>
                    </div>
                );
            },
            size: 30,
            footer: getVentvsPresEstado(),
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps(),
        },
        {
            accessorKey: `ResPresupuesto${selectAñoIncial}vs${selectAñoIncial}`,
            header: `VentVSPres ${selectAñoIncial}`,
            muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#E74C3C'),
            enableSorting: false,
            enableColumnActions: false,
            Cell: ({ row }) => {
                const dato = row.original;
                const prop1 = dato[Object.keys(dato)[3]];
                const prop2 = dato[Object.keys(dato)[5]];
                const total = (prop1 !== 0 && prop2 !== 0) ? (prop1 / prop2 * 100) : 0;
                const formattedTotal = isNaN(total) ? 0 : total.toFixed(2);
                return `${formattedTotal}%`;
            },
            size: 30,
            footer: getVentvsPres(),
            muiTableBodyCellProps: getCommonMuiTableBodyCellProps(),
        },
        ...(
            cambioTabla
                ? [
                    {
                        accessorKey: `Costo${selectAñoIncial}`,
                        header: `Costo ${selectAñoIncial}`,
                        muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#1C64F2'),
                        enableColumnActions: false,
                        Cell: ({ cell }) => (
                            <>
                                {cell.getValue()?.toLocaleString?.('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}
                            </>
                        ),
                        footer: (
                            <Typography variant="body2" style={{ display: 'flex', alignItems: 'left', }}>
                                ${formatNumber(totalColumnaCosto)}
                            </Typography>
                        ),
                        size: 30,
                        muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
                    },
                    {
                        accessorKey: 'Rentabilidad',
                        header: 'Rentabilidad',
                        muiTableHeadCellProps: getCommonMuiTableHeadCellProps('#FF5722'),
                        enableSorting: false,
                        enableColumnActions: false,
                        Cell: ({ row }) => {
                            // FORMULA: COSTO / VENTAS DEL AÑO ACTUAL
                            const dato = row.original;
                            const prop1 = dato[Object.keys(dato)[4]];
                            const prop2 = dato[Object.keys(dato)[3]];
                            const total = (prop1 !== 0 && prop2 !== 0) ? 100 - ((prop1 / prop2) * 100) : 0;
                            return `${total.toFixed(2)}%`;
                        },
                        size: 30,
                        footer: (
                            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center', marginLeft: '12px' }}>
                                {formatRentabilidad} %
                            </Typography>
                        ),
                        muiTableBodyCellProps: getCommonMuiTableBodyCellProps(),
                    },
                    {
                        accessorKey: `Proyeccion`,
                        header: `Proyeccion`,
                        muiTableHeadCellProps: {
                            align: 'center',
                            sx: {
                                fontWeight: 'normal',
                                fontSize: '14px !important',
                                backgroundColor: '#27AE60',
                                color: "white"
                            }
                        },
                        enableSorting: false, // disable sorting for this column
                        enableColumnActions: false,
                        Cell: ({ row, cell }) => {
                            const calcularRentabilidad = () => {
                                const dato = row.original;
                                const propiedades = Object.keys(dato); //Accede a las llaves del json
                                const prop2 = propiedades[3]; // Ejemplo: "Ventas2023"
                                let suma = 0;
                                for (let i = 0; i <= row.original.id - 1; i++) {
                                    suma = suma + rows[i][prop2];
                                }
                                const total = suma / row.original.id * 12;
                                setvalorProyeccion((selectedMesFinal - selectedMesInicial + 1))
                                return formatNumber(total)
                            };
                            const formattedValue = cell.getValue()?.toLocaleString?.('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            });
                            return (
                                <span style={{ display: "flex", alignItems: "left", justifyContent: "left" }}>
                                    $
                                    {formattedValue}
                                    {calcularRentabilidad()}
                                </span>
                            );
                        },
                        size: 30,
                        footer:
                            <Typography variant="body2" style={{ display: "flex", alignItems: "left", }}>
                                {totalProyeccion.toFixed(2)}%
                            </Typography>,
                        muiTableBodyCellProps: getCommonMuiTableBodyCellProps('left'),
                    }
                ]
                : []
        )
    ]

    // EXPORTA EL EXCEL DE ACUERDO A LOS DATOS DE LA TABLA
    const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = 'presupuesto_por_mes';

        const formattedData = rows.map((dato) => ({
            [`Mes`]: (() => {
                const monthName = getMonthName(dato.mes);
                return monthName;
            })(),
            [`Ventas ${converAñoAnt}`]: dato[columnName],
            [`Ventas ${selectAñoIncial}`]: dato[ColumnañoInicial],
            [`Estado de las Ventas`]: (() => {
                const prop1 = dato[ColumnañoInicial]; // Ventas 2023
                const prop2 = dato[columnName]; // Ventas 2022
                let total;
                if (prop2 !== 0 && prop1 !== 0) {
                    total = (prop1 / prop2 * 100) - 100;
                } else {
                    total = 0;
                }
                return total < 0 ? 'Bajó' : 'Subió';
            })(),
            [`${columnName}vs${selectAñoIncial}`]: (() => {
                const prop1 = dato[ColumnañoInicial]; // Ventas 2023
                const prop2 = dato[columnName]; // Ventas 2022
                if (prop2 !== 0 && prop1 !== 0) {
                    const total = (prop1 / prop2 * 100) - 100;
                    return `${total.toFixed(2)}%`;
                } else {
                    return '0%';
                }
            })(),
            Presupuesto: dato[ColumnaPresupuesto],
            Estado: (() => {
                const prop1 = dato[ColumnañoInicial]; // Ventas 2023
                const prop2 = dato[ColumnaPresupuesto]; // Presupuesto 2023
                return prop1 > prop2 ? 'Subió' : 'Bajó';
            })(),
            ventVsPres: (() => {
                const prop1 = dato[ColumnañoInicial]; // Ventas 2023
                const prop2 = dato[ColumnaPresupuesto]; // Presupuesto 2023
                if (prop2 !== 0) {
                    const total = (prop1 / prop2) * 100;
                    return `${total.toFixed(2)}%`;
                } else {
                    return '0%';
                }
            })(),
            costo: dato[ColumnaCosto],
            Rentabilidad: (() => {
                const prop1 = dato[ColumnaCosto]; //Costo 2023 - Utiliza las propiedades dinámicas
                const prop2 = dato[ColumnañoInicial]; //Ventas 2023 - Utiliza las propiedades dinámicas
                if (prop2 !== 0 && prop1 !== 0) {
                    const total = 100 - (prop1 / prop2) * 100;
                    return `${total.toFixed(2)}%`;
                } else {
                    return '0%';
                }
            })(),
            Proyeccion: (() => {
                const propiedades = Object.keys(dato); // Accede a las llaves del json
                const prop2 = propiedades[3]; // Ejemplo: "Ventas2023"
                let suma = 0;
                for (let i = 0; i <= dato.id - 1; i++) {
                    suma = suma + rows[i][prop2];
                }
                const total = (suma / dato.id) * 12;
                return total;
            })()
        }));

        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        saveAs(data, fileName + fileExtension);
    };

    useEffect(() => {
    }, [selectedMesFinal, selectedMesInicial, selectAñoIncial, rows,
        selectedProduct, selectedCliente, selectedLineas, selectedVendedor, checked, Excluir,
        updateRowsData, selectedTercero, presupuestoTotal, noDataMessage, cambioTabla, selectedZona])

    const table = useMaterialReactTable({
        columns,
        data: rows, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        positionToolbarAlertBanner: 'bottom', //show selected rows count on bottom toolbar
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: { density: 'compact' },
        renderTopToolbarCustomActions: ({ table }) => (
            <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography variant='h6' fontSize={16} fontWeight="bold">Ventas por mes</Typography>
                    <OpcionesTabla onChangeCambioTabla={handleCambioTabla} cambioTablaFromParent={cambioTabla} />
                </div>
            </Box>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <Box>
                {/* <MRT_ToggleGlobalFilterButton table={table} /> */}
                <IconButton onClick={exportToExcel}>
                    <DownloadIcon />
                </IconButton>
                <MRT_ShowHideColumnsButton table={table} />
            </Box>
        ),
        localization: MRT_Localization_ES,
        muiTableContainerProps: { sx: { height: "18.5rem", width: "100%" } }
    });



    return (
        <Box sx={{ width: '100%', overflowX: 'auto', padding: '5px' }}>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={messageInfo.severity}
                    sx={{
                        width: '100%', background: "#222831", color: "white"
                    }}
                >
                    {messageInfo.message}
                </Alert>
            </Snackbar>

            <MaterialReactTable
                table={table} //only pass in table instead of all table options
                localization={MRT_Localization_ES}
            />
        </Box>
    )
}
export default TablaMeses