import React, { useEffect, useState, Suspense, lazy } from 'react'

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import newRequest from '../../../utils/newRequest';
import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup,
    IconButton,
    Fab,
    Divider,
    // Skeleton
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const VentasLinea = lazy(() => import('./VentasLinea/VentasLinea'));
const VentasProductos = lazy(() => import('./VentasProducto/VentasProductos'));
const VentasVendedor = lazy(() => import('./VentasVendedor/VentasVendedor'));
const VentasClientes = lazy(() => import('./VentasClientes/VentasClientes'));
const VentasPorZona = lazy(() => import('./VentasPorZona/VentasPorZona'));
const VentasTercero = lazy(() => import('./VentasTercero/VentasTercero'));

const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-11)

export const VentasComercial = React.memo(() => {

    const navigate = useNavigate();


    const [añoSelect, setAñoSelect] = useState([]);
    const [selectAñoIncial, setAñoInical] = useState(currentYear);
    const [selectProduct, setSelectProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectCliente, setSelectCliente] = useState([]);
    const [selectedCliente, setSelectedCliente] = useState(null);
    const [selectLinea, setSelectLinea] = useState([]);
    const [selectedLineas, setSelectedLineas] = useState(null);
    const [selectVendedor, setSelectVendedor] = useState([]);
    const [selectedVendedor, setSelectedVendedor] = useState(null);
    const [selectTercero, setSelectTercero] = useState([]);
    const [selectedTercero, setSelectedTercero] = useState(null);
    const [selectZona, setSelectZona] = useState([]);
    const [selectedZona, setSelectedZona] = useState(null);

    const añoInicial = (year) => {
        // estadoFechasetVisual(true);
        if (year <= currentYear) {
            setAñoInical(year);
        } else {
            toast.warn("El año inicial no puede ser mayor al año final", {
                position: "bottom-center",
                autoClose: 5000,
                theme: "dark",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const fetchData = async () => {
        try {
            const [resAno, resProductos, resCliente, resLinea, resVendedor, resTercero, resZona] = await Promise.all([
                newRequest.get('/api/GerenciaVisual/profundidadSelectAno'),
                newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasArticulosSelect'),
                newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasClientesSelect'),
                newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialOpcionesLinea'),
                newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasVendedorSelect'),
                newRequest.get('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasTerceroSelect'),
                newRequest.get('/api/GerenciaVisual/profundidadSelectZona'),
            ]);
            setAñoSelect(resAno.data.length ? resAno.data.reverse() : []);
            setSelectProduct(resProductos.data.length ? resProductos.data : []);
            setSelectCliente(resCliente.data.length ? resCliente.data : []);
            setSelectLinea(resLinea.data.length ? resLinea.data : []);
            setSelectVendedor(resVendedor.data.length ? resVendedor.data : []);
            setSelectTercero(resTercero.data.length ? resTercero.data : []);
            setSelectZona(resZona.data.length ? resZona.data : []);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleBack = () => {
        navigate("/Farmanet/GestionComercial")
    }

    return (
        <Box>
            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Fab onClick={() => navigate(-1)} style={{ background: "white", width: "45px", height: "45px" }}>
                            <ChevronLeftIcon />
                        </Fab>

                        <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                            {/* <Typography color="text.primary">Gestión Comercial</Typography> */}
                            <Typography color="text.primary">Volver</Typography>
                            {/* <Typography color="text.primary">Ventas</Typography> */}
                        </Breadcrumbs>
                    </Box>

                    <Typography variant="h6" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                        marginBottom: "25px", marginTop: "10px"
                    }}>
                        <AttachMoneyRoundedIcon sx={{ paddingLeft: "5px", marginRight: "10px", color: "#FE0000", fontSize: "32px" }} />
                        Ventas
                    </Typography>
                </Box>
            </Grid>

            <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                style={{
                    borderRadius: "13px",
                    position: "sticky",
                    top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                    zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                    boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                    transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                }}
            >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1 }}>
                    <Grid container spacing={1} alignItems="center" sx={{ margin: 1, marginTop: "8px" }}>
                        <Grid item xs={12} sm={2} md={2} lg={4} xl={6} sx={{ display: "flex" }}>
                            <Stack direction="column" alignItems="center" spacing={0.5}>
                                {añoSelect.map((año) => (
                                    <div key={año.PerMesano}>
                                        <ToggleButtonGroup
                                            color="primary" value={selectAñoIncial}
                                            exclusive onClick={() => añoInicial(año.PerMesano)}
                                            sx={{ height: "30px" }}
                                        >
                                            <ToggleButton key={año.PerMesano}
                                                style={{
                                                    backgroundColor: selectAñoIncial === año.PerMesano ? '#1976d2' : 'initial',
                                                    color: selectAñoIncial === año.PerMesano ? 'white' : 'initial',
                                                }}
                                                value={año.PerMesano}>
                                                {año.PerMesano}
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                ))}
                            </Stack>
                        </Grid>

                        {/* Aqui va los select */}
                        <Grid item xs={7} sm={8} md={8} lg={7} xl={6} >
                            <div style={{ display: "flex" }}>
                                {/* TERCERO */}
                                <IconButton aria-label="Tercero" sx={{
                                    background: "#475053", marginRight: "5px", color: "white",
                                    borderRadius: "100%", width: "20px", height: "20px",
                                    '&:hover': {
                                        background: 'black',
                                        color: 'white',
                                    },
                                }}
                                    onClick={() => scrollToElement("ventasTercero")} // Llama a la función para desplazarte
                                >
                                    <VisibilityIcon sx={{ fontSize: "18px" }} />
                                </IconButton>
                                <Grid container item xs={12} sm={6} sx={{ display: "flex", alignItems: "center" }}>
                                    <Autocomplete
                                        id="cliente"
                                        name="Articulo"
                                        autoComplete={false}
                                        options={selectTercero}
                                        getOptionLabel={(option) => option.tercero}
                                        fullWidth
                                        sx={{ marginBottom: "5px", marginRight: "5px" }}
                                        onChange={(event, newValue) => {
                                            setSelectedTercero(newValue?.nitSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                            // estadoFechasetVisual(true)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label="Tercero"
                                                size="small"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: { fontSize: 14 },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>

                                {/* CLIENTES */}
                                <IconButton aria-label="Clientes" sx={{
                                    background: "#475053", marginRight: "5px", color: "white",
                                    borderRadius: "100%", width: "20px", height: "20px",
                                    '&:hover': {
                                        background: 'black',
                                        color: 'white',
                                    },
                                }}
                                    onClick={() => scrollToElement("ventasClientes")} // Llama a la función para desplazarte
                                >
                                    <VisibilityIcon sx={{ fontSize: "18px" }} />
                                </IconButton>
                                <Grid container item xs={12} sm={6}  >
                                    <Autocomplete
                                        id="cliente" name="Articulo" size="small" autoComplete={false}
                                        options={selectCliente} getOptionLabel={(option) => option.cliente}
                                        fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                        onChange={(event, newValue) => {
                                            setSelectedCliente(newValue?.NitSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Clientes" size="small" variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: { fontSize: 14, height: 35, fontWeight: 'normal' }, // Cambia el tamaño de la fuente aquí
                                                }}
                                            />
                                        )}
                                    />
                                    {/* <div>Valor seleccionado cliente: {selectedCliente}</div> */}
                                </Grid>

                                <IconButton aria-label="delete" sx={{
                                    background: "#475053", marginRight: "5px", color: "white",
                                    borderRadius: "100%", width: "20px", height: "20px",
                                    '&:hover': {
                                        background: 'black',
                                        color: 'white',
                                    },
                                }}
                                    onClick={() => scrollToElement("ventasZona")} // Llama a la función para desplazarte
                                >
                                    <VisibilityIcon sx={{ fontSize: "18px" }} />
                                </IconButton>
                                {/* Zona */}
                                <Grid container item xs={12} sm={6}>
                                    <Autocomplete
                                        id="zona" name="zona" size="small"
                                        autoComplete={false}
                                        options={selectZona}
                                        getOptionLabel={(option) => option.ZonNom}
                                        fullWidth sx={{ marginBottom: "5px", }}
                                        onChange={(event, newValue) => {
                                            setSelectedZona(newValue?.ZonCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Zona" size="small" variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: { fontSize: 14 },
                                                }}
                                            />
                                        )}
                                    />
                                    {/* <div>Valor seleccionado: {selectedZona}</div> */}
                                </Grid>
                            </div>

                            <div style={{ display: "flex", alignItems: "center" }}>

                                {/* Productos */}
                                <IconButton aria-label="delete" sx={{
                                    background: "#475053", marginRight: "5px", color: "white",
                                    borderRadius: "100%", width: "20px", height: "20px",
                                    '&:hover': {
                                        background: 'black',
                                        color: 'white',
                                    },
                                }}
                                    onClick={() => scrollToElement("ventasProducto")} // Llama a la función para desplazarte
                                >
                                    <VisibilityIcon sx={{ fontSize: "18px" }} />
                                </IconButton>
                                <Grid container item xs={12} sm={6}>
                                    <Autocomplete
                                        id="cliente" name="Articulo" size="small" autoComplete={false}
                                        options={selectProduct} getOptionLabel={(option) => option.producto}
                                        fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                        onChange={(event, newValue) => {
                                            setSelectedProduct(newValue?.ArtSec || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                            // estadoFechasetVisual(true)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Producto" size="small" variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: { fontSize: 14 },
                                                }}
                                            />
                                        )}
                                    />
                                    {/* <div>Valor seleccionado producto: {selectedProduct}</div> */}
                                </Grid>

                                {/* LINEA */}
                                <IconButton aria-label="delete" sx={{
                                    background: "#475053", marginRight: "5px", color: "white",
                                    borderRadius: "100%", width: "20px", height: "20px",
                                    '&:hover': {
                                        background: 'black',
                                        color: 'white',
                                    },
                                }}
                                    onClick={() => scrollToElement("uno")} // Llama a la función para desplazarte
                                >
                                    <VisibilityIcon sx={{ fontSize: "18px" }} />
                                </IconButton>
                                <Grid container item xs={12} sm={6}>
                                    <Autocomplete
                                        id="cliente" name="Articulo" size="small" autoComplete={false}
                                        options={selectLinea} getOptionLabel={(option) => option.InvGruNom}
                                        fullWidth sx={{ marginBottom: "5px", marginRight: "5px" }}
                                        onChange={(event, newValue) => {
                                            setSelectedLineas(newValue?.invGruCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                            // estadoFechasetVisual(true)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Linea" size="small" variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: { fontSize: 14 },
                                                }}
                                            />
                                        )}
                                    />
                                    {/* <div>Valor seleccionado linea: {selectedLineas}</div> */}
                                </Grid>

                                {/* Vendedor */}
                                <IconButton aria-label="delete" sx={{
                                    background: "#475053", marginRight: "5px", color: "white",
                                    borderRadius: "100%", width: "20px", height: "20px",
                                    '&:hover': {
                                        background: 'black',
                                        color: 'white',
                                    },
                                }}
                                    onClick={() => scrollToElement("ventasVendedor")} // Llama a la función para desplazarte

                                >
                                    <VisibilityIcon sx={{ fontSize: "18px" }} />
                                </IconButton>
                                <Grid container item xs={12} sm={6}>
                                    <Autocomplete
                                        id="cliente" name="Articulo" size="small" autoComplete={false}
                                        options={selectVendedor}
                                        getOptionLabel={(option) => option.VenNom}
                                        fullWidth sx={{ marginTop: "-5px" }}
                                        onChange={(event, newValue) => {
                                            setSelectedVendedor(newValue?.VenCod || ''); // Actualiza la variable de estado selectedValue con el valor seleccionado
                                            // estadoFechasetVisual(true)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Vendedor" size="small" variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: { fontSize: 14 },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>

            <Suspense fallback={<div>Cargando....</div>} >

                <Box sx={{ marginBottom: 3 }}>
                    <Paper variant="outlined" sx={{
                        p: { xs: 1, md: 1 }, borderRadius: "13px",
                        marginTop: 3,
                    }}>
                        <Suspense>
                            <VentasLinea selectAñoIncial={selectAñoIncial} selectedTercero={selectedTercero}
                                selectedCliente={selectedCliente} selectedZona={selectedZona}
                                selectedProduct={selectedProduct} selectedLineas={selectedLineas} selectedVendedor={selectedVendedor} />
                        </Suspense>

                        <Suspense >
                            <VentasPorZona selectAñoIncial={selectAñoIncial} selectedTercero={selectedTercero}
                                selectedCliente={selectedCliente} selectedZona={selectedZona}
                                selectedProduct={selectedProduct} selectedLineas={selectedLineas} selectedVendedor={selectedVendedor} />
                        </Suspense>

                        <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                        <Suspense>
                            <VentasProductos selectAñoIncial={selectAñoIncial} selectedTercero={selectedTercero}
                                selectedCliente={selectedCliente} selectedZona={selectedZona}
                                selectedProduct={selectedProduct} selectedLineas={selectedLineas} selectedVendedor={selectedVendedor} />
                        </Suspense>

                        <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                        <Suspense>
                            <VentasVendedor selectAñoIncial={selectAñoIncial} selectedTercero={selectedTercero}
                                selectedCliente={selectedCliente} selectedZona={selectedZona}
                                selectedProduct={selectedProduct} selectedLineas={selectedLineas} selectedVendedor={selectedVendedor} />
                        </Suspense>

                        <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                        <Suspense>
                            <VentasClientes selectAñoIncial={selectAñoIncial} selectedTercero={selectedTercero}
                                selectedCliente={selectedCliente} selectedZona={selectedZona}
                                selectedProduct={selectedProduct} selectedLineas={selectedLineas} selectedVendedor={selectedVendedor} />
                        </Suspense>

                        <Divider sx={{ marginLeft: 10, marginRight: 10, marginTop: 1, marginBottom: 1 }} />

                        <Suspense>
                            <VentasTercero selectAñoIncial={selectAñoIncial} selectedTercero={selectedTercero}
                                selectedCliente={selectedCliente} selectedZona={selectedZona}
                                selectedProduct={selectedProduct} selectedLineas={selectedLineas} selectedVendedor={selectedVendedor} />
                        </Suspense>
                    </Paper>
                </Box>
                {/* <Paper id="ventasProducto" // Aqui se llama el id para mover el Cliente
                    variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3 }}>
                
                </Paper>

                <Paper
                    id="ventasVendedor" // Aqui se llama el id para mover el Cliente
                    variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3 }}>
                   
                </Paper>

                <Paper
                    id="ventasClientes" // Aqui se llama el id para mover el Cliente
                    variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3 }}>
                  
                </Paper>

                <Paper
                    id="ventasClientes" // Aqui se llama el id para mover el Cliente
                    variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3 }}>
                   
                </Paper>

                <Paper
                    id="ventasTercero" // Aqui se llama el id para mover el Cliente
                    variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3 }}>
                   
                </Paper>

                <Paper
                    variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: 3 }}>
                    
                </Paper> */}

            </Suspense>
        </Box>
    )
})
