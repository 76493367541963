/* eslint-disable react/jsx-pascal-case */
import React, { useMemo } from 'react'
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import OpcionesTabla from './OpcionesTabla';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP', // Código de moneda para el peso colombiano
    }).format(value);
};

const VentasTablaDispostivosM = ({ dataZona, selectedOption, handleOpen, handleOptionChange }) => {

    console.log("selectedOption VentasTablaDispostivosM", selectedOption)

    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    // Función para calcular la suma de cada fila
    const calculateRowTotal = (row) => {
        return Object.keys(row).reduce((total, key) => {
            if (key !== 'Codigo' && key !== 'Producto') {
                return total + (parseFloat(row[key]) || 0);
            }
            return total;
        }, 0);
    };

    // Agregar el total a cada fila
    const dataWithTotals = useMemo(() => dataZona.map(row => ({
        ...row,
        Total: calculateRowTotal(row),
    })), [dataZona]);

    const sumaTotal = useMemo(() => {
        return dataZona.reduce((total, row) => {
            return total + calculateRowTotal(row);
        }, 0);
    }, [dataZona]);

    // Precalcular los totales por columna
    const totalValues = {};
    const totalQuantities = {};
    meses.forEach((mes, i) => {
        const valueField = `${i + 1}V`;
        const quantityField = `${i + 1}C`;
        totalValues[valueField] = dataZona.reduce((total, row) => total + (parseFloat(row[valueField]) || 0), 0);
        totalQuantities[quantityField] = dataZona.reduce((total, row) => total + (parseFloat(row[quantityField]) || 0), 0);
    });

    const columns = [
        {
            id: 'Codigo',
            accessorKey: 'Codigo',
            header: 'Codigo',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            size: 100,
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell }) => {
                return (
                    <Typography variant='body2'>
                        {cell.row.original.Codigo}
                    </Typography>
                )
            },
            footer: 'Total',
        },
        {
            id: 'Producto',
            accessorKey: 'Producto',
            header: 'Producto',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => {
                return (
                    <div style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap", overflow: "hidden", width: "130px"
                    }}>
                        {cell.row.original.Producto}
                    </div>
                )
            },
        },
    ];

    for (let i = 0; i < meses.length; i++) {
        const valueField = `${i + 1}V`;
        const quantityField = `${i + 1}C`;

        columns.push({
            accessorKey: valueField,
            header: `${selectedOption === "b" ? meses[i] : `$${meses[i]}`}`, // Header se ajusta basado en selectedOption
            Cell: ({ cell }) => (
                <div>
                    {selectedOption === "b" ?
                        cell.getValue()?.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        }) :
                        cell.getValue()?.toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                        })
                    }
                </div>
            ),
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#1C64F2',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            size: 10,
            footer: selectedOption === "b" ?
                totalValues[valueField].toLocaleString() :
                formatCurrency(totalValues[valueField]),  // Ajuste en el footer basado en selectedOption
        });

        if (selectedOption !== "b") {
            columns.push({
                accessorKey: quantityField,
                header: `# ${meses[i]}`,
                muiTableHeadCellProps: {
                    align: 'center',
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: '#27AE60',
                        color: "white"
                    }
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                size: 10,
                footer: totalQuantities[quantityField].toLocaleString(),  // Muestra el total como un número formateado
            });
        }
    }

    const table = useMaterialReactTable({
        columns,
        data: dataWithTotals,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableColumnActions: false,
        initialState: {
            columnPinning: { left: ['Codigo', 'Producto'] },
            density: 'compact'
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <div style={{ gap: '1rem', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant='lg' fontWeight="bold"> Ventas por dispositivos medicos</Typography>
                <OpcionesTabla onOptionChange={handleOptionChange} //aqui pasa el hijo
                    selectedOptionPadre={selectedOption} />
            </div>
        ),
        renderBottomToolbarCustomActions: ({ table }) => (
            <Box>
                <Typography variant="body2" sx={{ fontWeight: "bolder", marginTop: 1 }} gutterBottom>
                    Total de los meses :
                    <span style={{ fontSize: "17px", marginLeft: "10px" }}>
                        {selectedOption === "a" || selectedOption === "b" ?
                            sumaTotal.toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }) :
                            formatCurrency(sumaTotal)}
                    </span>
                </Typography>
            </Box>
        ),
        localization: {
            toggleDensity: "Alternar densidad",
            rowsPerPage: "Filas por página",
            toggleFullScreen: "Alternar pantalla completa",
            search: "Buscar",
            columnActions: "mostrar/ocultar columnas",
            showHideSearch: "Filtro de busqueda",
            showHideColumns: "mostrar / ocultar columnas"
        },

        muiTableContainerProps: { sx: { height: "24rem", width: "100%" } }
    });


    return (
        <MaterialReactTable
            table={table}
        />
    )
}

export default VentasTablaDispostivosM