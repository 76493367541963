import React from 'react'
import { Box, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { CardActionArea, Grid } from '@mui/material';

import SavingsIcon from '@mui/icons-material/Savings';
import PaymentsIcon from '@mui/icons-material/Payments';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
export const MenuFinanciera = () => {

    const gridItems = [
        {
            icon: <PaymentsIcon style={{ fontSize: "45px" }} />, title: "PyG (Perdida y Ganancia)",
            link: "/Farmanet/Financiera/Pyg"
        },
        // Add more grid items as needed
    ];

    const CustomGridItem = ({ icon, title, link }) => {
        return (
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <Paper variant="elevation" elevation={2} style={{ borderRadius: "12px" }}>
                    <Card sx={{ display: 'flex', height: "130px", borderRadius: "12px" }}>
                        <CardActionArea component="a" href={link} sx={{ display: "flex" }}>
                            <Grid item xs={3} sm={3} md={3}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    width="100%"
                                >
                                    {icon}
                                </Box>
                            </Grid>
                            <Divider orientation='vertical' style={{ height: "80px" }} />
                            <Grid item xs={9} sm={9} md={9}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography sx={{ fontSize: 14, color: "#FE0000", }} color="text.secondary" gutterBottom>
                                            Financiera
                                        </Typography>
                                        <Typography variant="h5" component="div" style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                        }}>
                                            {title}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Paper>
            </Grid>
        );
    };

    return (
        <Box>
            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Typography variant="h5" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                        marginBottom: "25px", marginTop: "1.5rem"
                    }}>
                        <AttachMoneyIcon style={{ marginRight: "5px", color: "#FE0000" }} />
                        Financiera
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={2}>
                {gridItems.map((item, index) => (
                    <CustomGridItem key={index} {...item} />
                ))}
            </Grid>
        </Box >
    )
}
