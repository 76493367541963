import React, { useContext, useEffect, useState } from 'react'

import { useParams, useNavigate } from 'react-router-dom';
import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, Button, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup, Checkbox, FormControlLabel, InputAdornment, FormControl, InputLabel, Fab,
    Select, MenuItem, LinearProgress
    // Skeleton
} from '@mui/material';
import { Link, } from 'react-router-dom';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import newRequest from '../../../../../../utils/newRequest';
import TablaProcesoEmpresaInd from '../TablaProcesoEmpresaInd/TablaProcesoEmpresaInd';
import SelectorPanel from './SelectorPanel';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
const currentYear = new Date().getFullYear(); // Obtiene el año actual
const currentMonth = new Date().getMonth() + 1; // Obtén el mes actual (0-12)

const LineaTiempoIndicador = () => {

    const { indProEmpSec, tipo, fecha, mesData } = useParams();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false); // Estado de carga
    const [añoSelect, setAñoSelect] = useState([]);
    const [selectAñoIncial, setAñoInical] = useState(parseInt(fecha));
    const [indicadoresNombre, setIndicadoresNombre] = useState([])
    const [selectedIndicador, setSelectedIndicador] = useState('');
    const [nombreInd, setnombreInd] = useState("")
    const [dataTabla, setDataTabla] = useState([])
    const [alignment, setAlignment] = useState(tipo);

    const meses = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
    ];

    const ajustarMesData = mesDataParam => {
        // Convertir mesData a un número entero.
        const mesIndex = parseInt(mesDataParam, 10) - 1; // Restar 1 para coincidir con los índices del array.

        // Verificar si el índice está dentro del rango válido (0-11).
        if (mesIndex >= 0 && mesIndex < 12) {
            return mesIndex;
        }

        // Si mesData está fuera de rango, establece un valor predeterminado o retorna un valor que indica "sin selección".
        return ''; // O podrías usar un valor por defecto como 0 (para Enero) o cualquier otro índice válido.
    };

    // const [mes, setMes] = useState(mesData);
    // const [mes, setMes] = useState(ajustarMesData(mesData));
    // Usar el valor ajustado de mesData para establecer el estado inicial de 'mes'.
    const [mes, setMes] = useState(() => ajustarMesData(mesData));


    const handleChangeNombre = (event) => {
        setSelectedIndicador(event.target.value);

        // Buscar el objeto con el ProEmpSec correspondiente a selectedValue
        const selectedObj = indicadoresNombre.find(item => item.ProEmpSec === parseInt(event.target.value));

        // Establecer el valor del nombre
        setnombreInd(selectedObj ? selectedObj.ProEmpNom : '');

        setnombreInd(selectedObj?.ProEmpNom);
        // Actualizar la URL con los nuevos parámetros
        navigate(`/Farmanet/GerenciaVisual/Indicadores/LineaTiempoIndicador/${event.target.value}/${alignment}/${selectAñoIncial}/${mes}`);
    };

    const handleChangeTipo = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }

        // Actualizar la URL con los nuevos parámetros
        navigate(`/Farmanet/GerenciaVisual/Indicadores/LineaTiempoIndicador/${selectedIndicador}/${newAlignment}/${selectAñoIncial}/${mes}`);
    };


    const añoInicial = (year) => {
        if (year <= currentYear) {
            setAñoInical(year);
            // Actualizar la URL con el nuevo año
            navigate(`/Farmanet/GerenciaVisual/Indicadores/LineaTiempoIndicador/${selectedIndicador}/${alignment}/${year}/${mes}`);
        } else {
            // Muestra una alerta o realiza alguna acción para indicar que la selección no es válida
            console.log("Error en el añoIncial")
        }
    };
    // PARAMETROS QUE SE LE PASA PARA LOS SELECT

    const handleChange = (event) => {
        const newMes = event.target.value;
        setMes(newMes);

        // Actualizar la URL con los nuevos parámetros (incluyendo el mes)
        navigate(`/Farmanet/GerenciaVisual/Indicadores/LineaTiempoIndicador/${selectedIndicador}/${alignment}/${selectAñoIncial}/${newMes}`);
    };


    const DataAct = {
        proceso: alignment, // NUMERO DELPROCESO, SE PASA POR EL PARAMS O DEPENDIENDE DEL QUE SE SELECCIONE
        año: selectAñoIncial //AÑO
    }
    // AQUI PARA EL SELECT DE LOS INDICADORES
    const indicador = async () => {
        try {
            const res = await newRequest.post('/api/GerenciaVisual/Indicadores/MostrarIndicadores', DataAct, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            // Verifica si res.data es un array antes de establecerlo en el estado
            if (res.data && res.data.length !== undefined) {
                setIndicadoresNombre(res.data);
            } else {
                console.log("La respuesta no contiene un array de indicadores:", res.data);
            }
        } catch (error) {
            console.log("error 🦋", error)
        }
    }

    const fetchData = async () => {
        try {
            const res = await newRequest.get('/api/GerenciaVisual/profundidadSelectAno')
            setAñoSelect(res.data.reverse());
        } catch (error) {
            console.error(error);
        }
    }

    // DATOS QUE SE VAN A MOSTRAR EN LA TABLA
    useEffect(() => {
        if (!selectedIndicador || !selectAñoIncial) return;

        const fetchData = async () => {
            try {
                setLoading(true); // Activar el estado de carga
                const res = await newRequest.post('/api/GerenciaVisual/Indicadores/ProcesoEmpresaInd', {
                    proceso: selectedIndicador,
                    tipo: alignment,
                    año: selectAñoIncial,
                    mes: mes
                }, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                setDataTabla(res.data.length ? res.data : []);
            } catch (error) {
                console.error(error);
                setDataTabla([]);
            } finally {
                setLoading(false); // Desactivar el estado de carga después de la consulta
            }
        };

        fetchData();
    }, [selectedIndicador, selectAñoIncial, alignment, mes]);

    useEffect(() => {
        // Llamada a indicador() y fetchData() inicial
        indicador();
        fetchData();
    }, [selectAñoIncial, alignment, mes]);

    useEffect(() => {
        // Establecer el valor inicial de selectedIndicador cuando se carga la página
        setSelectedIndicador(indProEmpSec);
    }, [indProEmpSec]);

    useEffect(() => {
        // Buscar el objeto con el ProEmpSec correspondiente a selectedIndicador
        const selectedObj = indicadoresNombre.find(item => item.ProEmpSec === parseInt(selectedIndicador));

        // Establecer el valor del nombre
        setnombreInd(selectedObj ? selectedObj.ProEmpNom : '');
    }, [selectedIndicador, indicadoresNombre]);


    const handleBack = () => {
        navigate("/Farmanet/GerenciaVisual/Indicadores")
    }

    return (
        <>
            <Box>
                <Grid item xs={12} sm={6} md={12} >
                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            {/* <Button variant="contained" style={{ borderRadius: "8px", background: "#1c64f2", }}
                                onClick={handleBack}>
                                <ChevronLeftIcon />
                            </Button> */}
                            <Fab onClick={handleBack} style={{ background: "white", width: "45px", height: "45px" }}>
                                <ChevronLeftIcon />
                            </Fab>

                            <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                                <Typography color="text.primary">Gerencia visual</Typography>
                                <Typography color="text.primary">Indicadores</Typography>
                                {/* <Link underline="hover" color="inherit" to="/Farmanet/Indicadores">Indicadores</Link> */}
                                <Typography color="text.primary">Linea de tiempo</Typography>
                            </Breadcrumbs>
                        </Box>
                        <Typography variant="h6" color="black" gutterBottom style={{
                            display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                            marginBottom: "25px", marginTop: "10px"
                        }}>
                            <TimelineIcon sx={{ paddingLeft: "5px", marginRight: "10px", color: "#FE0000", fontSize: "32px" }} />
                            Linea de tiempo
                        </Typography>
                    </Box>
                </Grid>
            </Box>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SelectorPanel
                        añoSelect={añoSelect} selectAñoIncial={selectAñoIncial} añoInicial={añoInicial}
                        meses={meses} mes={mes} handleChange={handleChange}
                        indicadoresNombre={indicadoresNombre} selectedIndicador={selectedIndicador} handleChangeNombre={handleChangeNombre}
                        alignment={alignment} handleChangeTipo={handleChangeTipo} nombreInd={nombreInd}
                    />


                    {loading ? (
                        // Mostrar el loader mientras se está realizando la consulta
                        // <CircularProgress style={{
                        //     margin: 'auto', display: "flex", alignItems: "center", justifyContent: "center",
                        //     marginTop: "10rem"
                        // }} />
                        <LinearProgress />

                    ) : (
                        <Paper variant="outlined" style={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: "2.5rem", marginBottom: "2.5rem" }}>
                            <TablaProcesoEmpresaInd dataTabla={dataTabla} />
                        </Paper>
                    )}


                </Grid>
            </Grid>
        </>
    )
}

export default LineaTiempoIndicador