import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts'
import {
    Box,
} from '@mui/material';
const IndicadorPorcentaje = ({ chartId, porcentaje }) => {

    useEffect(() => {

        const element = document.getElementById(chartId);
        if (!element) return; // Si no existe el elemento, termina la ejecución del efecto

        // const chart = echarts.init(document.getElementById(chartId));
        const chart = echarts.init(element);

        const option = {
            series: [
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    center: ['50%', '50%'],
                    radius: '100%',
                    min: 0,
                    max: 1,
                    splitNumber: 8,
                    axisLine: {
                        lineStyle: {
                            width: 4,
                            color: [
                                [0.80, '#FF6E76'],
                                [0.95, '#FFB534'],
                                [1, '#65B741'],
                            ]
                        }
                    },
                    pointer: {
                        icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                        length: '12%',
                        width: 15,
                        offsetCenter: [0, '-60%'],
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        length: 6,
                        lineStyle: {
                            color: 'auto',
                            width: 2
                        }
                    },
                    splitLine: {
                        length: 20,
                        lineStyle: {
                            color: 'auto',
                            width: 5
                        }
                    },
                    axisLabel: {
                        // color: '#464646',
                        // fontSize: 20,
                        distance: -60,
                        rotate: 'tangential',
                        formatter: function (value) {
                            if (value === 0.875) {
                                // return 'Grade A';
                            } else if (value === 0.625) {
                                // return 'Grade B';
                            } else if (value === 0.375) {
                                // return 'Grade C';
                            } else if (value === 0.125) {
                                // return 'Grade D';
                            }
                            return '';
                        }
                    },
                    title: {
                        offsetCenter: [0, '-10%'],
                        // fontSize: 20
                    },
                    detail: {
                        // show: false, // No mostrará el valor
                        fontSize: 25,
                        offsetCenter: [0, '-35%'],
                        valueAnimation: true,
                        // formatter: function (value) {
                        //     return Math.round(value * 100) + '%';
                        // },
                        formatter: function (value) {
                            // Verifica si value es NaN. Si es así, devuelve '0%'.
                            if (isNaN(value)) {
                                return '0%';
                            }
                            // De lo contrario, procede como antes.
                            return Math.round(value * 100) + '%';
                        },
                        color: 'inherit'
                    },
                    // data: [
                    //     {
                    //         // value: porcentaje,
                    //         value: parseFloat(porcentaje) / 100,
                    //         name: 'porcentaje'
                    //     }
                    // ]
                    data: [
                        {
                            value: isNaN(parseFloat(porcentaje)) ? 0 : parseFloat(porcentaje) / 100,
                            name: 'porcentaje'
                        }
                    ]
                }
            ]
        };

        chart.setOption(option);

        const calculatedValue = isNaN(parseFloat(porcentaje)) ? 0 : parseFloat(porcentaje) / 100;

        // Cambiar el color del título basado en el valor
        if (calculatedValue === 0) {
            chart.setOption({
                series: [{
                    detail: {
                        color: '#ff6e76', // Cambiar al color rojo si es 0%
                    }
                }]
            });
        }

        // Limpia el gráfico cuando el componente se desmonta
        // return () => {
        //     chart.dispose();
        // };

        const resizeHandler = () => {
            chart.resize();
        };

        window.addEventListener('resize', resizeHandler);

        // Limpia el gráfico cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', resizeHandler); // Cambiado a removeEventListener
            chart.dispose();
        };

    }, [chartId, porcentaje]);

    return (
        <div>
            <Box id={chartId} sx={{
                width: '100%',
                height: '240px',
                position: "relative",
                marginRight: "10px"
                // marginTop: "-5rem"
            }}>
            </Box>
        </div>
    )
}

export default IndicadorPorcentaje