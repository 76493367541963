/* eslint-disable react/jsx-pascal-case */
import React, { useMemo } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import { MaterialReactTable, MRT_ShowHideColumnsButton, useMaterialReactTable } from 'material-react-table';
import Tooltip from '@mui/material/Tooltip';
import OpcionesTabla from './OpcionesTabla';


const TablaVentasLinea = ({ dataZona, selectedOption, handleOpen, handleOptionChange }) => {
    const campos = dataZona.length > 0 ? Object.keys(dataZona[0]) : [];

    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];


    const formatNumber = (value, option) => {
        if (option === 'a' || option === 'b') {
            return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value);
        } else if (option === 'c') {
            return new Intl.NumberFormat(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value);
        }
        return value;
    };

    const calculateRowTotal = (row) => {
        return Object.keys(row).reduce((total, key) => {
            if (key !== 'linea' && key !== 'Total') {
                return total + (parseFloat(row[key]) || 0);
            }
            return total;
        }, 0);
    };

    const dataWithTotals = useMemo(() => {
        return dataZona.map((row) => ({
            ...row,
            Total: calculateRowTotal(row),
        }));
    }, [dataZona]);

    const sumaTotal = useMemo(() => {
        return dataZona.reduce((total, row) => total + calculateRowTotal(row), 0);
    }, [dataZona]);

    const columns = [
        {
            id: 'linea',
            accessorKey: 'linea',
            header: 'Linea',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            footer: 'Total',
        },
        ...campos
            .filter((campo) => campo !== 'linea')
            .map((campo, index) => ({
                id: campo,
                accessorKey: campo,
                header: meses[index],
                enableColumnActions: false,
                muiTableHeadCellProps: {
                    align: 'center',
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: index % 2 === 0 ? '#1C64F2' : '#27AE60',
                        color: "white",
                    }
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                size: 10,
                Cell: ({ cell }) => (
                    <div>
                        {formatNumber(cell.getValue(), selectedOption)}
                    </div>
                ),
                Footer: ({ column }) => {
                    const sum = dataZona.reduce((total, row) => total + (row[column.id] || 0), 0);
                    return (
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "left" }}>
                            {formatNumber(sum, selectedOption)}
                        </div>
                    );
                },
            })),
        {
            id: 'Total',
            accessorKey: 'Total',
            header: 'Total',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    fontWeight: 'normal',
                },
            },
            size: 12,
            Cell: ({ cell }) => (
                <div>
                    {formatNumber(cell.getValue(), selectedOption)}
                </div>
            ),
        },
    ];

    const table = useMaterialReactTable({
        columns,
        data: dataWithTotals,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: {
            columnPinning: { left: ['linea'], right: ['Total'] },
            density: 'compact'
        },
        localization: {
            toggleDensity: "Alternar densidad",
            rowsPerPage: "Filas por página",
            toggleFullScreen: "Alternar pantalla completa",
            search: "Buscar",
            columnActions: "mostrar/ocultar columnas",
            showHideSearch: "Filtro de busqueda",
            showHideColumns: "mostrar / ocultar columnas"
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem" }}>
                <Typography variant='lg' fontWeight="bold"> Ventas por Linea</Typography>
                <OpcionesTabla onOptionChange={handleOptionChange} selectedOptionPadre={selectedOption} />
            </div>
        ),
        renderBottomToolbarCustomActions: ({ table }) => (
            <Box>
                <Typography variant="body2" sx={{ fontWeight: "bolder", marginTop: 1 }} gutterBottom>
                    Total de los meses :
                    <span style={{ fontSize: "17px", marginLeft: "10px" }}>
                        {formatNumber(sumaTotal, selectedOption)}
                    </span>
                </Typography>
            </Box>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <Box>
                <MRT_ShowHideColumnsButton table={table} />
                <Tooltip title="Grafica">
                    <IconButton onClick={handleOpen}>
                        <BarChartIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        muiTableContainerProps: { sx: { width: "100%" } }
    });

    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <MaterialReactTable table={table} />
        </Box>
    );
};

export default TablaVentasLinea;
