import React, { Fragment, useEffect, useState } from 'react';
// import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import {
    Table, TableHead, TableBody, TableRow, TableCell, TableContainer,
    Box, Typography, Toolbar, TablePagination
} from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { getColorStyle } from './ColorUtils';

const TablaProcesoEmpresaInd = ({ dataTabla }) => {

    const { indProEmpSec, tipo, indicador } = useParams();

    const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']

    // Estado existente...
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Resetear a la primera página
    };

    const navigate = useNavigate();


    useEffect(() => {

    }, [dataTabla])
    // Verificación para asegurarse de que dataTabla no es undefined
    if (!dataTabla) {
        return null; // O maneja este caso según tus necesidades
    }

    // Verifica si la propiedad "Result" es igual a "Este año aun no tiene datos"
    // const hasNoData = dataTabla.Result === "Este año aun no tiene datos";

    //trim() para eliminar posibles espacios en blanco al principio o al final de la cadena:
    const hasNoData = dataTabla[0]?.Result === "Este año aun no tiene datos";;

    if (hasNoData) {
        return <Typography>No hay datos disponibles para este año.</Typography>;
    }


    const handleRowClick = (IndProEmpSec) => {
        navigate(`/Farmanet/GerenciaVisual/Indicadores/LineaTiempoIndicador/${indProEmpSec}/${tipo}/ResultadoIndicador/${IndProEmpSec}`);
    };

    return (
        <Box >
            <TableContainer style={{ height: 500, borderRadius: "8px", }}>
                <Table stickyHeader size='small' sx={{ overflow: 'auto' }}>
                    <TableHead sx={{ backgroundColor: '#1C64F2', color: 'white', }}>
                        <TableRow>
                            {/* <TableCell style={{
                                fontSize: '15px',
                                backgroundColor: '#1C64F2',
                                color: "white", textAlign: "center"
                            }}>
                            </TableCell> */}

                            <TableCell style={{
                                fontSize: '15px',
                                backgroundColor: '#1C64F2',
                                color: "white",
                                textAlign: "left",
                                maxHeight: "2.5rem",
                                width: "5rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}> Indicador</TableCell>

                            <TableCell style={{
                                fontSize: '15px', backgroundColor: '#1C64F2',
                                color: "white", textAlign: "center", maxWidth: "1rem",

                            }} >Meta </TableCell>

                            <TableCell style={{
                                fontSize: '15px',
                                backgroundColor: '#1C64F2',
                                color: "white",
                                textAlign: "center",

                                // maxHeight: "2.5rem",

                                maxWidth: "1rem",


                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            }} >
                                Resultado Indicador
                            </TableCell>

                            {meses.map((mes, index) => (
                                <TableCell style={{
                                    fontSize: '15px', backgroundColor: '#1C64F2',
                                    color: "white", textAlign: "center"
                                }} key={index} >{mes}</TableCell>
                            ))}

                            <TableCell style={{
                                fontSize: '15px', backgroundColor: '#E74C3C',
                                color: "white",
                                textAlign: "center",
                            }}>Frecuencia</TableCell>

                            <TableCell style={{
                                fontSize: '15px',
                                backgroundColor: '#E74C3C',
                                color: "white",
                                textAlign: "center",

                                // maxWidth: "20rem",
                                width: "8rem",
                                height: "3rem"
                            }}>
                                Formula
                            </TableCell>

                            <TableCell style={{
                                fontSize: '15px', backgroundColor: '#1C64F2',
                                color: "white", textAlign: "center", fontWeight: "normal"
                            }}>Criterio</TableCell>

                            <TableCell style={{
                                fontSize: '15px', backgroundColor: '#1C64F2',
                                color: "white", textAlign: "center", fontWeight: "normal"
                            }}>Peso</TableCell>

                        </TableRow>
                    </TableHead>

                    <TableBody >
                        {/* {dataTabla.map((row, rowIndex) => ( */}
                        {dataTabla
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, rowIndex) => (
                                <TableRow key={rowIndex} hover
                                    onClick={() => handleRowClick(row.IndProEmpSec)}
                                    style={{ cursor: 'pointer' }} // Opcional: Cambia el cursor para mejorar la UX
                                >
                                    {/* <TableCell style={{ width: 50 }}>
                                        <Link
                                            key={rowIndex}
                                            to={`/Farmanet/Indicadores/LineaTiempoIndicador/${indProEmpSec}/${tipo}/ResultadoIndicador/${row.IndProEmpSec}`}
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <span >
                                                <RemoveRedEyeIcon sx={{ fontSize: "18px" }} />
                                            </span>
                                        </Link>
                                    </TableCell> */}

                                    <TableCell
                                        style={{
                                            // height: "55px",
                                            border: '1px solid rgba(224, 224, 224, 1)',
                                            minHeight: "4rem",
                                            height: "3rem",

                                            maxWidth: "18rem",
                                            minWidth: "18rem",
                                            width: "18rem",

                                            position: "relative",
                                            paddingRight: "1rem",
                                            maxLines: 3
                                        }}
                                        title={`${row.Indicador} ( ${row.IndProTipCod} - ${row.IndProTipCod2} )`}
                                    >
                                        <Typography variant='subtitle2' style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
                                            {row.Indicador} ( {row.IndProTipCod} - {row.IndProTipCod2} )
                                        </Typography>
                                    </TableCell>

                                    <TableCell style={{
                                        textAlign: "right", border: '1px solid rgba(224, 224, 224, 1)',
                                        whiteSpace: 'nowrap', // Evitar ajuste de línea para texto largo
                                    }}>
                                        <Typography variant='subtitle2' >
                                            {row?.Meta}
                                        </Typography>

                                    </TableCell>

                                    <TableCell style={{
                                        textAlign: "right",
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                    }}>
                                        {
                                            row["Meta"] && row["Meta"].includes('%') ?
                                                (
                                                    // Si row["Resultado Indicador"] no es 'SinCalculo', formatea el valor + '%'; de lo contrario, muestra "Sin cálculo"
                                                    `${row["Resultado Indicador"] !== 'SinCalculo' ? (parseFloat(row["Resultado Indicador"]) * 100).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%' : 'Sin cálculo'}`
                                                )
                                                :
                                                (
                                                    // Si row["Resultado Indicador"] no es 'SinCalculo', formatea el valor; de lo contrario, muestra "Sin cálculo"
                                                    `${row["Resultado Indicador"] !== 'SinCalculo' ? parseFloat(row["Resultado Indicador"]).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'Sin cálculo'}`
                                                )
                                        }
                                    </TableCell>

                                    {/* Renderizado condicional basado en la frecuencia */}
                                    {meses.map((mes, mesIndex) => {
                                        // Lógica para determinar si la celda debe mostrarse basada en la frecuencia
                                        let mostrarCelda = false;
                                        let resultado = null;
                                        let colSpan = 1; // Predeterminado a 1, ajustar según la lógica de frecuencia

                                        switch (row.IndProFre) {
                                            case 'Mensual':
                                                mostrarCelda = true;
                                                resultado = parseFloat(row[meses[mesIndex]]);
                                                colSpan = 1;
                                                break;
                                            case 'Bimestral':
                                                mostrarCelda = mesIndex % 2 === 0; // Verdadero cada dos meses
                                                resultado = parseFloat(row[meses[mesIndex]]); // Asumiendo que el resultado bimestral está en el primer mes del bimestre
                                                colSpan = 2;
                                                break;
                                            case 'Trimestral':
                                                mostrarCelda = mesIndex % 3 === 0; // Verdadero cada tres meses
                                                resultado = parseFloat(row[meses[mesIndex]]); // Asumiendo que el resultado trimestral está en el primer mes del trimestre
                                                colSpan = 3;
                                                break;
                                            case 'Cuatrimestral':
                                                mostrarCelda = mesIndex % 4 === 0; // Verdadero cada cuatro meses
                                                resultado = parseFloat(row[meses[mesIndex]]);
                                                colSpan = 4;
                                                break;
                                            case 'Semestral':
                                                mostrarCelda = mesIndex % 6 === 0; // Verdadero cada seis meses
                                                resultado = parseFloat(row[meses[mesIndex]]);
                                                colSpan = 6;
                                                break;
                                            case 'Anual':
                                                // Asumiendo que el resultado anual está en el primer mes del año
                                                mostrarCelda = mesIndex === 0; // Solo el primer mes contiene el valor para anual
                                                resultado = parseFloat(row[meses[mesIndex]]);
                                                colSpan = 12; // Ocupa toda la fila
                                                break;
                                            // Agrega más casos según sea necesario
                                        }

                                        if (mostrarCelda) {
                                            return (
                                                <TableCell key={mesIndex} colSpan={colSpan} style={{ textAlign: "center", border: '1px solid rgba(224, 224, 224, 1)' }}>
                                                    {/* Renderiza tu contenido de celda aquí, por ejemplo: */}
                                                    {resultado !== null && !isNaN(resultado) ? (
                                                        <div style={{
                                                            height: "30px",
                                                            borderRadius: "12px",
                                                            marginLeft: "-8px",
                                                            marginRight: "-8px",
                                                            background: getColorStyle(row['Meta'], resultado, row['Criterio']),
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}>
                                                            <Typography variant='subtitle2'>
                                                                {(resultado * 100).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
                                                            </Typography>
                                                        </div>
                                                    ) : <span></span>}
                                                </TableCell>
                                            );
                                        }
                                        return null; // En casos donde no se debe mostrar la celda, retorna null
                                    })}

                                    < TableCell style={{
                                        border: '1px solid rgba(224, 224, 224, 1)', maxWidth: "8rem", width: "8rem"
                                    }}>
                                        <Typography variant='subtitle2' style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }} >
                                            {row.IndProFre} - ( {row.IndProTipCod} - {row.IndProTipCod2} )
                                        </Typography>
                                    </TableCell>

                                    <TableCell style={{ border: '1px solid rgba(224, 224, 224, 1)', position: "relative", maxWidth: "8rem", width: "8rem" }} title={row.formula}>
                                        <Typography variant='subtitle2' style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }} >
                                            {row.formula}
                                        </Typography>
                                    </TableCell>

                                    <TableCell style={{
                                        textAlign: "center",
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                        whiteSpace: 'nowrap', // Evitar ajuste de línea para texto largo
                                    }}>
                                        <Typography variant='subtitle2' >
                                            {row.Criterio}
                                        </Typography>
                                    </TableCell>

                                    <TableCell style={{
                                        textAlign: "right",
                                        border: '1px solid rgba(224, 224, 224, 1)',
                                        whiteSpace: 'nowrap', // Evitar ajuste de línea para texto largo
                                    }}>
                                        <Typography variant='subtitle2' >
                                            {row.peso}%
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer >
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataTabla.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`}
            />
        </ Box>
    )
}

export default TablaProcesoEmpresaInd