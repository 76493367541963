import React, { useEffect, useState } from 'react'
import { Grid, Typography, CircularProgress, Box, Modal } from '@mui/material'
import newRequest from '../../../../utils/newRequest'
// import OpcionesTabla from './OpcionesTabla'
import TablaVentasProducto from './TablaVentasProducto'
import GraficoVentasVendedor from './GraficoVentasVendedor'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "70%",
    height: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: "8px"
};


const VentasVendedor = ({ selectAñoIncial, selectedTercero, selectedCliente, selectedZona,
    selectedProduct, selectedLineas, selectedVendedor }) => {

    const [loading, setLoading] = useState(false);
    const [dataZona, setDataZona] = useState([])

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // console.log("open Vendedor", open)

    const [selectedOption, setSelectedOption] = useState("a"); // Estado para el valor seleccionado

    // Función para manejar el cambio de opción
    const handleOptionChange = (newValue) => {
        setSelectedOption(newValue);
        // Realiza cualquier otra acción que desees con el nuevo valor seleccionado.
    }

    useEffect(() => {
        const getVentasZonas = async () => {
            try {
                setLoading(true);
                const res = await newRequest.post('/api/GerenciaVisual/Ventas/VentasgetVendedorVentas', {
                    // datoCheck: selectedValue,
                    datoCheck: selectedOption,
                    año: selectAñoIncial,
                    tercero: selectedTercero,
                    cliente: selectedCliente,
                    zona: selectedZona,
                    producto: selectedProduct,
                    linea: selectedLineas,
                    vendedor: selectedVendedor
                });
                setDataZona(Array.isArray(res.data) ? res.data : []);
                setLoading(false);
            } catch (error) {
                return alert("Error en la la consulta de ->getVentasZonas<-")
            }
        }
        getVentasZonas();
    }, [selectedOption, selectAñoIncial, selectedTercero, selectedCliente,
        selectedZona, selectedProduct, selectedLineas, selectedVendedor])

    return (
        <div style={{ width: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {loading ? (
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "396px" }} >
                            <CircularProgress sx={{ marginRight: "25px" }} />
                            <Typography>
                                Ventas por Vendedor
                            </Typography>
                        </div>
                    ) : (
                        < div>
                            <TablaVentasProducto dataZona={dataZona} handleOpen={handleOpen}
                                selectedOption={selectedOption} handleOptionChange={handleOptionChange} />
                        </div>
                    )}
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <GraficoVentasVendedor dataZona={dataZona} />
                </Box>
            </Modal>
        </div >
    )
}

export default VentasVendedor