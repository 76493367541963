// GraficasIndicador.js

import React, { useEffect, useState } from 'react';
import * as echarts from 'echarts';

const GraficasIndicador = ({ datos, datosPorIndicador }) => {
    useEffect(() => {
        if (!datos || !datosPorIndicador) {
            return;
        }

        const chart = echarts.init(document.getElementById('nightingale-chart'));

        const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        const meta = Array.from({ length: 12 }, (_, i) => {
            const metaConPorcentaje = datos.IndProEmpresa || "0%";
            return parseInt(metaConPorcentaje.replace('%', ''), 10);
        });

        let metaDos = Array.from({ length: 12 }, (_, i) => 0);
        let legendData = ['Meta', 'Resultado Barras', 'Resultado Línea'];

        if (datos.IndProMetCri === "Rango") {
            metaDos = Array.from({ length: 12 }, (_, i) => {
                const metaConPorcentaje = datos.IndProEmpresaDos || "0%";
                return parseInt(metaConPorcentaje.replace('%', ''), 10);
            });
            legendData.push('Meta Dos');
        }

        const resultadoBarras = datosPorIndicador.map(item => item.DatIndRes || 0);
        const resultadoLinea = datosPorIndicador.map(item => item.DatIndRes || 0);

        const maxResult = Math.max(...[...resultadoBarras, ...resultadoLinea]);

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    crossStyle: {
                        color: '#999'
                    }
                }
            },
            legend: {
                data: legendData,
            },
            xAxis: [
                {
                    type: 'category',
                    data: months,
                    axisPointer: {
                        type: 'shadow'
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: 'Meta',
                    smooth: true,
                    position: 'right',
                    max: maxResult + 10,
                    min: 0,
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#1C64F2',
                        },
                    },
                },
                {
                    type: 'value',
                    position: 'right',
                    offset: 80,
                    min: 0,
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#27AE60',
                        },
                    },
                },
                {
                    type: 'value',
                    name: 'Resultado Barras',
                    position: 'left',
                    smooth: true,
                    min: 0,
                    alignTicks: true,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#E74C3C',
                        },
                    },
                },
            ],
            series: [
                {
                    name: 'Meta',
                    type: 'line',
                    data: meta,
                    smooth: true,
                },
                {
                    name: 'Meta Dos',
                    type: 'line',
                    data: metaDos,
                    smooth: true,
                },
                {
                    name: 'Resultado Barras',
                    type: 'bar',
                    yAxisIndex: 1,
                    data: resultadoBarras,
                    smooth: true,
                },
                {
                    name: 'Resultado Línea',
                    type: 'line',
                    yAxisIndex: 2,
                    smooth: true,
                    data: resultadoLinea,
                },
            ]
        };

        chart.setOption(option);

        const resizeHandler = () => {
            chart.resize();
        };

        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
            chart.dispose();
        };
    }, [datos, datosPorIndicador]);

    return (
        <div>
            <div id="nightingale-chart" style={{ width: '100%', height: '550px' }}></div>
        </div>
    );
}

export default GraficasIndicador;