import React, { useEffect, useState } from 'react'
import CartaIndicador from './CartaIndicador/CartaIndicador'
import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup, Checkbox, FormControlLabel, InputAdornment, ButtonGroupContext
    // Skeleton
} from '@mui/material';
import { Link } from 'react-router-dom';
import newRequest from '../../../../utils/newRequest';
import { number } from 'echarts';


const VistaEmpresa = ({ indicadores, alignment, selectAñoIncial, mes }) => {

    // useEffect(() => {

    // }, [selectAñoIncial, alignment, indicadores, mes])

    return (
        <Box>
            <Grid container
                spacing={2}
                sx={{ marginTop: 1, marginBottom: "3rem", }}
            >
                {indicadores.map((item, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={index} >
                        {/* <Grid item xs={12} sm={6} md={6} lg={4} key={item} > */}
                        <Link to={`/Farmanet/GerenciaVisual/Indicadores/LineaTiempoIndicador/${item.ProEmpSec}/${alignment}/${selectAñoIncial}/${mes}`}
                            style={{ textDecoration: "none" }}>
                            <CartaIndicador
                                mes={mes}
                                item={item}
                                alignment={alignment}
                                selectAñoIncial={selectAñoIncial}
                                chartId={`chart-${index}`}
                            />
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default VistaEmpresa