import React, { useContext, useEffect, useState } from 'react'

import { useParams, useNavigate, Link } from 'react-router-dom';
import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, Button, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup, Checkbox, FormControlLabel, InputAdornment, FormControl, InputLabel,
    Select, MenuItem, Fab, LinearProgress
    // Skeleton
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';


import QueryStatsIcon from '@mui/icons-material/QueryStats';
import TablaResultadoIndicador from './TablaResultadoIndicador';
import GraficasIndicador from './GraficasIndicador';
import newRequest from '../../../../../../utils/newRequest';

const ResultadoIndicador = () => {

    const { indProEmpSec, tipo, indicador } = useParams();

    const [loading, setLoading] = useState(true);
    const [loadingDos, setLoadingDos] = useState(true);

    const [datos, setdatos] = useState([])
    const [datosPorIndicador, setdatosPorIndicador] = useState([])

    const navigate = useNavigate();


    const handleGoBack = () => {
        navigate(-1); // Retrocede una página en el historial
    };


    useEffect(() => {
        const ObtenerDatosIndicador = async () => {
            try {
                setLoading(true); // Marcar como cargando al iniciar la solicitud

                const res = await newRequest.get(`/api/GerenciaVisual/Indicadores/ObtenerIndicador/${indicador}/${indProEmpSec}`)

                if (res.data.length) {
                    setdatos(res.data[0])
                } else {
                    setdatos([])
                    // console.log("La respuesta no contiene datos :", res.data);
                }
            } catch (error) {
                console.log("error", error)
            }
            finally {
                setLoading(false); // Marcar como no cargando cuando la solicitud termina (ya sea éxito o error)
            }
        }

        const ResultadoPorIndicador = async () => {
            try {
                setLoadingDos(true); // Marcar como cargando al iniciar la solicitud
                const res = await newRequest.get(`/api/GerenciaVisual/Indicadores/ResultadoPorIndicador/${indProEmpSec}/${indicador}`)
                if (res.data.length) {
                    setdatosPorIndicador(res.data);
                } else {
                    setdatosPorIndicador([]);
                    // console.log("La respuesta no contiene un array de indicadores:", res.data);
                }
            } catch (error) {
                console.log("error", error)
            }
        }

        ResultadoPorIndicador()
        ObtenerDatosIndicador();
    }, [indProEmpSec, tipo, indicador])

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <div>
            <Box>
                <Grid item xs={12} sm={6} md={12} >


                    <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Fab onClick={handleBack} style={{ background: "white", width: "45px", height: "45px" }}>
                                <ChevronLeftIcon />
                            </Fab>

                            <Breadcrumbs maxItems={3} aria-label="breadcrumb" style={{ marginLeft: "15px" }}>
                                <Typography color="text.primary">Gerencia visual</Typography>
                                <Typography color="text.primary">Indicadores</Typography>
                                <Typography color="text.primary">Linea de tiempo</Typography>
                                <Typography color="text.primary">Resultado por Indicador</Typography>
                            </Breadcrumbs>
                        </Box>
                        <Typography variant="h6" color="black" gutterBottom style={{
                            display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                            marginBottom: "25px", marginTop: "10px"
                        }}>
                            <QueryStatsIcon sx={{ paddingLeft: "5px", marginRight: "10px", color: "#FE0000", fontSize: "32px" }} />
                            Resultado por Indicador
                        </Typography>
                    </Box>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <Paper variant="elevation" square elevation={3} // Aplicar sombra si showShadow es verdadero, de lo contrario, sin sombra
                            style={{
                                borderRadius: "13px",
                                position: "sticky",
                                top: 55, // Establece la distancia desde la parte superior donde se quedará fijo el elemento
                                zIndex: 3, // Asegura que el elemento tenga una posición superior al resto del contenido
                                boxShadow: " 0px 0px 30px 10px rgba(0, 0, 0, 0.2)", // Agrega una sombra debajo del Paper
                                transition: "box-shadow 0.3s", // Agregar una transición suave para la sombra
                            }}
                        >
                            <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center", margin: 1, justifyItems: "center", }}>
                                <Grid container spacing={1} alignItems="center" sx={{
                                    marginBottom: "17px", marginLeft: "10px", marginRight: "10px", marginTop: "8px",
                                    justifyContent: "space-between", display: "flex", justifyItems: "center",
                                }}>
                                    {/* Aqui va el año */}
                                    <Grid item xs={12} sm={6} md={6} lg={3} xl={6} >
                                        <Typography sx={{
                                            fontSize: "1.0rem", textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                                        }} >
                                            <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                Nombre indicador:
                                            </span>
                                            {datos?.IndProEmpNom}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={6} >
                                        <Typography sx={{
                                            fontSize: "1.0rem", textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap', overflow: 'hidden',
                                        }} >
                                            <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                Relación indicador
                                            </span>

                                            {datos?.IndProRel} / {datos?.IndProRelDos}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={6} lg={4} xl={6} >
                                        <Typography sx={{ fontSize: "1.0rem", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                            <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                Frecuencia:
                                            </span>
                                            {datos?.IndProFre}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={6} lg={1} xl={6} >
                                        <Typography sx={{ fontSize: "1.0rem", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                                            <span style={{ fontWeight: "bold", marginRight: "8px" }}>
                                                Meta:
                                            </span>
                                            {datos?.IndProEmpresa} {datos?.IndProEmpresaDos ? ` - ${datos?.IndProEmpresaDos}` : ''}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Box>
                        </Paper>

                        {loading ? (
                            <LinearProgress />

                        ) : (
                            <Paper variant="outlined" sx={{ p: { xs: 1, md: 1 }, borderRadius: "13px", marginTop: "2rem", marginBottom: "2rem" }}>
                                <Grid container spacing={1} alignItems="center" sx={{ marginTop: "6px" }}>
                                    {/* Aqui va el año */}
                                    <Grid item xs={12} sm={12} md={12} lg={4} xl={5} >
                                        <GraficasIndicador datos={datos} datosPorIndicador={datosPorIndicador} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={8} xl={7}  >
                                        <TablaResultadoIndicador datosPorIndicador={datosPorIndicador} datos={datos} />
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </Box>



        </div>
    )
}

export default ResultadoIndicador