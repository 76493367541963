import React, { useEffect, useState } from 'react'
import {
    Box, Typography, Tooltip, Button, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

import DrawIcon from '@mui/icons-material/Draw';
import DeleteIcon from '@mui/icons-material/Delete';
import PercentIcon from '@mui/icons-material/Percent';
import newRequest from '../../../../utils/newRequest';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import AddIcon from '@mui/icons-material/Add';

const TablaIndicadoresEmpresa = ({ setOpenDrawer, setOpenDrawerDos, ObtenerIndicador,
    obtenerCantidadIndicador, datosIndicador, onIDSelected, obtenerProceso }) => {

    const [id, setId] = useState("")
    const [idDos, setIdDos] = useState("")

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const crearIndicador = () => {
        setOpenDrawer(true)
        onIDSelected("")
    }

    const CrearPesoIndicador = (row) => {
        setOpenDrawerDos(true)
        onIDSelected(row)
        setId("")
    }

    const handleButtonClick = (row) => {
        try {
            setId(row.IndProEmpSec)
        } catch (error) {
            console.error(error);
        }
    };

    const handleOpenConfirmDialog = (row) => {
        //Pasamos el parametro que recibimos a un estado para que pueda compartirse
        setIdDos(row.IndProEmpSec)
        //Abrimos el modal
        setOpenConfirmDialog(true);
    };

    const EliminarUnIndicador = async () => {
        try {
            // Aqui recibimos el estado
            const res = await newRequest.delete(`/api/Calidad/Indicadores/EliminarUnIndicador/${idDos}`);
            if (!res.status === 200) {
                toast.error(
                    `Posiblemente tiene datos asociados en la tabla "DatosIndicador"`,
                    {
                        position: "bottom-left",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
            } else {
                toast.success(
                    `Dato eliminado correctamente`,
                    {
                        position: "bottom-left",
                        autoClose: 4000,
                        theme: "dark",
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                    }
                );
                // Llama a ObtenerIndicador después de eliminar un indicador
                obtenerProceso();
                ObtenerIndicador();
                obtenerCantidadIndicador() // Actualiza la cantidad de indicadores del proceso
            }
        } catch (error) {
            console.error(error);
        }
    };
    const calcularSumaIndProPesoUno = () => {
        if (!Array.isArray(datosIndicador)) {
            return 0; // Retorna 0 si datosIndicador no es un array válido
        }

        let suma = 0;
        datosIndicador.forEach((item) => {
            const valor = parseFloat(item.IndProPesoUno);
            if (!isNaN(valor)) {
                suma += valor;
            }
        });
        return suma;
    };

    const totalPeso = calcularSumaIndProPesoUno(); // Calcula la suma

    useEffect(() => {
        if (id) {
            setOpenDrawer(true) // Abre el modal para actualizar
            const ObtenerUnIndicador = async () => {
                try {
                    const res = await newRequest.post(`/api/Calidad/Indicadores/ObtenerIdIndicador`, {
                        id: id
                    });
                    // console.log("res.data", res.data)
                    // setOpenDrawer(true) // Abre el modal
                    onIDSelected(res.data); // Llama a la función con el ID como argumento
                    setId("")
                } catch (error) {
                    console.error(error)
                }
            }
            ObtenerUnIndicador();
        }
    }, [id, idDos, onIDSelected, setOpenDrawer])

    const columns = [
        {
            accessorKey: 'IndProEmpNom', //access nested data with dot notation
            header: 'Indicador',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2', color: "white",
                    fontSize: "15px", fontWeight: 'normal',
                }
            },
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    // border: '1px solid rgba(224, 224, 224, 1)',
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    // height: "40px",  // Ajusta este valor según tus necesidades
                    // maxHeight: "40px",
                    maxWidth: "50px",
                    width: "50px",
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.IndProEmpNom}`} arrow>
                        <Box sx={{ fontSize: "14px" }}>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.IndProEmpNom}
                            </Typography>
                        </Box>
                    </Tooltip>

                )
            },

        },
        {
            accessorKey: 'Objetivo', //access nested data with dot notation
            header: 'Objetivo',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2', color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            enableColumnActions: false,
            enableColumnFilters: false,
            enableSorting: false,
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    // border: '1px solid rgba(224, 224, 224, 1)',
                    height: "40px",  // Ajusta este valor según tus necesidades
                    maxHeight: "40px",
                    maxWidth: "50px",
                    width: "50px",
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.Objetivo}`} arrow>
                        <Typography variant='subtitle2' style={{
                            textTransform: 'lowercase', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
                        }}>
                            {cell.row.original.Objetivo}
                        </Typography>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'Observacion', //access nested data with dot notation
            header: 'Descripción',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#27AE60', color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            // size: 120,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    // border: '1px solid rgba(224, 224, 224, 1)',
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    // height: "40px",  // Ajusta este valor según tus necesidades
                    // maxHeight: "40px",
                    maxWidth: "50px",
                    width: "50px",
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.IndProRel} /  ${cell.row.original.IndProRelDos}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.IndProRel} /  {cell.row.original.IndProRelDos}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'IndProFre', //access nested data with dot notation
            header: 'Frecuencia',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2', color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            size: 100,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'center',
                sx: {
                    // border: '1px solid rgba(224, 224, 224, 1)',
                    fontSize: "14px",  // Ajusta este valor según tus necesidades
                    // height: "40px",  // Ajusta este valor según tus necesidades
                    // maxHeight: "40px",
                    // maxWidth: "20px",
                    // width: "20px",
                }
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.IndProFre}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{
                                textTransform: 'lowercase', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
                            }}>
                                {cell.row.original.IndProFre}
                            </Typography>
                        </Box>
                    </Tooltip>

                )
            },

        },
        {
            accessorKey: 'IndProMetCri', //access nested data with dot notation
            header: 'Criterio',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2', color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                    // width: "1rem", maxWidth: "1rem",
                }
            },
            size: 100,
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.IndProMetCri}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'lowercase' }}>
                                {cell.row.original.IndProMetCri}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },

        },
        {
            accessorKey: 'IndProTipNom', //access nested data with dot notation
            header: 'Resultado',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2', color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                    // width: "1rem", maxWidth: "1rem",
                }
            },
            size: 100,
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.IndProTipNom}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'lowercase' }}>
                                {cell.row.original.IndProTipNom}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'IndProEmpresa', //access nested data with dot notation
            header: 'Meta',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2', color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                    // width: "1rem", maxWidth: "1rem",
                }
            },
            size: 100,
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.IndProEmpresa}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'lowercase' }}>
                                {cell.row.original.IndProEmpresa}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'IndProPesoUno', //access nested data with dot notation
            header: 'peso',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2', color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            size: 100,
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.IndProPesoUno}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'lowercase' }}>
                                {cell.row.original.IndProPesoUno}%
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'IndProAno', //access nested data with dot notation
            header: 'Año',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    backgroundColor: '#1C64F2', color: "white",
                    fontWeight: 'normal', fontSize: "15px",
                }
            },
            size: 100,
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ cell }) => {
                return (
                    <Tooltip title={`${cell.row.original.IndProAno}`} arrow>
                        <Box>
                            <Typography variant='subtitle2' style={{ textTransform: 'lowercase' }}>
                                {cell.row.original.IndProAno}
                            </Typography>
                        </Box>
                    </Tooltip>
                )
            },
        },
        {
            accessorKey: 'Opciones',
            header: 'Opciones',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal', fontSize: "15px",
                    backgroundColor: '#1C64F2',
                    color: "white",
                }
            },
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'center',
                height: "20px !important",
            },
            Cell: ({ cell }) => {
                return (
                    < div style={{ position: "relative", }}>
                        <ButtonGroup variant="text" >
                            <Tooltip title="Peso">
                                <Button onClick={() => CrearPesoIndicador(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <PercentIcon sx={{ width: '25px', height: '25px', color: 'orangered' }} />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Editar">
                                <Button onClick={() => handleButtonClick(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <DrawIcon sx={{ width: '25px', height: '25px', color: '#118DF0' }} />
                                </Button>
                            </Tooltip>

                            <Tooltip title="Eliminar">
                                <Button onClick={() => handleOpenConfirmDialog(cell.row.original)}
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <DeleteIcon sx={{ width: '25px', height: '25px', color: '#FF2442' }} />
                                </Button>
                            </Tooltip>
                        </ButtonGroup>
                    </div >
                )
            },
        },
    ];

    // console.log("datosIndicador", datosIndicador)

    return (
        <div>

            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='h5'>
                        Confirmar Eliminación
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        <Typography variant='body1'>
                            ¿Está seguro de que desea eliminar este dato?
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' onClick={() => setOpenConfirmDialog(false)} color="primary">
                        <Typography variant='sub'>
                            Cancelar
                        </Typography>
                    </Button>
                    <Button variant='text' sx={{ color: "red" }} onClick={() => {
                        setOpenConfirmDialog(false);
                        // Llamar a la función para eliminar el proceso aquí
                        EliminarUnIndicador();
                    }} color="error" autoFocus>
                        <Typography variant='button'>
                            Eliminar
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>


            <MaterialReactTable columns={columns} data={datosIndicador}
                muiTablePaperProps={{
                    elevation: 0,
                    sx: {
                        backgroundColor: 'white', // Cambiar el color de fondo a rojo
                        marginTop: -4
                    },
                }}
                enableStickyHeader

                enableColumnActions={false}
                enableColumnFilters={false}
                enableSorting={false}

                renderTopToolbarCustomActions={({ table }) => (
                    <Button variant="outlined"
                        onClick={crearIndicador}
                    >
                        <Typography sx={{ fontSize: "13px", textTransform: "lowercase" }} >
                            Insertar indicador
                        </Typography>
                        <AddIcon sx={{ marginLeft: "5px", width: "20px", height: "20px" }} />
                    </Button>
                    // </Box>
                )}
                renderBottomToolbarCustomActions={({ table }) => (
                    <Box>
                        <Typography variant="subtitle1" sx={{ fontSize: "18px", textTransform: "lowercase" }} gutterBottom>
                            Total del peso : {totalPeso}%
                        </Typography>
                    </Box>
                )}
                localization={MRT_Localization_ES}
                muiTableHeadCellProps={{
                    sx: {
                        fontWeight: 'normal !important',
                        fontSize: '16px !important',
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        height: "20px !important",
                        // border: '1px solid rgba(224, 224, 224, 1)',
                    },
                }}
                enableDensityToggle={false}
                enableFilters={false}
                // initialState={{
                //     density: 'compact'
                // }}

                initialState={{
                    density: 'compact',
                    pagination: { pageIndex: 0, pageSize: 10 },
                    columnPinning: { left: ['IndProEmpNom'] }
                    // columnPinning: { left: ['nit', 'tercero'] }
                }}

                muiTableContainerProps={{
                    sx: {
                        height: 370, width: "100%",
                    }
                }}
            />
        </div>
    )
}

export default TablaIndicadoresEmpresa