import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { CardActionArea, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import SettingsIcon from '@mui/icons-material/Settings';

const ImportacionesPrincipal = () => {

    return (
        <div>
            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Typography variant="h5" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                        marginBottom: "25px", marginTop: "1.5rem"
                    }}>
                        <DirectionsBoatFilledIcon style={{ marginRight: "10px", color: "#FE0000" }} />
                        Importaciones
                    </Typography>
                </Box>
            </Grid>


            <Box >
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Paper variant="elevation" square elevation={6} style={{ borderRadius: "13px", position: "initial" }}>
                            <Card sx={{ display: 'flex', height: "130px", borderRadius: "13px", }}>
                                <CardActionArea component={Link} to="/Farmanet/ContenedoresImportaciones" sx={{ display: "flex" }}>
                                    <Grid item xs={3} sm={3} md={3}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            bgcolor="#EEF2FF"
                                            height="230px"
                                            width="100%"
                                        >
                                            <DirectionsBoatFilledIcon style={{ fontSize: 40 }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                <Typography sx={{ fontSize: 14, color: "#FE0000" }} color="text.secondary" gutterBottom>
                                                    Importaciones
                                                </Typography>
                                                <Typography variant="h5" component="div" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                    Contenedores
                                                </Typography>
                                            </CardContent>
                                        </Box>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Paper variant="elevation" square elevation={6} style={{ borderRadius: "13px", position: "initial" }}>
                            <Card sx={{ display: 'flex', height: "130px", borderRadius: "13px", }}>
                                <CardActionArea component={Link} to="/Farmanet/ParametrosImportaciones" sx={{ display: "flex" }}>
                                    <Grid item xs={3} sm={3} md={3}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            bgcolor="#EEF2FF"
                                            height="230px"
                                            width="100%"
                                        >
                                            <SettingsIcon style={{ fontSize: "40px" }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={9} sm={9} md={9}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                <Typography sx={{ fontSize: 14, color: "#FE0000" }} color="text.secondary" gutterBottom>
                                                    Importaciones
                                                </Typography>
                                                <Typography variant="h5" component="div" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                    Parametros
                                                </Typography>
                                            </CardContent>
                                        </Box>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Box >
        </div>
    )
}

export default ImportacionesPrincipal