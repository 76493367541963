import React, { useEffect, useState } from 'react'
import {
    Box, Breadcrumbs, Grid, Paper, Stack, Typography, TextField, Autocomplete, ToggleButton,
    ToggleButtonGroup, Checkbox, FormControlLabel, InputAdornment, Radio, RadioGroup,
    FormControl, FormLabel
    // Skeleton
} from '@mui/material';

const OpcionesTabla = ({ onOptionChange, selectedOptionPadre }) => {
    // ? Select de los Radio Buttons
    const [selectedValue, setSelectedValue] = useState("a");


    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);


        onOptionChange(newValue); // Llama a la función proporcionada por el padre
    };


    // Aqui traemos el valor desde el padre para que se guarde en el estado
    useEffect(() => {
        setSelectedValue(selectedOptionPadre)
    }, [selectedOptionPadre])


    return (
        <div>
            {/* Aqui va los Rad */}
            {/* <FormLabel id="demo-radio-buttons-group-label">Ver en: </FormLabel> */}
            <FormControlLabel sx={{ marginBottom: "-10px" }}
                control={<Radio
                    checked={selectedValue === "a"}
                    onChange={handleChange}
                    value="a"
                    sx={{
                        fontWeight: 'normal',
                        // color: "Green",
                        // '&.Mui-checked': {
                        //     color: "Green",
                        // },
                    }}
                />}
                label="Valor"
            />
            <FormControlLabel sx={{ marginBottom: "-10px" }}
                control={<Radio
                    checked={selectedValue === "b"}
                    onChange={handleChange}
                    value="b"
                    sx={{
                        fontWeight: 'normal',
                        // color: "Green",
                        // '&.Mui-checked': {
                        //     color: "Green",
                        // },
                    }}
                />}
                label="Costo"
            />
            <FormControlLabel sx={{ marginBottom: "-10px" }}
                control={<Radio
                    checked={selectedValue === "c"}
                    onChange={handleChange}
                    value="c"
                    sx={{
                        align: 'center',
                        fontWeight: 'normal',
                        // color: "black",
                        // '&.Mui-checked': {
                        //     color: "black",
                        // },
                    }}
                />}
                label="Cantidad"
            />
        </div>
    )
}

export default OpcionesTabla