import React from 'react'
import { Box, Divider } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { CardActionArea, Grid } from '@mui/material';

import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import WalletIcon from '@mui/icons-material/Wallet';
import InventoryIcon from '@mui/icons-material/Inventory';
import PaidIcon from '@mui/icons-material/Paid';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import HailIcon from '@mui/icons-material/Hail';

const GerencialVisualPrincipal = () => {

    const gridItems = [
        { icon: <TimelineRoundedIcon style={{ fontSize: "45px" }} />, title: "Profundidad al portafolio", link: "/Farmanet/GerenciaVisual/ProfundidadAlPortafolio" },
        { icon: <ApartmentRoundedIcon style={{ fontSize: "45px" }} />, title: "Visual empresa", link: "/Farmanet/GerenciaVisual/VisualEmpresa" },
        { icon: <PaidIcon style={{ fontSize: "40px" }} />, title: "Presupuesto", link: "/Farmanet/GerenciaVisual/Presupuesto" },
        { icon: <AttachMoneyRoundedIcon style={{ fontSize: "40px" }} />, title: "Ventas", link: "/Farmanet/GerenciaVisual/Ventas" },
        { icon: <InventoryIcon style={{ fontSize: "40px" }} />, title: "Recaudo por vendedor", link: "/Farmanet/GerenciaVisual/RecaudoPorVendedor" },
        { icon: <HailIcon style={{ fontSize: "40px" }} />, title: "Seguimiento Asesor", link: "/Farmanet/GerenciaVisual/SeguimientoAsesor" },
        { icon: <MultilineChartIcon style={{ fontSize: "40px" }} />, title: "Indicadores", link: "/Farmanet/GerenciaVisual/Indicadores" },
        { icon: <WalletIcon style={{ fontSize: "40px" }} />, title: "Cartera", link: "/Farmanet/GerenciaVisual/Cartera" },
        // Add more grid items as needed
    ];

    const CustomGridItem = ({ icon, title, link }) => {
        return (
            <Grid item xs={12} sm={6} md={6} lg={3}>
                <Paper variant="elevation" elevation={2} style={{ borderRadius: "12px" }}>
                    <Card sx={{ display: 'flex', height: "130px", borderRadius: "12px" }}>
                        <CardActionArea component="a" href={link} sx={{ display: "flex" }}>
                            <Grid item xs={3} sm={3} md={3}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    // bgcolor="#EEF2FF"
                                    // height="40px"
                                    width="100%"
                                >
                                    {icon}
                                </Box>
                            </Grid>
                            <Divider orientation='vertical' style={{ height: "80px" }} />
                            <Grid item xs={9} sm={9} md={9}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography sx={{ fontSize: 14, color: "#FE0000", }} color="text.secondary" gutterBottom>
                                            Gerencia visual
                                        </Typography>
                                        <Typography variant="h5" component="div" style={{ overflow: 'hidden', 
                                            textOverflow: 'ellipsis', whiteSpace: 'nowrap',  }}>
                                            {title}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Paper>
            </Grid>
        );
    };

    return (
        <Box>
            <Grid item xs={12} sm={6} md={12} >
                <Box sx={{ alignContent: "center", justifyContent: "space-between", display: "flex", alignItems: "center" }}>
                    <Typography variant="h5" color="black" gutterBottom style={{
                        display: "flex", justifyContent: "start", textAlign: "center", alignItems: "center",
                        marginBottom: "25px", marginTop: "1.5rem"
                    }}>
                        <BarChartRoundedIcon style={{ marginRight: "5px", color: "#FE0000" }} />
                        Gerencia visual
                    </Typography>
                </Box>
            </Grid>

            <Grid container spacing={2}>
                {gridItems.map((item, index) => (
                    <CustomGridItem key={index} {...item} />
                ))}
            </Grid>
        </Box >
    )
}

export default GerencialVisualPrincipal