/* eslint-disable react/jsx-pascal-case */
import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
    MaterialReactTable,
    MRT_ShowHideColumnsButton,
    useMaterialReactTable,
} from 'material-react-table';
import OpcionesTabla from './OpcionesTabla';

const formatNumber = (value) => {
    return new Intl.NumberFormat('es-CO', {
        maximumFractionDigits: 0, // Define cuántos dígitos decimales mostrar
    }).format(value);
};

const TablaVentasProducto = ({ dataZona, selectedOption, handleOpen, handleOptionChange }) => {
    const campos = dataZona.length > 0 ? Object.keys(dataZona[0]) : [];

    const meses = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];

    // Función para calcular la suma de cada fila
    const calculateRowTotal = (row) => {
        return Object.keys(row).reduce((total, key) => {
            if (key !== 'Codigo' && key !== 'Producto' && key !== 'linea' && key !== 'Total') {
                return total + (parseFloat(row[key]) || 0);
            }
            return total;
        }, 0);
    };

    // Agregar el total a cada fila
    const dataWithTotals = useMemo(() => dataZona.map(row => ({
        ...row,
        Total: calculateRowTotal(row),
    })), [dataZona]);

    const sumaTotal = useMemo(() => dataZona.reduce((total, row) => total + calculateRowTotal(row), 0), [dataZona]);

    // Calcula los totales de cada columna
    const totalQuantities = {};
    campos.forEach(campo => {
        if (campo !== 'vendedor' && !campo.endsWith('Total')) {
            totalQuantities[campo] = dataZona.reduce((total, row) => total + (parseFloat(row[campo]) || 0), 0);
        }
    });

    const formatCellValue = (value) => {
        if (selectedOption === "c") {
            return formatNumber(value); // Sin símbolo $
        }
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            maximumFractionDigits: 0,
        }).format(value); // Con símbolo $
    };

    const columns = [
        {
            id: 'vendedor',
            accessorKey: 'vendedor',
            header: 'vendedor',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            size: 10,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => (
                <Tooltip title={`${cell.row.original.vendedor}`} arrow>
                    <Box>
                        <div>
                            {cell.row.original.vendedor}
                        </div>
                    </Box>
                </Tooltip>
            ),
            footer: 'Total',
        },
        ...campos
            .filter(campo => campo !== 'vendedor')
            .map((campo, index) => ({
                id: campo,
                accessorKey: campo,
                header: meses[index % meses.length], // Asegurarse de no sobrepasar el array
                enableColumnActions: false,
                muiTableHeadCellProps: {
                    align: 'center',
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: index < 5 ? '#1C64F2' : '#27AE60',
                        color: "white",
                    }
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                size: 10,
                Cell: ({ cell }) => (
                    <div>
                        {formatCellValue(cell.getValue())}
                    </div>
                ),
                Footer: ({ column }) => (
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "left" }} >
                        {formatCellValue(totalQuantities[column.id])}
                    </div >
                ),
            })),
        {
            id: 'Total',
            accessorKey: 'Total',
            header: 'Total',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white"
                }
            },
            muiTableBodyCellProps: {
                align: 'left',
            },
            size: 10,
            Cell: ({ cell }) => (
                <div>
                    {formatCellValue(cell.getValue())}
                </div>
            ),
        },
    ];

    const table = useMaterialReactTable({
        columns,
        data: dataWithTotals,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: {
            columnPinning: { left: ['vendedor'], right: ['Total'] },
            density: 'compact'
        },
        localization: {
            toggleDensity: "Alternar densidad",
            rowsPerPage: "Filas por página",
            toggleFullScreen: "Alternar pantalla completa",
            search: "Buscar",
            columnActions: "mostrar/ocultar columnas",
            showHideSearch: "Filtro de busqueda",
            showHideColumns: "mostrar / ocultar columnas"
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <div style={{ gap: '1rem', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant='lg' fontWeight="bold">  Ventas por vendedor</Typography>
                <OpcionesTabla onOptionChange={handleOptionChange} //aqui pasa el hijo
                    selectedOptionPadre={selectedOption}
                />
            </div>
        ),
        renderBottomToolbarCustomActions: ({ table }) => (
            <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: "bolder", marginTop: 1 }} gutterBottom>
                    Total de los meses:
                    <span style={{ fontSize: "17px", marginLeft: "10px" }}>
                        {formatCellValue(sumaTotal)}
                    </span>
                </Typography>
            </Box>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <Box>
                <MRT_ShowHideColumnsButton table={table} />
                <Tooltip title="Grafica">
                    <IconButton onClick={handleOpen}>
                        <BarChartIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        muiTableContainerProps: { sx: { height: "24rem", width: "100%" } }
    });

    return (
        <MaterialReactTable table={table} />
    );
};

export default TablaVentasProducto;
