import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const GraficoVendedor = ({ selectAñoIncial, rows }) => {

    const chartRef = useRef(null);

    const converAñoAnt = selectAñoIncial - 1;
    const nombrVendedor = `vendedor`;
    const ventasKey = `Ventas${selectAñoIncial}`;
    const costoKey = `Costo${selectAñoIncial}`;
    const presupuestoKey = `Presupuesto${selectAñoIncial}`;
    const AñoAnterior = `Ventas${converAñoAnt}`;

    const data = rows.map((row, index) => {
        const nombreVende = row[nombrVendedor];
        const ventas = row[ventasKey];
        const ventasAñoAnterior = row[AñoAnterior];
        const costo = row[costoKey];
        const presupuesto = row[presupuestoKey];
        let tendencia = 0;
        if (index >= 1) {
            const ventasAnterior = +rows[index - 1][ventasKey];
            const presupuestoAnterior = +rows[index - 1][presupuestoKey];
            const diferencia = (presupuestoAnterior - ventasAnterior);
            tendencia = diferencia + presupuesto;
        } else {
            tendencia = presupuesto;
        }

        return {
            name: nombreVende,
            Ventas: ventas,
            ventasAnt: ventasAñoAnterior,
            Costo: costo,
            Presupuesto: presupuesto,
            Tendencia: tendencia
        };
    });

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current);

        const option = {
            tooltip: {
                trigger: 'axis',
                confine: true, // Mantener el tooltip dentro del contenedor
                formatter: (params) => {
                    const { name, value } = params[0];
                    return `
                        <div>${name}</div>
                        <div>Ventas: $${new Intl.NumberFormat().format(value)}</div>
                    `;
                }
            },
            legend: {
                data: ['Ventas']
            },
            xAxis: {
                type: 'category',
                data: data.map(item => item.name),

                axisLabel: {
                    // interval: 0, 
                    rotate: 60,
                    formatter: function (value) {
                        // Truncar si el nombre es más largo de 10 caracteres y agregar "..."
                        return value.length > 10 ? value.slice(0, 6) + '...' : value;
                    }
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    // fontSize: 15,
                    formatter: (value) => {
                        if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`; // Billones
                        if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`; // Millones
                        if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`; // Miles
                        if (value < 0) return `-${(-value / 1e6).toFixed(1)}M`; // Acortar valores negativos
                        return value; // Valor original si no supera los miles
                    }
                }
            },
            series: [
                {
                    name: 'Ventas',
                    type: 'bar',
                    data: data.map(item => item.Ventas),
                    // itemStyle: {
                    //     color: '#9fd286'
                    // }
                }
            ]
        };

        chartInstance.setOption(option);


        function handleResize() {
            chartInstance.resize();
        }

        window.addEventListener('resize', handleResize);

        return () => {
            chartInstance.dispose();
        };
    }, [data]);

    return (
        <div>
            <div ref={chartRef} style={{
                width: '100%', height: '400px',
                marginTop: "2rem"
            }}></div>
        </div>
    );
};

export default GraficoVendedor;
