import React, { useCallback, useEffect, useState, useMemo, Suspense } from 'react';
import TablaMeses from './TablaMeses';
import GraficoMeses from './GraficoMeses';
import { Grid, CircularProgress } from '@mui/material';
import newRequest from '../../../../utils/newRequest';

const PorMeses = ({
    selectedMesFinal,
    selectedMesInicial,
    selectAñoIncial,
    selectedProduct,
    selectedCliente,
    selectedLineas,
    selectedVendedor,
    selectedTercero,
    checked,
    Excluir,
    selectedZona
}) => {

    const [rows, setRows] = useState([]); // Estado para almacenar las filas
    const [presupuestoTotal, setPresupuestoTotal] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noDataMessage, setNoDataMessage] = useState('');
    const converAñoAnt = useMemo(() => selectAñoIncial - 1, [selectAñoIncial]);

    const [cambioTabla, setCambioTabla] = useState(true);

    const handleCambioTabla = useCallback((newCambioTabla) => {
        setCambioTabla(newCambioTabla);
    }, []);

    const updateRowsData = useCallback(async () => {
        try {
            setLoading(true);
            const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialVentasMes', {
                anioant: converAñoAnt,
                anio: selectAñoIncial,
                mes1: selectedMesInicial,
                mes2: selectedMesFinal,
                articulo: selectedProduct,
                cliente: selectedCliente,
                vendedor: selectedVendedor,
                linea: selectedLineas,
                tercero: selectedTercero,
                conProspecto: checked,
                excluir: Excluir,
                CambioDato: cambioTabla,
                zona: selectedZona,
            });
            const data = res.data;
            if (data.length > 0) {
                const rowsWithId = data.map((row, index) => ({ id: index + 1, ...row }));
                setRows(rowsWithId);
                setNoDataMessage('Ventas por mes');
            } else {
                setNoDataMessage('No hay datos disponibles');
                setRows([]);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [converAñoAnt, selectAñoIncial, selectedMesInicial, selectedMesFinal, selectedProduct, selectedCliente, selectedLineas, selectedVendedor, selectedTercero, checked, Excluir, cambioTabla, selectedZona]);

    useEffect(() => {
        const getPresupuestoAnual = async () => {
            try {
                setLoading(true);
                const res = await newRequest.post('/api/GerenciaVisual/VisualEmpresa/vistaGerencialTotalPresupuestoAnual', {
                    total: selectAñoIncial
                });
                setPresupuestoTotal(res.data);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        getPresupuestoAnual();
        updateRowsData();
    }, [updateRowsData, selectAñoIncial]);

    return (
        <div style={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} sx={{ marginBottom: '25px' }}>
                    {loading ?
                        (
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "350px" }} >
                                <CircularProgress />
                            </div>
                        ) : (
                            <Suspense >
                                <GraficoMeses
                                    dataReady={loading}
                                    selectAñoIncial={selectAñoIncial}
                                    selectedMesInicial={selectedMesInicial}
                                    selectedMesFinal={selectedMesFinal}
                                    rows={rows}
                                    cambioTabla={cambioTabla}
                                />
                            </Suspense>
                        )}
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} sx={{ marginBottom: '25px' }}>
                    {loading ?
                        (
                            <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "350px" }} >
                                <CircularProgress />
                            </div>
                        ) :
                        (
                            <>
                                {/* <div
                                    style={{
                                        position: 'sticky',
                                        top: 0,
                                        display: 'flex',
                                        zIndex: 2,
                                        marginBottom: '-40px',
                                        marginLeft: '10px',
                                        width: '60%',
                                    }}
                                > */}
                                {/* <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '10px' }}>
                                        {noDataMessage && <>{noDataMessage}</>}
                                    </Typography>
                                    <OpcionesTabla onChangeCambioTabla={handleCambioTablaChange} cambioTablaFromParent={cambioTabla} /> */}
                                {/* </div> */}
                                <TablaMeses
                                    updateRowsData={updateRowsData}
                                    selectAñoIncial={selectAñoIncial}
                                    selectedMesInicial={selectedMesInicial}
                                    selectedMesFinal={selectedMesFinal}
                                    rows={rows}
                                    selectedProduct={selectedProduct}
                                    selectedCliente={selectedCliente}
                                    selectedVendedor={selectedVendedor}
                                    selectedLineas={selectedLineas}
                                    selectedTercero={selectedTercero}
                                    presupuestoTotal={presupuestoTotal}
                                    checked={checked}
                                    Excluir={Excluir}
                                    selectedZona={selectedZona}
                                    noDataMessage={noDataMessage}
                                    cambioTabla={cambioTabla}
                                    onChangeCambioTabla={handleCambioTabla}
                                />
                            </>
                        )}

                </Grid>
            </Grid>
        </div>
    );
};

export default PorMeses;
