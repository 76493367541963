/* eslint-disable react/jsx-pascal-case */
import React, { useMemo } from 'react'
import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import BarChartIcon from '@mui/icons-material/BarChart';

import {
    MaterialReactTable,
    MRT_ShowHideColumnsButton,
    useMaterialReactTable,
} from 'material-react-table';


import Tooltip from '@mui/material/Tooltip';
import OpcionesTabla from './OpcionesTabla';

const TablaVentasPorZona = ({ dataZona, selectedOption, handleOpen, handleOptionChange }) => {

    const campos = dataZona.length > 0 ? Object.keys(dataZona[0]) : [];

    const meses = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ];

    const formatCurrency = (value, option) => {
        if (option === 'a' || option === 'b') {
            return new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value);
        } else if (option === 'c') {
            return new Intl.NumberFormat(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(value);
        }
        return value;
    };


    // Función para calcular la suma de cada fila
    const calculateRowTotal = (row) => {
        return Object.keys(row).reduce((total, key) => {
            if (key !== 'zona' && key !== 'Total') {
                return total + (parseFloat(row[key]) || 0);
            }
            return total;
        }, 0);
    };

    // Agregar el total a cada fila
    const dataWithTotals = useMemo(() => {
        return dataZona.map((row) => ({
            ...row,
            Total: calculateRowTotal(row),
        }));
    }, [dataZona]);

    const sumaTotal = useMemo(() => {
        return dataZona.reduce((total, row) => {
            return total + calculateRowTotal(row);
        }, 0);
    }, [dataZona]);

    const columns = [
        {
            id: 'zona',
            accessorKey: 'zona',
            header: 'Zona',
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white",
                },
            },
            size: 10,
            enableColumnActions: false,
            muiTableBodyCellProps: {
                align: 'left',
            },
            Cell: ({ cell }) => (
                <Tooltip title={cell.row.original.zona}>
                    <div>{cell.row.original.zona}</div>
                </Tooltip>
            ),
            footer: 'Total',
        },
        ...campos
            .filter(campo => campo !== 'Codigo' && campo !== 'Producto' && campo !== 'linea')
            .map((campo, index) => ({
                id: campo,
                accessorKey: campo,
                header: meses[index],
                muiTableHeadCellProps: {
                    align: 'center',
                    sx: {
                        fontWeight: 'normal',
                        backgroundColor: index % 2 === 0 ? '#1C64F2' : '#27AE60',
                        color: "white",
                    },
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                size: 10,
                Cell: ({ cell }) => (
                    <div>
                        {formatCurrency(cell.getValue(), selectedOption)}
                    </div>
                ),
                Footer: ({ column }) => {
                    const sum = dataWithTotals.reduce((total, row) => total + (row[column.id] || 0), 0);
                    return (
                        <div style={{ alignItems: "left", display: "flex", justifyContent: "left" }}>
                            {formatCurrency(sum, selectedOption)}
                        </div>
                    );
                },
            })),
        {
            id: 'Total',
            accessorKey: 'Total',
            header: 'Total',
            enableColumnActions: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: {
                    fontWeight: 'normal',
                    backgroundColor: '#E74C3C',
                    color: "white",
                },
            },
            muiTableBodyCellProps: {
                align: 'left',
                sx: {
                    border: '1px solid rgba(224, 224, 224, 1)',
                    fontWeight: 'normal',
                },
            },
            size: 120,
            Cell: ({ cell }) => (
                <Typography variant='body2'>
                    {formatCurrency(cell.getValue(), selectedOption)}
                </Typography>
            ),
        },
    ];

    const table = useMaterialReactTable({
        columns,
        data: dataWithTotals,
        positionToolbarAlertBanner: 'bottom',
        muiTablePaperProps: {
            elevation: 0
        },
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enableColumnActions: false,

        initialState: {
            columnPinning: { left: ['zona'], right: ['Total'] },
            density: 'compact'
        },
        localization: {
            toggleDensity: "Alternar densidad",
            rowsPerPage: "Filas por página",
            toggleFullScreen: "Alternar pantalla completa",
            search: "Buscar",
            columnActions: "mostrar/ocultar columnas",
            showHideSearch: "Filtro de busqueda",
            showHideColumns: "mostrar / ocultar columnas"
        },
        renderTopToolbarCustomActions: ({ table }) => (
            <div style={{ gap: '1rem', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant='lg' fontWeight="bold"> Ventas por zona</Typography>
                <OpcionesTabla onOptionChange={handleOptionChange} //aqui pasa el hijo
                    selectedOptionPadre={selectedOption} />
            </div>
        ),
        renderBottomToolbarCustomActions: ({ table }) => (
            <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: "bolder", marginTop: 1 }} gutterBottom>
                    Total de los meses :
                    <span style={{ fontSize: "17px", marginLeft: "10px" }}>
                        {formatCurrency(sumaTotal, selectedOption)}
                    </span>
                </Typography>
            </Box>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <Box>
                <MRT_ShowHideColumnsButton table={table} />
                <Tooltip title="Grafica">
                    <IconButton onClick={handleOpen}>
                        <BarChartIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        muiTableContainerProps: { sx: { height: "24rem", width: "100%" } }
    });


    return (
        <MaterialReactTable
            table={table}
        />
    )
}

export default TablaVentasPorZona